import { Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Dialog,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import CloseBtn from "../../context/CloseBtn";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import TextFieldNew from "../../common/TextFieldNew";

const UpdateTc = ({ data, hitAxios }) => {
  const [state, setState] = React.useState({
    dialog: false,
    page: {},
  });

  async function getPage() {
    const res = await hitAxios({
      path: "/api/admin/get_page_slug",
      post: true,
      admin: false,
      obj: { slug: "terms-and-conditions" },
    });
    if (res.data.success) {
      setState({ ...state, page: res.data.data });
    }
  }

  async function updatePP() {
    const res = await hitAxios({
      path: "/api/admin/update_terms",
      post: true,
      admin: true,
      obj: state.page,
    });
  }

  React.useEffect(() => {
    getPage();
  }, []);

  return (
    <div>
      <Chip
        color="secondary"
        variant="outlined"
        label={data.termsCondition}
        icon={
          <IconButton onClick={() => setState({ ...state, dialog: true })}>
            <Edit sx={{ height: 18, width: 18 }} />
          </IconButton>
        }
      />

      <Dialog
        fullWidth
        onClose={() => setState({ ...state, dialog: false })}
        PaperProps={{
          style: { borderRadius: 10 },
        }}
        open={state.dialog}
      >
        <Box p={2}>
          <Stack
            pb={2}
            alignItems={"center"}
            direction={"row"}
            justifyContent={"space-between"}
          >
            <Typography variant="h6" fontWeight={600}>
              {data.termsCondition}
            </Typography>
            <CloseBtn onClick={() => setState({ ...state, dialog: false })} />
          </Stack>

          <Divider />

          <Box pt={2}>
            <Stack direction={"column"} spacing={2}>
              <TextFieldNew
                label={data.title}
                value={state?.page?.title}
                onChange={(e) =>
                  setState({
                    ...state,
                    page: { ...state.page, title: e.target.value },
                  })
                }
              />

              <ReactQuill
                value={state.page?.content}
                style={{ backgroundColor: "white", color: "black" }}
                theme="snow"
                onChange={(e) =>
                  setState({ ...state, page: { ...state.page, content: e } })
                }
              />

              <Button
                onClick={updatePP}
                variant="contained"
                sx={{ boxShadow: 0, borderRadius: 2 }}
              >
                {data.submit}
              </Button>
            </Stack>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};

export default UpdateTc;

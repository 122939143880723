import {
  AutoAwesome,
  AutoAwesomeOutlined,
  ManageSearch,
} from "@mui/icons-material";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import ButtonAnime from "../../../common/ButtonAnime";

const WhileNoChat = ({ data, state, setState }) => {
  return (
    <div>
      <Stack alignItems={"center"} justifyContent={"center"} height={"65vh"}>
        <Stack alignItems={"center"} direction={"column"} spacing={4}>
          <ButtonAnime style={{ padding: 10 }}>
            <Typography color={"white"} variant="h1">
              LOGO
            </Typography>
          </ButtonAnime>
          <Stack direction={"row"} spacing={2}>
            <Button
              onClick={() => setState({ ...state, selectModelDialog: true })}
              size="small"
              variant="outlined"
              //   color="primary"
              startIcon={<AutoAwesomeOutlined />}
            >
              {data.startNewChat}
            </Button>
            <Button
              onClick={() => setState({ ...state, historyDialog: true })}
              size="small"
              variant="outlined"
              startIcon={<ManageSearch />}
              color="inherit"
            >
              {data.chatHistory}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};

export default WhileNoChat;

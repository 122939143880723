import React from "react";
import { GlobalContext } from "../../context/GlobalContext";
import {
  Box,
  CardMedia,
  Container,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import SlideShow from "../../common/SlideShow";
import TitleHeading from "./TitleHeading";
import { Handshake } from "@mui/icons-material";

const OurClient = ({ data }) => {
  const { hitAxios } = React.useContext(GlobalContext);
  const [partners, setPartners] = React.useState([]);

  async function getPartners() {
    const res = await hitAxios({
      path: "/api/admin/get_brands",
      post: false,
      admin: false,
    });
    if (res.data.success) {
      setPartners(res.data.data);
    }
  }

  React.useEffect(() => {
    getPartners();
  }, []);
  return (
    <div>
      <Box p={4} pb={2} bgcolor={"action.hover"} mb={6}>
        <Box mb={4}>
          <Container maxWidth="md">
            <Stack justifyContent={"center"} direction={"row"}>
              <Box bgcolor={"white"} p={2} borderRadius={2}>
                <TitleHeading
                  text={data.partnersTitle}
                  icon={
                    <>
                      <svg width={0} height={0}>
                        <linearGradient
                          id="linearColors"
                          x1={0}
                          y1={0}
                          x2={1}
                          y2={1}
                        >
                          <stop offset="0%" stopColor="#f25470" />
                          <stop offset="100%" stopColor="#5f88fe" />
                        </linearGradient>
                      </svg>
                      <Handshake sx={{ fill: "url(#linearColors)" }} />
                    </>
                  }
                />
              </Box>
            </Stack>
          </Container>
        </Box>

        <Box mt={6} mb={3}>
          <Container maxWidth="xl">
            <SlideShow data={data} getBrands={getPartners} images={partners} />
          </Container>
        </Box>
      </Box>
    </div>
  );
};

export default OurClient;

import React from "react";
import { useLocation } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  Box,
  Button,
  CardActionArea,
  CardMedia,
  Checkbox,
  Container,
  Dialog,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { TranslateContext } from "../../context/TranslateContext";
import { AccountBalanceOutlined, CreditCard } from "@mui/icons-material";
import CloseBtn from "../../context/CloseBtn";
import { loadStripe } from "@stripe/stripe-js";
import PayStack from "./PayStack";
import Header from "../component/Header";
import Footer from "../component/Footer";

const CheckOut = () => {
  const { data } = React.useContext(TranslateContext);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const productId = queryParams.get("product");
  const { hitAxios } = React.useContext(GlobalContext);
  const [user, setUser] = React.useState({});
  const [web, setWeb] = React.useState({});
  const history = useHistory();
  const [state, setState] = React.useState({
    gateways: {},
    pp: false,
    tc: false,
    offlineDialog: false,
  });

  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateScreenWidth);
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  async function getWebPublic() {
    const res = await hitAxios({
      path: "/api/web/get_web_public",
      post: false,
      admin: false,
      token_user: "",
    });
    if (res.data.success) {
      setWeb(res.data.data);
    }
  }

  const [plan, setPlan] = React.useState({});

  async function getPlan() {
    const res = await hitAxios({
      path: "/api/user/get_plan_details",
      post: true,
      admin: false,
      obj: { id: productId },
    });
    if (res.data.success) {
      setPlan(res.data.data);
    } else {
      history.push("/user");
    }
  }

  async function getPaymentGate() {
    const res = await hitAxios({
      path: "/api/user/get_payment_details",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      setState({ ...state, gateways: res.data.data });
    }
  }

  async function payWithStripe() {
    const stripe = await loadStripe(state?.gateways?.pay_stripe_id);

    const res = await hitAxios({
      path: "/api/user/create_stripe_session",
      post: true,
      admin: false,
      obj: {
        planId: plan?.id,
      },
    });
    if (res.data.success) {
      const result = stripe.redirectToCheckout({
        sessionId: res?.data?.session?.id,
      });

      console.log({ result });
    }
  }

  async function enrollFree(planId) {
    const res = await hitAxios({
      path: "/api/plan/start_free_trial",
      post: true,
      admin: false,
      obj: { planId },
    });
    if (res.data.success) {
      setTimeout(() => {
        history.push("/user");
      }, 3000);
    }
  }

  async function getUser() {
    const res = await hitAxios({
      path: "/api/user/get_me",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      setUser(res.data.data);
    }
  }

  React.useEffect(() => {
    if (productId) {
      getPlan();
      getPaymentGate();
    }
  }, [productId]);

  React.useEffect(() => {
    getWebPublic();
    getUser();
  }, []);

  return (
    <div>
      <Header />
      <Container maxWidth="lg">
        <Grid
          alignItems={"center"}
          justifyContent={"center"}
          container
          minHeight={"90vh"}
        >
          <Grid xs={12} sm={12} lg={12} item>
            <Stack spacing={4} direction={"column"} alignItems={"center"}>
              <CardMedia
                src="/assets/payment_page.svg"
                component={"img"}
                sx={{ height: "100%", width: 200 }}
              />

              <Stack direction={"column"}>
                <Typography variant="h6" fontWeight={600} align="center">
                  {data.cehckOutFor}{" "}
                  <a
                    style={{
                      color: data.color_theme,
                    }}
                  >
                    {plan?.name}
                  </a>{" "}
                  {data.plan}
                </Typography>
                <Typography
                  align="center"
                  color={"gray"}
                  fontWeight={600}
                  variant="body2"
                >
                  {data.selPayment}
                </Typography>
              </Stack>

              <Stack alignItems={"center"} direction={"row"} spacing={2}>
                <Stack alignItems={"center"} direction={"row"} spacing={1}>
                  <Checkbox
                    onChange={(e) =>
                      setState({ ...state, pp: e.target.checked })
                    }
                    checked={state.pp}
                    size="small"
                  />
                  <Typography
                    onClick={() => {
                      window.open(`/view/privacy-policy`);
                    }}
                    fontWeight={600}
                    sx={{
                      cursor: "pointer",
                      color: (t) => t.palette.primary.main,
                    }}
                    variant="body2"
                  >
                    {data.privacyPlicy}
                  </Typography>
                </Stack>
                <Stack alignItems={"center"} direction={"row"} spacing={1}>
                  <Checkbox
                    onChange={(e) =>
                      setState({ ...state, tc: e.target.checked })
                    }
                    checked={state.tc}
                    size="small"
                  />
                  <Typography
                    onClick={() => {
                      window.open(`/view/terms-and-conditions`);
                    }}
                    fontWeight={600}
                    sx={{
                      cursor: "pointer",
                      color: (t) => t.palette.primary.main,
                    }}
                    variant="body2"
                  >
                    {data.termsCondition}
                  </Typography>
                </Stack>
              </Stack>

              {plan?.price > 0 ? (
                <Stack spacing={2} direction={"column"} alignItems={"center"}>
                  {state?.gateways?.offline_active > 0 &&
                    state.tc &&
                    state.pp && (
                      <CardActionArea
                        onClick={() =>
                          setState({ ...state, offlineDialog: true })
                        }
                        disabled={state.tc && state.pp ? false : true}
                        sx={{
                          padding: 2,
                          borderRadius: 3,
                          bgcolor: "#b2e0f7",
                        }}
                      >
                        <Stack direction={"row"} spacing={2}>
                          <AccountBalanceOutlined />
                          <Typography fontWeight={600}>
                            {data.offlinePayment}
                          </Typography>
                        </Stack>
                      </CardActionArea>
                    )}

                  {state?.gateways?.stripe_active > 0 &&
                    state.pp &&
                    state.tc && (
                      <CardActionArea
                        onClick={payWithStripe}
                        disabled={state.tc && state.pp ? false : true}
                        sx={{
                          padding: 2,
                          borderRadius: 3,
                          bgcolor: "#d8d2fa",
                        }}
                      >
                        <Stack direction={"row"} spacing={2}>
                          <CreditCard />
                          <Typography fontWeight={600}>
                            {data.stripePay}
                          </Typography>
                        </Stack>
                      </CardActionArea>
                    )}

                  {/* paystack payment gateway  */}
                  {state?.gateways?.paystack_active > 0 &&
                    state.pp &&
                    state.tc && (
                      <PayStack
                        keys={state?.gateways}
                        state={state}
                        data={data}
                        plan={plan}
                        setState={setState}
                        web={web}
                        user={user}
                      />
                    )}
                </Stack>
              ) : (
                state.tc &&
                state.pp && (
                  <Stack spacing={2} direction={"column"} alignItems={"center"}>
                    {" "}
                    <CardActionArea
                      onClick={() => enrollFree(plan?.id)}
                      disabled={state.tc && state.pp ? false : true}
                      sx={{
                        padding: 2,
                        borderRadius: 3,
                        bgcolor: "action.hover",
                      }}
                    >
                      <Stack
                        alignItems={"center"}
                        direction={"row"}
                        spacing={2}
                      >
                        <Typography variant="h4">🤩</Typography>
                        <Typography fontWeight={600}>
                          {data.startFreeTrial}
                        </Typography>
                      </Stack>
                    </CardActionArea>
                  </Stack>
                )
              )}
            </Stack>
          </Grid>
        </Grid>

        <Dialog
          fullWidth
          PaperProps={{
            style: { borderRadius: 10 },
          }}
          onClose={() => setState({ ...state, offlineDialog: false })}
          open={state.offlineDialog}
        >
          <Box p={2}>
            <Stack direction={"column"} spacing={2}>
              <Stack
                alignItems={"center"}
                spacing={2}
                justifyContent={"space-between"}
                direction={"row"}
              >
                <Typography variant="h6">
                  {state?.gateways?.pay_offline_id}
                </Typography>
                <CloseBtn
                  onClick={() => setState({ ...state, offlineDialog: false })}
                />
              </Stack>

              <Divider />

              <Typography
                sx={{ display: "inline-block" }}
                dangerouslySetInnerHTML={{
                  __html: state?.gateways?.pay_offline_key,
                }}
                fontWeight={400}
              />
            </Stack>
          </Box>
        </Dialog>
      </Container>

      <Footer screenWidth={screenWidth} data={data} />
    </div>
  );
};

export default CheckOut;

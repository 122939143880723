import { AutoFixHigh, Send } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonBase,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import SmallDialog from "../../../common/SmallDialog";
import TextFieldNew from "../../../common/TextFieldNew";
import { GlobalContext } from "../../../context/GlobalContext";

const ChooseAudioText = ({ data, state, setState }) => {
  const { hitAxios } = React.useContext(GlobalContext);
  const [a, setA] = React.useState({
    dialog: false,
    que: "",
    text: "",
  });

  async function getText() {
    const res = await hitAxios({
      path: "/api/user/gen_text",
      post: true,
      admin: false,
      obj: { que: a?.que },
    });
    if (res.data.success) {
      setA({ ...a, text: res.data?.text });
    }
  }

  return (
    <div>
      <Button
        variant="contained"
        onClick={() => setA({ ...a, dialog: true })}
        startIcon={<AutoFixHigh />}
      >
        {data.genTopic}
      </Button>

      <SmallDialog
        dialogType={"fullWidth"}
        open={a.dialog}
        title={data.genTopic}
        onClose={() => setA({ ...a, dialog: false })}
      >
        <Stack direction={"column"} spacing={2}>
          <TextFieldNew
            value={a.que}
            onChange={(e) => setA({ ...a, que: e.target.value })}
            helperText={data.writeTopicHelper}
            label={data.enterTopic}
            endIcon={
              <IconButton onClick={getText}>
                <Send />
              </IconButton>
            }
          />

          {a?.text && (
            <ButtonBase
              onClick={() => {
                setState({ ...state, choosedAudioText: a?.text });
                setA({ ...a, dialog: false });
              }}
              sx={{ borderRadius: 3 }}
            >
              <Box p={2} borderRadius={3} bgcolor={"action.hover"}>
                <Typography>{a?.text}</Typography>
              </Box>
            </ButtonBase>
          )}
        </Stack>
      </SmallDialog>
    </div>
  );
};

export default ChooseAudioText;

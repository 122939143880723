import { Box, Grow, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import { DeleteOutline, MovieFilter } from "@mui/icons-material";
import moment from "moment";
import StatusVideo from "./StatusVideo";
import ButtonAnime from "../../../common/ButtonAnime";

const VideoHistory = ({ data, state, setState }) => {
  const { hitAxios } = React.useContext(GlobalContext);
  const [videos, setVideos] = React.useState([]);

  async function delVideo(id) {
    if (window.confirm(data.aus)) {
      const res = await hitAxios({
        path: "/api/video/del_video_ai",
        post: true,
        admin: false,
        obj: { id },
      });
      if (res.data.success) {
        getData();
      }
    }
  }

  async function getData(params) {
    const res = await hitAxios({
      path: "/api/video/get_my_videos",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      setVideos(res.data.data);
    }
  }

  React.useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <Box borderRadius={2} overflow={"auto"} height={"65vh"} mt={3}>
        <Stack direction={"column"} spacing={2}>
          {[...videos]?.reverse()?.map((i, key) => {
            return (
              <Grow in timeout={1000}>
                <Box key={key} bgcolor={"action.hover"} p={2} borderRadius={3}>
                  <Stack
                    direction={"row"}
                    spacing={2}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Stack alignItems={"center"} direction={"row"} spacing={2}>
                      <MovieFilter sx={{ height: 40, width: 40 }} />
                      <Typography color={"gray"} variant="caption">
                        {i?.id}: {moment(i?.createdAt).fromNow()}
                      </Typography>
                    </Stack>

                    <Stack alignItems={"center"} direction={"row"} spacing={2}>
                      <IconButton color="error" onClick={() => delVideo(i?.id)}>
                        <DeleteOutline />
                      </IconButton>
                      <StatusVideo i={i} data={data} />
                    </Stack>
                  </Stack>
                </Box>
              </Grow>
            );
          })}
        </Stack>
      </Box>
    </div>
  );
};

export default VideoHistory;

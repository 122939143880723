import React from "react";
import ButtonGrid from "../../../common/ButtonGrid";
import { Add, Save, SaveAlt } from "@mui/icons-material";
import SmallDialog from "../../../common/SmallDialog";
import { Autocomplete, Box, Button, Stack, TextField } from "@mui/material";
import BotList from "./BotList";

const CreateBot = ({ data, state, setState, hitAxios }) => {
  const [models, setModels] = React.useState([]);
  const [bot, setBots] = React.useState([]);

  async function getModels(params) {
    const res = await hitAxios({
      path: "/api/user/get_ai_models",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      setModels(res.data.data);
    }
  }

  async function getList(params) {
    const res = await hitAxios({
      path: "/api/embed/get_my_embed",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      setBots(res.data.data);
    }
  }

  async function addModel(params) {
    const res = await hitAxios({
      path: "/api/embed/add_bot",
      post: true,
      admin: false,
      obj: state,
    });
    if (res.data.success) {
      getList();
      setState({ ...state, dialogAdd: false });
    }
  }

  React.useEffect(() => {
    getModels();
    getList();
  }, []);

  return (
    <div>
      <SmallDialog
        title={data.createNew}
        dialogType={"fullWidth"}
        open={state.dialogAdd}
        onClose={() => setState({ ...state, dialogAdd: false })}
      >
        <Stack direction={"column"} spacing={2}>
          <Autocomplete
            value={state.openai_model}
            onChange={(e, newValue) => {
              if (newValue?.label) {
                setState({ ...state, modelId: newValue?.id });
              }
            }}
            size="small"
            sx={{ "& fieldset": { borderRadius: 3 } }}
            id="combo-box-demo"
            options={models.map((i) => {
              return {
                ...i,
                label: i.name,
              };
            })}
            renderInput={(params) => (
              <TextField
                InputProps={{
                  style: { borderRadius: 10 },
                }}
                size="small"
                {...params}
                label={data.selAiMode}
              />
            )}
          />

          <Button
            onClick={addModel}
            disabled={state.modelId ? false : true}
            variant="contained"
          >
            {data.submit}
          </Button>
        </Stack>
      </SmallDialog>

      <Box mt={3}>
        <BotList
          state={state}
          setState={setState}
          getList={getList}
          data={data}
          bot={bot}
        />
      </Box>
    </div>
  );
};

export default CreateBot;

import React from "react";
import { Avatar, Box, Stack, Switch, Typography, Button } from "@mui/material";
import TitlePanel from "../../common/TitlePanel";
import { AccountTreeOutlined } from "@mui/icons-material";
import TextFieldNew from "../../common/TextFieldNew";
import { GlobalContext } from "../../context/GlobalContext";

const PaymentGateway = ({ data }) => {
  const { hitAxios } = React.useContext(GlobalContext);
  const [gateway, setGateway] = React.useState("");

  async function getGateway() {
    const res = await hitAxios({
      path: "/api/admin/get_payment_gateway_admin",
      post: false,
      admin: true,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_admin"),
    });
    if (res.data.success) {
      setGateway(res.data.data);
    }
  }

  async function updateGateway() {
    const res = await hitAxios({
      path: "/api/admin/update_pay_gateway",
      post: true,
      admin: true,
      obj: gateway,
    });
  }

  React.useEffect(() => {
    getGateway();
  }, []);

  return (
    gateway && (
      <div>
        <Stack direction={"column"} spacing={2}>
          <TitlePanel
            icon={
              <Avatar
                sx={{
                  width: 50,
                  height: 50,
                  bgcolor: "action.hover",
                }}
              >
                <AccountTreeOutlined
                  sx={{
                    color: (t) => t.palette.primary.main,
                  }}
                />
              </Avatar>
            }
            data={data}
            place={data.paymentGateway}
            title={data.paymentGateway}
          />

          <Stack direction={"column"} spacing={2}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography fontWeight={600}>{data.offlinePayment}</Typography>
              <Switch
                onChange={(e) =>
                  setGateway({
                    ...gateway,
                    offline_active: e.target.checked ? 1 : 0,
                  })
                }
                checked={gateway?.offline_active > 0 ? true : false}
              />
            </Stack>

            <TextFieldNew
              onChange={(e) =>
                setGateway({ ...gateway, pay_offline_id: e.target.value })
              }
              value={gateway.pay_offline_id}
              label={data.title}
            />
            <TextFieldNew
              onChange={(e) =>
                setGateway({ ...gateway, pay_offline_key: e.target.value })
              }
              value={gateway.pay_offline_key}
              multiline
              rows={4}
              label={data.des}
            />

            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography fontWeight={600}>{data.stripe}</Typography>
              <Switch
                onChange={(e) =>
                  setGateway({
                    ...gateway,
                    stripe_active: e.target.checked ? 1 : 0,
                  })
                }
                checked={gateway?.stripe_active > 0 ? true : false}
              />
            </Stack>

            <TextFieldNew
              onChange={(e) =>
                setGateway({ ...gateway, pay_stripe_id: e.target.value })
              }
              value={gateway.pay_stripe_id}
              label={data.id}
            />
            <TextFieldNew
              onChange={(e) =>
                setGateway({ ...gateway, pay_stripe_key: e.target.value })
              }
              value={gateway.pay_stripe_key}
              label={data.keys}
            />

            {/* paystack  */}
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography fontWeight={600}>{data.payStackGateway}</Typography>
              <Switch
                onChange={(e) =>
                  setGateway({
                    ...gateway,
                    paystack_active: e.target.checked ? 1 : 0,
                  })
                }
                checked={gateway?.paystack_active > 0 ? true : false}
              />
            </Stack>

            <TextFieldNew
              onChange={(e) =>
                setGateway({ ...gateway, pay_paystack_id: e.target.value })
              }
              value={gateway.pay_paystack_id}
              label={data.id}
            />
            <TextFieldNew
              onChange={(e) =>
                setGateway({ ...gateway, pay_paystack_key: e.target.value })
              }
              value={gateway.pay_paystack_key}
              label={data.keys}
            />

            <Button
              onClick={updateGateway}
              variant="contained"
              sx={{ boxShadow: 0, borderRadius: 2 }}
            >
              {data.update}
            </Button>
          </Stack>
        </Stack>
      </div>
    )
  );
};

export default PaymentGateway;

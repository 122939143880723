import {
  HourglassTop,
  SlowMotionVideo,
  Error,
  Downloading,
} from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";

const StatusVideo = ({ i, data }) => {
  return (
    <div>
      {i?.status == "COMPLETED" && (
        <Tooltip title={data.playVideo}>
          <IconButton
            onClick={() => window.open(`/media/${i?.final_video}`)}
            color="success"
          >
            <Downloading />
          </IconButton>
        </Tooltip>
      )}

      {i?.status == "QUEUE" && (
        <Tooltip title={data.inQueue}>
          <HourglassTop color="info" />
        </Tooltip>
      )}

      {i?.status !== "QUEUE" && i?.status !== "COMPLETED" && (
        <Tooltip title={i?.status}>
          <Error color="error" />
        </Tooltip>
      )}
    </div>
  );
};

export default StatusVideo;

import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Slider,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import ButtonGrid from "../../common/ButtonGrid";
import {
  History,
  AddCircleOutline,
  ContactSupport,
  PsychologyOutlined,
} from "@mui/icons-material";
import SmallDialog from "../../common/SmallDialog";
import TextFieldNew from "../../common/TextFieldNew";
import DragUpload from "../../common/DragUpload";
import { GlobalContext } from "../../context/GlobalContext";
import UserToken from "../../common/UserToken";

const CreateModel = ({ data, getModels }) => {
  const { hitAxios } = React.useContext(GlobalContext);
  const [state, setState] = React.useState({
    dialogAdd: false,
    ai_type: "OPENAI",
    openai_model: "gpt-4o",
    memory: 3,
  });

  function createSlug(text = "") {
    return text
      .toString() // Convert to string
      .toLowerCase() // Convert to lowercase
      .trim() // Trim whitespace
      .replace(/[\s\W-]+/g, "-") // Replace spaces and non-word characters with hyphens
      .replace(/^-+|-+$/g, ""); // Remove leading or trailing hyphens
  }

  const gptModelArr = [
    "gpt-3.5-turbo-0613",
    "gpt-3.5-turbo-0301",
    "gpt-3.5-turbo-1106",
    "gpt-3.5-turbo",
    "gpt-3.5-turbo-16k",
    "gpt-3.5-turbo-instruct",
    "gpt-3.5-turbo-0613",
    "gpt-3.5-turbo-16k-0613",
    "gpt-3.5-turbo-0301",
    "gpt-4-0314",
    "gpt-4-32k-0314",
    "gpt-4-0613",
    "gpt-4-32k-0613",
    "gpt-4-1106-preview",
    "gpt-4-vision-preview",
    "gpt-4",
    "gpt-4-32k",
    "gpt-4-0613",
    "gpt-4-32k-0613",
    "gpt-4-0314",
    "gpt-4-32k-0314",
    "gpt-4o",
    "gpt-4o-mini",
    "gpt-4-turbo",
  ];

  async function addModel() {
    const res = await hitAxios({
      path: "/api/user/add_model",
      post: true,
      admin: false,
      obj: state,
    });
    if (res.data.success) {
      setState({ ...state, dialogAdd: false });
      getModels();
    }
  }

  return (
    <div>
      <Stack direction={"row"} spacing={2} justifyContent={"space-between"}>
        <UserToken />
        <ButtonGrid
          onClick={() => setState({ ...state, dialogAdd: true })}
          startIcon={<AddCircleOutline />}
          title={data.createModel}
        />
      </Stack>

      <SmallDialog
        title={data.createModel}
        dialogType={"fullWidth"}
        onClose={() => setState({ ...state, dialogAdd: false })}
        open={state.dialogAdd}
      >
        <Stack direction={"column"} spacing={3}>
          <TextFieldNew
            onChange={(e) =>
              setState({
                ...state,
                name: e.target.value,
                model_id: createSlug(e.target.value || ""),
              })
            }
            helperText={state.model_id}
            label={data.modelName}
          />
          <DragUpload
            onUrl={(e) => {
              setState({ ...state, icon: e });
            }}
          />

          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Chip
              color="info"
              icon={<PsychologyOutlined />}
              onClick={() => setState({ ...state, ai_type: "OPENAI" })}
              variant={state.ai_type === "OPENAI" ? "filled" : "outlined"}
              label={data.openAi}
              size="large"
            />
            <Chip
              color="info"
              icon={<PsychologyOutlined />}
              onClick={() => setState({ ...state, ai_type: "GEMINI" })}
              variant={state.ai_type === "GEMINI" ? "filled" : "outlined"}
              label={data.geminiAi}
              size="large"
            />
          </Stack>

          {state.ai_type === "OPENAI" && (
            <Autocomplete
              value={state.openai_model}
              onChange={(e, newValue) => {
                if (newValue?.label) {
                  setState({ ...state, openai_model: newValue?.label });
                }
              }}
              size="small"
              sx={{ "& fieldset": { borderRadius: 3 } }}
              id="combo-box-demo"
              options={gptModelArr.map((i) => {
                return {
                  label: i,
                };
              })}
              renderInput={(params) => (
                <TextField
                  InputProps={{
                    style: { borderRadius: 10 },
                  }}
                  size="small"
                  {...params}
                  label={data.selAiMode}
                />
              )}
            />
          )}

          <TextFieldNew
            onChange={(e) =>
              setState({ ...state, train_data: e.target?.value })
            }
            helperText={data.giveSmallAndClearTextInfo}
            multiline
            rows={4}
            label={data.trainData}
          />

          <Box borderRadius={2} p={1} bgcolor="action.hover">
            <Typography variant="caption" color={"gray"}>
              {data.memoryNote}
            </Typography>

            <Stack alignItems={"center"} direction={"row"} spacing={3}>
              <Slider
                aria-label="Temperature"
                defaultValue={30}
                value={state.memory}
                valueLabelDisplay="auto"
                shiftStep={30}
                step={1}
                marks
                min={0}
                onChange={(e) =>
                  setState({ ...state, memory: e?.target.value })
                }
                size="small"
                max={15}
              />

              <Tooltip title={data.memory}>
                <ContactSupport />
              </Tooltip>
              {/* <Typography>{data.memory}</Typography> */}
            </Stack>
          </Box>

          <Button onClick={addModel} variant="contained">
            {data.submitAi}
          </Button>
        </Stack>
      </SmallDialog>
    </div>
  );
};

export default CreateModel;

import React from "react";
import { GlobalContext } from "../../context/GlobalContext";
import {
  Box,
  CardMedia,
  Fade,
  Grid,
  Grow,
  IconButton,
  Slide,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import moment from "moment";

const ModelList = ({ data, model, setModel, getModels }) => {
  const { hitAxios } = React.useContext(GlobalContext);

  async function delModel(id) {
    if (window.confirm(data.aus)) {
      const res = await hitAxios({
        path: "/api/user/del_model",
        post: true,
        admin: false,
        obj: { id },
      });
      if (res.data.success) {
        getModels();
      }
    }
  }

  return (
    <Stack direction={"column"} spacing={2}>
      {model?.map((i, key) => {
        return (
          <Grow direction="left" in={true} timeout={600}>
            <Box key={key} bgcolor={"action.hover"} p={2} borderRadius={3}>
              <Tooltip title={`${data.trainData}: ${i?.train_data}`}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Stack spacing={2} direction={"row"}>
                    <CardMedia
                      sx={{
                        height: 60,
                        width: 60,
                        borderRadius: 3,
                      }}
                      src={`/media/${i?.icon}`}
                      component={"img"}
                    />
                    <Stack direction={"column"}>
                      <Typography> {i?.name}</Typography>
                      <Typography fontWeight={300} variant="caption">
                        {moment(i?.createdAt)?.fromNow()}
                      </Typography>
                    </Stack>
                  </Stack>

                  <IconButton
                    onClick={() => delModel(i?.id)}
                    color="error"
                    size="small"
                  >
                    <DeleteOutline sx={{ height: 30, width: 30 }} />
                  </IconButton>
                </Stack>
              </Tooltip>
            </Box>
          </Grow>
        );
      })}
    </Stack>
  );
};

export default ModelList;

import React from "react";
import TitlePanel from "../../common/TitlePanel";
import { Avatar, Box, Stack } from "@mui/material";
import { AccountTreeOutlined, Add } from "@mui/icons-material";
import UserToken from "../../common/UserToken";
import ButtonGrid from "../../common/ButtonGrid";
import CreateBot from "./components/CreateBot";
import { GlobalContext } from "../../context/GlobalContext";

const EmbedChatbot = ({ data }) => {
  const { hitAxios } = React.useContext(GlobalContext);
  const [state, setState] = React.useState({
    dialogAdd: false,
    modelId: false,
    dialogChat: false,
    dialogChatView: false,
  });
  return (
    <div>
      <TitlePanel
        icon={
          <Avatar
            sx={{
              width: 50,
              height: 50,
              bgcolor: "action.hover",
            }}
          >
            <AccountTreeOutlined
              sx={{
                color: (t) => t.palette.primary.main,
              }}
            />
          </Avatar>
        }
        data={data}
        place={data.codeWriter}
        title={data.codeWriter}
      />

      <Box mt={2}>
        <Stack direction={"row"} spacing={2} justifyContent={"space-between"}>
          <UserToken />

          <ButtonGrid
            onClick={() => setState({ ...state, dialogAdd: true })}
            startIcon={<Add />}
            title={data.createNew}
          />
        </Stack>

        <CreateBot
          hitAxios={hitAxios}
          state={state}
          data={data}
          setState={setState}
        />
      </Box>
    </div>
  );
};

export default EmbedChatbot;

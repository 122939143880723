import {
  ArrowCircleLeft,
  ArrowCircleRight,
  DisplaySettings,
  LibraryAddCheckOutlined,
  MovieFilterOutlined,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  IconButton,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import DragUpload from "../../../common/DragUpload";
import ButtonAnime from "../../../common/ButtonAnime";
import TextFieldNew from "../../../common/TextFieldNew";
import SelectChooseColor from "./SelectChooseColor";
import SliderComp from "../../../common/SliderComp";
import { GlobalContext } from "../../../context/GlobalContext";

const lang = [
  { language: "Auto", code: "au" },
  { language: "Afrikaans", code: "af" },
  { language: "Albanian", code: "sq" },
  { language: "Amharic", code: "am" },
  { language: "Arabic", code: "ar" },
  { language: "Armenian", code: "hy" },
  { language: "Assamese", code: "as" },
  { language: "Azerbaijani", code: "az" },
  { language: "Bashkir", code: "ba" },
  { language: "Basque", code: "eu" },
  { language: "Belarusian", code: "be" },
  { language: "Bengali", code: "bn" },
  { language: "Bosnian", code: "bs" },
  { language: "Breton", code: "br" },
  { language: "Bulgarian", code: "bg" },
  { language: "Burmese", code: "my" },
  { language: "Castilian", code: "es" },
  { language: "Catalan", code: "ca" },
  { language: "Chinese", code: "zh" },
  { language: "Croatian", code: "hr" },
  { language: "Czech", code: "cs" },
  { language: "Danish", code: "da" },
  { language: "Dutch", code: "nl" },
  { language: "English", code: "en" },
  { language: "Estonian", code: "et" },
  { language: "Faroese", code: "fo" },
  { language: "Finnish", code: "fi" },
  { language: "Flemish", code: "nl" },
  { language: "French", code: "fr" },
  { language: "Galician", code: "gl" },
  { language: "Georgian", code: "ka" },
  { language: "German", code: "de" },
  { language: "Greek", code: "el" },
  { language: "Gujarati", code: "gu" },
  { language: "Haitian Creole", code: "ht" },
  { language: "Hausa", code: "ha" },
  { language: "Hawaiian", code: "haw" },
  { language: "Hebrew", code: "he" },
  { language: "Hindi", code: "hi" },
  { language: "Hungarian", code: "hu" },
  { language: "Icelandic", code: "is" },
  { language: "Indonesian", code: "id" },
  { language: "Italian", code: "it" },
  { language: "Japanese", code: "ja" },
  { language: "Javanese", code: "jv" },
  { language: "Kannada", code: "kn" },
  { language: "Kazakh", code: "kk" },
  { language: "Khmer", code: "km" },
  { language: "Korean", code: "ko" },
  { language: "Lao", code: "lo" },
  { language: "Latin", code: "la" },
  { language: "Latvian", code: "lv" },
  { language: "Lingala", code: "ln" },
  { language: "Lithuanian", code: "lt" },
  { language: "Luxembourgish", code: "lb" },
  { language: "Macedonian", code: "mk" },
  { language: "Malagasy", code: "mg" },
  { language: "Malay", code: "ms" },
  { language: "Malayalam", code: "ml" },
  { language: "Maltese", code: "mt" },
  { language: "Maori", code: "mi" },
  { language: "Marathi", code: "mr" },
  { language: "Mongolian", code: "mn" },
  { language: "Nepali", code: "ne" },
  { language: "Norwegian", code: "no" },
  { language: "Nynorsk", code: "nn" },
  { language: "Occitan", code: "oc" },
  { language: "Pashto", code: "ps" },
  { language: "Persian", code: "fa" },
  { language: "Polish", code: "pl" },
  { language: "Portuguese", code: "pt" },
  { language: "Punjabi", code: "pa" },
  { language: "Romanian", code: "ro" },
  { language: "Russian", code: "ru" },
  { language: "Samoan", code: "sm" },
  { language: "Serbian", code: "sr" },
  { language: "Shona", code: "sn" },
  { language: "Sindhi", code: "sd" },
  { language: "Sinhala", code: "si" },
  { language: "Slovak", code: "sk" },
  { language: "Slovenian", code: "sl" },
  { language: "Somali", code: "so" },
  { language: "Spanish", code: "es" },
  { language: "Sundanese", code: "su" },
  { language: "Swahili", code: "sw" },
  { language: "Swedish", code: "sv" },
  { language: "Tagalog", code: "tl" },
  { language: "Tajik", code: "tg" },
  { language: "Tamil", code: "ta" },
  { language: "Tatar", code: "tt" },
  { language: "Telugu", code: "te" },
  { language: "Thai", code: "th" },
  { language: "Tibetan", code: "bo" },
  { language: "Turkish", code: "tr" },
  { language: "Turkmen", code: "tk" },
  { language: "Ukrainian", code: "uk" },
  { language: "Urdu", code: "ur" },
  { language: "Uyghur", code: "ug" },
  { language: "Uzbek", code: "uz" },
  { language: "Vietnamese", code: "vi" },
  { language: "Welsh", code: "cy" },
  { language: "Xhosa", code: "xh" },
  { language: "Yiddish", code: "yi" },
  { language: "Yoruba", code: "yo" },
  { language: "Zulu", code: "zu" },
];

const ChooseOther = ({ data, state, setState }) => {
  const { hitAxios } = React.useContext(GlobalContext);

  async function handleAdd(params) {
    const res = await hitAxios({
      path: "/api/video/create_video",
      post: true,
      admin: false,
      obj: { state },
    });
    if (res.data.success) {
      setState({
        ...state,
        doing: "create",
        step: "chooseAudio",
        chooseAudio: "manual",
        choosedAudio: "",
        choosedAudioText: "",
        chooseVideo: "manual",
        choosedVideo: "",
        choosedVideoImage: [],
        colorDialog: false,
        primaryColor: "#dcdfdd",
        secondaryColor: "#21130d",
        outlineColor: "#1786b4",
        backgroundColor: "#f1973f",
        videoResolution: "720x1280",
        fontFamily: "Verdana",
        fontEffect: "Blur",
        captionPosition: "middle",
        language: "Auto",
        fontSize: 18,
      });
    }
  }
  return (
    <Stack
      alignItems={"center"}
      justifyContent={"center"}
      spacing={4}
      direction={"column"}
      minHeight={300}
    >
      <Stack spacing={1} direction={"row"}>
        <DisplaySettings />
        <Typography variant="h4">{data.otherStuff}</Typography>
      </Stack>

      <Stack direction={"row"} spacing={2}>
        <TextField
          InputProps={{
            style: { borderRadius: 10 },
          }}
          size={"small"}
          label={data.fontSize}
          value={state.fontSize}
          type="number"
          onChange={(e) => setState({ ...state, fontSize: e.target?.value })}
        />

        <Autocomplete
          fullWidth
          value={state.language}
          componentsProps={{
            paper: {
              sx: {
                borderRadius: 2,
              },
            },
          }}
          onChange={(event, newValue) => {
            setState({
              ...state,
              language: newValue?.language ? newValue?.language : "Auto",
            });
          }}
          size="small"
          sx={{ "& fieldset": { borderRadius: 2 } }}
          options={lang.map((i) => {
            return {
              ...i,
              label: i.language,
            };
          })}
          renderInput={(params) => (
            <TextField fullWidth {...params} label={data.selLang} />
          )}
        />
      </Stack>

      <Box bgcolor={"white"} p={2} borderRadius={3}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          spacing={2}
          alignItems={"center"}
        >
          <Typography variant="caption" color={"gray"}>
            {data.captionOutline}
          </Typography>
          <Switch
            onChange={(e) =>
              setState({ ...state, outlineCaption: e.target.checked })
            }
            checked={state.outlineCaption}
            size="small"
          />

          <Typography variant="caption" color={"gray"}>
            {data.boldCaption}
          </Typography>
          <Switch
            onChange={(e) =>
              setState({ ...state, boldCaption: e.target.checked })
            }
            checked={state.boldCaption}
            size="small"
          />
        </Stack>
      </Box>

      <Stack direction={"row"} spacing={4}>
        <Tooltip title={data.back}>
          <IconButton
            onClick={() => setState({ ...state, step: "chooseStyle" })}
            color="success"
          >
            <ArrowCircleLeft sx={{ height: 50, width: 50 }} />
          </IconButton>
        </Tooltip>

        <Tooltip title={data.add}>
          <IconButton onClick={handleAdd} color="info">
            <ArrowCircleRight sx={{ height: 50, width: 50 }} />
          </IconButton>
        </Tooltip>
      </Stack>
    </Stack>
  );
};

export default ChooseOther;

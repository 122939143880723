import {
  Box,
  Button,
  CardMedia,
  Container,
  Fade,
  Grid,
  Grow,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import React from "react";
import { styled } from "@mui/system";
import ButtonGrid from "../../common/ButtonGrid";
import { PlayCircle, Psychology, VpnKeyOutlined } from "@mui/icons-material";
import DesComp from "./DesComp";
import { useHistory } from "react-router-dom";
import ButtonAnime from "../../common/ButtonAnime";
import ButtonAnime2 from "../../common/ButtonAnime2";

const GradientText = styled("span")(({ theme }) => ({
  background: "linear-gradient(90deg, #f25470 0%, #5f88fe 100%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  fontWeight: "bold",
}));

const Hero = ({ data }) => {
  const [show, setShow] = React.useState(true);
  const history = useHistory();

  React.useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 7500);
  }, []);
  return (
    <Box>
      <Container maxWidth="lg">
        <Grid alignItems={"center"} container minHeight={"90vh"}>
          <Grid
            sx={{
              display: {
                xs: "block",
                sm: "none",
                lg: "none",
                md: "none",
              },
            }}
            xs={12}
            sm={6}
            lg={6}
            item
          >
            <Box position={"relative"}>
              <CardMedia
                sx={{
                  filter: "drop-shadow(5px 5px 5px #222);",
                }}
                src="/assets/login_hero_ai.png"
                component={"img"}
              />
              <Box
                top={0}
                bottom={0}
                right={0}
                left={0}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                position={"absolute"}
              >
                <Tooltip title={data.playVieo}>
                  <ButtonAnime2>
                    <IconButton size="large">
                      <PlayCircle
                        sx={{
                          color: "white",
                          height: 40,
                          width: 40,
                        }}
                      />
                    </IconButton>
                  </ButtonAnime2>
                </Tooltip>
              </Box>
            </Box>
          </Grid>

          <Grid xs={12} sm={6} lg={6} item>
            <Stack direction={"column"} spacing={3}>
              <Stack spacing={0.5} direction={"column"}>
                <Typography variant="h1">
                  {data.welcomeTo}{" "}
                  <Grow in timeout={1500}>
                    <GradientText>{data.dallHAM}</GradientText>
                  </Grow>{" "}
                  {data.ai}
                </Typography>

                <DesComp data={data} />
              </Stack>

              <Stack direction={"row"}>
                <ButtonAnime>
                  {!show ? (
                    <Fade timeout={1000} in={!show}>
                      <div>
                        <ButtonGrid
                          onClick={() => history.push("/user")}
                          startIcon={<VpnKeyOutlined />}
                          size="large"
                          title={data.getStarted}
                        />
                      </div>
                    </Fade>
                  ) : (
                    <Button
                      startIcon={<VpnKeyOutlined />}
                      color="inherit"
                      size="large"
                      variant="contained"
                    >
                      {data.getStarted}
                    </Button>
                  )}
                </ButtonAnime>
              </Stack>
            </Stack>
          </Grid>

          <Grid
            sx={{
              display: {
                xs: "none",
                sm: "block",
                lg: "block",
                md: "block",
              },
            }}
            xs={12}
            sm={6}
            lg={6}
            item
          >
            <Box position={"relative"}>
              <CardMedia
                sx={{
                  filter: "drop-shadow(5px 5px 5px #222);",
                }}
                src="/assets/login_hero_ai.png"
                component={"img"}
              />
              {/* <Box
                top={0}
                bottom={0}
                right={0}
                left={0}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                position={"absolute"}
              >
                <Tooltip title={data.playVieo}>
                  <ButtonAnime>
                    <IconButton size="large">
                      <PlayCircle
                        sx={{
                          color: "white",
                          height: 40,
                          width: 40,
                        }}
                      />
                    </IconButton>
                  </ButtonAnime>
                </Tooltip>
              </Box> */}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Hero;

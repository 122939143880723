import {
  Box,
  Button,
  CardActionArea,
  CardMedia,
  Divider,
  IconButton,
  ImageList,
  Stack,
  Typography,
  Avatar,
} from "@mui/material";
import React from "react";
import { TranslateContext } from "../../context/TranslateContext";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import TextFieldNew from "../../common/TextFieldNew";
import { GlobalContext } from "../../context/GlobalContext";
import {
  AccountTreeOutlined,
  DeleteOutline,
  OpenInNew,
} from "@mui/icons-material";
import UpdatePrivacyPolicy from "./UpdatePrivacyPolicy";
import UpdateTc from "./UpdateTc";
import TitlePanel from "../../common/TitlePanel";

function generateSlug(str) {
  return str
    .toLowerCase()
    .trim() // Convert to lowercase and remove leading/trailing spaces
    .replace(/[^\w\s-]/g, "") // Remove non-word characters except spaces and hyphens
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/--+/g, "-"); // Replace consecutive hyphens with a single hyphen
}

const ManagePage = () => {
  const [state, setState] = React.useState({
    title: "",
  });
  const [page, setPage] = React.useState([]);
  const { data } = React.useContext(TranslateContext);
  const { hitAxios } = React.useContext(GlobalContext);

  async function addPage() {
    const fd = new FormData();
    fd.append("file", state.file);
    fd.append("title", state.title);
    fd.append("content", state.content);
    fd.append("slug", generateSlug(state?.title));
    const res = await hitAxios({
      path: "/api/admin/add_page",
      post: true,
      admin: true,
      obj: fd,
    });
    if (res.data.success) {
      setState({ ...state, title: "", content: "", file: "" });
      getPages();
    }
  }

  async function delPage(id) {
    if (window.confirm(data.AUS)) {
      const res = await hitAxios({
        path: "/api/admin/del_page",
        post: true,
        admin: true,
        obj: { id },
      });
      if (res.data.success) {
        getPages();
      }
    }
  }

  async function getPages() {
    const res = await hitAxios({
      path: "/api/admin/get_pages",
      post: false,
      admin: false,
      token_user: "",
    });
    if (res.data.success) {
      setPage(res.data.data);
    }
  }

  React.useEffect(() => {
    getPages();
  }, []);

  return (
    <div>
      <Box mt={2}>
        <TitlePanel
          icon={
            <Avatar
              sx={{
                width: 50,
                height: 50,
                bgcolor: "action.hover",
              }}
            >
              <AccountTreeOutlined
                sx={{
                  color: (t) => t.palette.primary.main,
                }}
              />
            </Avatar>
          }
          data={data}
          place={data.managePage}
          title={data.managePage}
        />

        <Stack
          direction={"row"}
          alignItems={"center"}
          spacing={2}
          justifyContent={"space-between"}
        >
          <Stack direction={"column"}>
            <Box pt={2}>
              <Stack direction={"row"} spacing={2}>
                <UpdatePrivacyPolicy hitAxios={hitAxios} data={data} />
                <UpdateTc hitAxios={hitAxios} data={data} />
              </Stack>
            </Box>
          </Stack>
        </Stack>

        <Box mt={2}>
          <Stack direction={"column"} spacing={2}>
            <TextFieldNew
              helperText={`${data.slug} ${generateSlug(state?.title)}`}
              onChange={(e) => setState({ ...state, title: e.target.value })}
              value={state.title}
              label={data.pagetitle}
            />

            <ReactQuill
              value={state.content}
              style={{ backgroundColor: "white", color: "black" }}
              theme="snow"
              onChange={(e) => setState({ ...state, content: e })}
            />

            <CardActionArea
              component="label"
              sx={{ padding: 4.3, bgcolor: "action.hover", borderRadius: 2 }}
            >
              <input
                hidden
                onChange={(e) =>
                  setState({ ...state, file: e.target.files[0] })
                }
                type="file"
                accept=".png, .jpeg, .jpg"
              />
              {state.file ? (
                <CardMedia
                  sx={{ height: "100%", width: 100 }}
                  component={"img"}
                  src={URL.createObjectURL(state?.file)}
                />
              ) : (
                <Typography align="center" variant="body2" color={"gray"}>
                  {data.selectImage}
                </Typography>
              )}
            </CardActionArea>

            <Button
              onClick={addPage}
              variant="contained"
              sx={{ textTransform: "none", borderRadius: 2, boxShadow: 0 }}
            >
              {data.add}
            </Button>

            <Divider />

            <ImageList
              sx={{
                gridAutoFlow: "column",
                gridTemplateColumns:
                  "repeat(auto-fill,minmax(160px,1fr)) !important",
                gridAutoColumns: "minmax(160px, 1fr)",
                msOverflowStyle: "none",
                overflowX: "scroll",
                "::-webkit-scrollbar": {
                  width: "10px",
                },
              }}
            >
              <Stack spacing={4} direction={"row"}>
                {page?.map((i, key) => {
                  return (
                    <Box
                      p={1}
                      borderRadius={4}
                      bgcolor={"#F5F5F5"}
                      minWidth={300}
                      key={key}
                    >
                      <Stack direction={"column"} spacing={1}>
                        <CardMedia
                          sx={{ height: 200, width: "100%", borderRadius: 4 }}
                          src={`/media/${i?.image}`}
                          component={"img"}
                        />
                        <Stack
                          justifyContent={"space-between"}
                          direction={"column"}
                        >
                          <Stack direction={"column"}>
                            <Typography fontWeight={500} variant="h6">
                              {i?.title}
                            </Typography>

                            <Typography
                              sx={{ display: "inline-block" }}
                              dangerouslySetInnerHTML={{
                                __html:
                                  i.content?.length > 65
                                    ? i.content?.slice(0, 65) + "..."
                                    : i.content,
                              }}
                              fontWeight={400}
                              variant="body2"
                            />
                          </Stack>

                          <Stack direction={"row"} justifyContent={"flex-end"}>
                            <Stack direction={"row"}>
                              <IconButton
                                onClick={() => {
                                  window.open(`/view/${i?.slug}`);
                                }}
                                color="info"
                              >
                                <OpenInNew sx={{ height: 16, width: 16 }} />
                              </IconButton>

                              <IconButton
                                onClick={() => delPage(i.id)}
                                color="error"
                              >
                                <DeleteOutline sx={{ height: 16, width: 16 }} />
                              </IconButton>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Box>
                  );
                })}
              </Stack>
            </ImageList>
          </Stack>
        </Box>
      </Box>
    </div>
  );
};

export default ManagePage;

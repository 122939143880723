import {
  Button,
  Chip,
  Dialog,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import SmallDialog from "../../common/SmallDialog";
import TextFieldNew from "../../common/TextFieldNew";
import { CheckCircleOutline, PaidOutlined } from "@mui/icons-material";
import SliderComp from "../../common/SliderComp";

const UpdateUser = ({
  data,
  userData,
  state,
  setState,
  editUser,
  setEditUser,
  hitAxios,
  getUsers,
}) => {
  const [plans, setPlans] = React.useState([]);

  async function getPlans() {
    const res = await hitAxios({
      path: "/api/web/get_plans",
      post: false,
      admin: false,
    });
    if (res.data.success) {
      setPlans(res.data.data);
    }
  }

  async function updateUser() {
    const res = await hitAxios({
      path: "/api/admin/update_user",
      post: true,
      admin: true,
      obj: editUser,
    });
    if (res.data.success) {
      getUsers();
    }
  }

  async function updatePlan(uid, planId) {
    const res = await hitAxios({
      path: "/api/admin/update_user_plan",
      post: true,
      admin: true,
      obj: {
        uid,
        planId,
      },
    });
    if (res.data.success) {
      setState({ ...state, dialog: false });
      getUsers();
    }
  }

  React.useEffect(() => {
    getPlans();
  }, []);

  return (
    <SmallDialog
      title={data.udpateUser}
      dialogType={"fullWidth"}
      onClose={() => setState({ ...state, dialog: false })}
      open={state.dialog}
    >
      <Stack direction={"column"} spacing={2}>
        <TextFieldNew
          onChange={(e) => setEditUser({ ...editUser, name: e.target.value })}
          value={editUser?.name}
          label={data.name}
        />

        <TextFieldNew
          onChange={(e) => setEditUser({ ...editUser, email: e.target.value })}
          value={editUser?.email}
          label={data.email}
        />

        <TextFieldNew
          type="number"
          onChange={(e) => setEditUser({ ...editUser, mobile: e.target.value })}
          value={editUser?.mobile}
          label={data.mobile}
        />

        <TextFieldNew
          onChange={(e) =>
            setEditUser({ ...editUser, newPassword: e.target.value })
          }
          helperText={data.newPasDes}
          label={data.newPassword}
        />

        <Button onClick={updateUser} variant="contained">
          {data.update}
        </Button>

        <Divider />

        <Stack direction={"row"} spacing={1} alignContent={"center"}>
          <PaidOutlined sx={{ height: 20, width: 20 }} />
          <Typography color={"gray"}>{data.updatePlan}</Typography>
        </Stack>

        <SliderComp spacing={2}>
          {plans.map((i, key) => {
            return (
              <Chip
                onClick={() => {
                  updatePlan(editUser?.uid, i?.id);
                }}
                variant={
                  editUser?.plan
                    ? JSON.parse(editUser?.plan)?.id === i?.id
                      ? "filled"
                      : "outlined"
                    : "outlined"
                }
                icon={i?.enrolled ? <CheckCircleOutline /> : null}
                sx={{
                  p: 2,
                }}
                color={
                  editUser?.plan
                    ? JSON.parse(editUser?.plan)?.id === i?.id
                      ? "success"
                      : "default"
                    : "default"
                }
                size="large"
                label={i?.name}
                key={key}
              />
            );
          })}
        </SliderComp>
      </Stack>
    </SmallDialog>
  );
};

export default UpdateUser;

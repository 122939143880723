import { CloudDownload, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  CardMedia,
  Container,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import SmallDialog from "../../../common/SmallDialog";
import TextFieldNew from "../../../common/TextFieldNew";
import ImageSearch from "./ImageSearch";
import { GlobalContext } from "../../../context/GlobalContext";

const REGEX = /\["(\bhttps?:\/\/[^"]+)",(\d+),(\d+)\],null/g;
const unicodeToString = (content) =>
  content.replace(/\\u[\dA-F]{4}/gi, (match) =>
    String.fromCharCode(parseInt(match.replace(/\\u/g, ""), 16))
  );

const ChooseVideoScrap = ({ data, state, setState }) => {
  const { hitAxios } = React.useContext(GlobalContext);
  const CON = React.useContext(GlobalContext);
  const [img, setImg] = React.useState([]);
  const [a, setA] = React.useState({
    dialog: false,
    keyword: "",
  });

  const fetchImages = async (searchTerm, options = {}) => {
    CON?.setData({ ...CON.data, loading: true });
    if (!searchTerm || typeof searchTerm !== "string")
      throw new TypeError("searchTerm must be a string.");
    if (typeof options !== "object")
      throw new TypeError("options argument must be an object.");

    const {
      query = {},
      userAgent = "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36",
    } = options;

    const response = await fetch(
      `https://cors-anywhere.herokuapp.com/http://www.google.com/search?${new URLSearchParams(
        {
          ...query,
          udm: "2",
          tbm: "isch",
          q: searchTerm,
        }
      )}`,
      {
        headers: { "User-Agent": userAgent },
      }
    );

    const body = await response.text();
    const content = body.slice(
      body.lastIndexOf("ds:1"),
      body.lastIndexOf("sideChannel")
    );
    const results = [];

    let result;
    while ((result = REGEX.exec(content))) {
      results.push({
        url: unicodeToString(result[1]),
        height: +result[2],
        width: +result[3],
      });
    }
    CON?.setData({ ...CON.data, loading: false });
    setImg(results);
    // return results;
  };

  const convertToBase64 = async (url) => {
    CON?.setData({ ...CON.data, loading: true });
    try {
      const response = await fetch(
        `https://cors-anywhere.herokuapp.com/${url}`
      );
      const blob = await response.blob();
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        addImage(reader.result);
      };
      CON?.setData({ ...CON.data, loading: false });
    } catch (error) {
      alert(data.opsImageNotsaved);
      CON?.setData({ ...CON.data, loading: false });
      console.error("Error converting image to base64:", error);
    }
  };

  async function addImage(base64Image) {
    const res = await hitAxios({
      path: "/api/video/add_google_image",
      post: true,
      admin: false,
      obj: { base64Image: base64Image },
    });
    if (res.data.success) {
      setState({
        ...state,
        choosedVideoImage: [...state.choosedVideoImage, res.data.fileName],
      });
      setA({ ...a, dialog: false });
    }
  }

  return (
    <div>
      <Stack direction={"column"} spacing={2} alignItems={"center"}>
        <Button
          variant="contained"
          onClick={() => setA({ ...a, dialog: true })}
          startIcon={<Search />}
        >
          {data.searchGoogle}
        </Button>

        <Container maxWidth="sm">
          <Typography align="center">
            {data.reqstDemo}&nbsp;
            <a
              style={{
                cursor: "pointer",
                color: "blue",
              }}
              onClick={() =>
                window.open(
                  `https://cors-anywhere.herokuapp.com/http://www.google.com/search?udm=2&tbm=isch&q=hello`
                )
              }
            >
              {data.clickHere}
            </a>
          </Typography>
        </Container>
      </Stack>

      <SmallDialog
        dialogType={"fullWidth"}
        title={data.searchGoogle}
        onClose={() => setA({ ...a, dialog: false })}
        open={a.dialog}
      >
        <Stack direction={"column"} spacing={2}>
          <TextFieldNew
            value={a.keyword}
            onChange={(e) => setA({ ...a, keyword: e.target.value })}
            endIcon={
              <IconButton
                onClick={() => fetchImages(a.keyword)}
                disabled={a.keyword ? false : true}
              >
                <Search />
              </IconButton>
            }
            variant={"outlined"}
            placeholder={data.naturImages}
          />
        </Stack>

        <Box mt={3}>
          <Grid container spacing={2}>
            {img?.slice(0, 80).map((i, key) => {
              return (
                <Grid key={key} item xs={3} sm={4} lg={4}>
                  <Box position={"relative"}>
                    <CardMedia
                      height={200}
                      sx={{
                        borderRadius: 1,
                      }}
                      component={"img"}
                      src={i?.url}
                    />
                    <Box
                      borderRadius={"50%"}
                      bgcolor={"rgba(255, 255, 255, 0.5)"}
                      position={"absolute"}
                      bottom={5}
                      right={5}
                    >
                      <Tooltip title={data.import}>
                        <IconButton
                          onClick={() => convertToBase64(i?.url)}
                          size="small"
                          color="inherit"
                        >
                          <CloudDownload />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </SmallDialog>
    </div>
  );
};

export default ChooseVideoScrap;

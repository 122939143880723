import { Campaign } from "@mui/icons-material";
import {
  Box,
  CardMedia,
  Fade,
  Slide,
  Stack,
  Typography,
  Zoom,
} from "@mui/material";
import React, { useState, useEffect } from "react";

const SpeechTt = ({ data }) => {
  const endNumber = 100;
  const duration = 1.5;
  const [count, setCount] = useState(0);
  const [show, setShow] = React.useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 1000);
    const countToNumber = (endNumber, totalDurationInSeconds) => {
      return new Promise((resolve) => {
        const totalSteps = endNumber + 1;
        const delayBetweenSteps = (totalDurationInSeconds * 1000) / totalSteps;

        let currentNumber = 0;
        const interval = setInterval(() => {
          setCount(currentNumber);
          currentNumber += 1;

          if (currentNumber > endNumber) {
            clearInterval(interval);
            resolve();
          }
        }, delayBetweenSteps);
      });
    };

    countToNumber(endNumber, duration);
  }, [endNumber, duration]);

  return (
    <div>
      <Box mt={5}>
        <Stack alignItems={"center"} spacing={2} direction={"row"}>
          <Campaign
            sx={{
              height: 40,
              width: 40,
            }}
          />

          <Fade direction="left" in timeout={1000}>
            <CardMedia
              sx={{
                width: {
                  lg: "100%",
                  md: "100%",
                  sm: "90%",
                  xs: "85%",
                },
              }}
              src="/assets/vc.png"
              component={"img"}
            />
          </Fade>
        </Stack>

        <Box mt={5}>
          {show && (
            <Fade in timeout={1000}>
              <Typography variant="h5" fontWeight={100}>
                {data.loremLopez}
              </Typography>
            </Fade>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default SpeechTt;

import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Button,
  Stack,
  Paper,
  Typography,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import FooterAction from "./components/FooterAction";
import TopBar from "./components/TopBar";
import WhileNoChat from "./components/WhileNoChat";
import SelectModel from "./components/SelectModel";
import { GlobalContext } from "../../context/GlobalContext";
import ChatHistory from "./components/ChatHistory";
import MessageMain from "./messages/MessageMain";

const ChatContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "87vh",
  // maxWidth: "600px",
  margin: "auto",
  // border: "1px solid #ddd",
  // borderRadius: "8px",
}));

const MessageList = styled(Paper)(({ theme }) => ({
  flex: 1,
  overflowY: "scroll",
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  // backgroundColor: theme?.palette?.primary?.dark,
}));

// const Message = styled(Paper)(({ theme, sender }) => ({
//   alignSelf: sender === "user" ? "flex-end" : "flex-start",
//   padding: theme.spacing(2),
//   borderRadius: "20px",
//   backgroundColor: sender === "user" ? "#dcf8c6" : "#ffffff",
//   maxWidth: "70%",
//   marginBottom: theme.spacing(1),
// }));

const Footer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1),
  //   borderTop: "1px solid #ddd",
  //   backgroundColor: "#f9f9f9",
}));

const AiChat = ({ data }) => {
  const { hitAxios } = React.useContext(GlobalContext);
  const [state, setState] = React.useState({
    selectModelDialog: false,
    historyDialog: false,
  });
  const [chat, setChat] = React.useState({});
  const [messages, setMessages] = useState([]);
  const [history, setHistory] = React.useState([]);
  const [typeMsg, setTypeMsg] = React.useState("");

  const endOfMessagesRef = useRef(null);

  async function getChatHistory() {
    const res = await hitAxios({
      path: "/api/chat/get_my_chats",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      setHistory(res.data.data);
    }
  }

  React.useEffect(() => {
    getChatHistory();
  }, []);

  useEffect(() => {
    // Scroll to the bottom whenever messages change
    endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <ChatContainer>
      <TopBar
        setMessages={setMessages}
        state={state}
        setState={setState}
        chat={chat}
        data={data}
      />
      <MessageList elevation={0}>
        {messages?.length > 0 && (
          <MessageMain messages={messages} data={data} />
        )}

        {/* msglist */}
        {!chat?.selectedModel && (
          <WhileNoChat data={data} state={state} setState={setState} />
        )}

        {/* Empty div to use as the scroll target */}
        <div ref={endOfMessagesRef} />
      </MessageList>

      <Footer>
        <FooterAction
          setTypeMsg={setTypeMsg}
          typeMsg={typeMsg}
          data={data}
          state={state}
          setState={setState}
          chat={chat}
          hitAxios={hitAxios}
          setChat={setChat}
          setMessages={setMessages}
          messages={messages}
        />
      </Footer>

      <SelectModel
        getChatHistory={getChatHistory}
        setMessages={setMessages}
        data={data}
        state={state}
        setState={setState}
        chat={chat}
        setChat={setChat}
        hitAxios={hitAxios}
      />

      <ChatHistory
        getChatHistory={getChatHistory}
        history={history}
        setHistory={setHistory}
        setMessages={setMessages}
        messages={messages}
        data={data}
        state={state}
        setState={setState}
        chat={chat}
        setChat={setChat}
        hitAxios={hitAxios}
      />
    </ChatContainer>
  );
};

export default AiChat;

import React from "react";
import TitlePanel from "../../../common/TitlePanel";
import { Avatar, Box, Grid, Stack, Typography } from "@mui/material";
import {
  AccountTreeOutlined,
  AutoAwesome,
  AutoFixHigh,
  Chat,
  ContactMail,
  Group,
  GroupOutlined,
  Image,
  Paid,
  SlowMotionVideo,
  VoiceChat,
} from "@mui/icons-material";
import { GlobalContext } from "../../../context/GlobalContext";

const Dash = ({ data }) => {
  const [dash, setDash] = React.useState({});
  const { hitAxios } = React.useContext(GlobalContext);
  const [web, setWeb] = React.useState({});

  async function getDash(params) {
    const res = await hitAxios({
      path: "/api/admin/get_dash",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_admin"),
    });
    if (res.data.success) {
      setDash({ ...dash, ...res.data });
    }
  }

  async function getWeb() {
    const res = await hitAxios({
      path: "/api/web/get_web_public",
      post: false,
      admin: false,
      token_user: "",
    });
    if (res.data.success) {
      setWeb(res.data.data);
    }
  }

  React.useEffect(() => {
    getDash();
    getWeb();
  }, []);
  return (
    <div>
      <TitlePanel
        icon={
          <Avatar
            sx={{
              width: 50,
              height: 50,
              bgcolor: "action.hover",
            }}
          >
            <AccountTreeOutlined
              sx={{
                color: (t) => t.palette.primary.main,
              }}
            />
          </Avatar>
        }
        data={data}
        place={data.dashboard}
        title={data.dashboard}
      />

      <Box mt={3}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={4} lg={4}>
            <Box bgcolor={"action.hover"} p={3} borderRadius={3}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"flex-end"}
              >
                <Stack spacing={2} direction={"column"}>
                  <Group />
                  <Typography>{data.totalUsers}</Typography>
                </Stack>
                <Typography
                  sx={{
                    background:
                      "linear-gradient(90deg, #f25470 0%, #5f88fe 100%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontWeight: 300,
                  }}
                  variant="h1"
                >
                  {dash.users}
                </Typography>
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={6} sm={4} lg={4}>
            <Box bgcolor={"action.hover"} p={3} borderRadius={3}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"flex-end"}
              >
                <Stack spacing={2} direction={"column"}>
                  <GroupOutlined />
                  <Typography>{data.signedupThisMonth}</Typography>
                </Stack>
                <Typography
                  sx={{
                    background:
                      "linear-gradient(90deg, #f25470 0%, #5f88fe 100%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontWeight: 300,
                  }}
                  variant="h1"
                >
                  {dash.userThis}
                </Typography>
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={6} sm={4} lg={4}>
            <Box bgcolor={"action.hover"} p={3} borderRadius={3}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"flex-end"}
              >
                <Stack spacing={2} direction={"column"}>
                  <Paid />
                  <Typography>{data.earningThisMOnth}</Typography>
                </Stack>
                <Typography
                  sx={{
                    background:
                      "linear-gradient(90deg, #f25470 0%, #5f88fe 100%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontWeight: 300,
                  }}
                  variant="h1"
                >
                  {web?.currency_code}
                  {dash.orders}
                </Typography>
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={6} sm={4} lg={4}>
            <Box bgcolor={"action.hover"} p={3} borderRadius={3}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"flex-end"}
              >
                <Stack spacing={2} direction={"column"}>
                  <Chat />
                  <Typography>{data.totalChats}</Typography>
                </Stack>
                <Typography
                  sx={{
                    background:
                      "linear-gradient(90deg, #f25470 0%, #5f88fe 100%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontWeight: 300,
                  }}
                  variant="h1"
                >
                  {dash.chat}
                </Typography>
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={6} sm={4} lg={4}>
            <Box bgcolor={"action.hover"} p={3} borderRadius={3}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"flex-end"}
              >
                <Stack spacing={2} direction={"column"}>
                  <Image />
                  <Typography>{data.taotalGenImg}</Typography>
                </Stack>
                <Typography
                  sx={{
                    background:
                      "linear-gradient(90deg, #f25470 0%, #5f88fe 100%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontWeight: 300,
                  }}
                  variant="h1"
                >
                  {dash.image}
                </Typography>
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={6} sm={4} lg={4}>
            <Box bgcolor={"action.hover"} p={3} borderRadius={3}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"flex-end"}
              >
                <Stack spacing={2} direction={"column"}>
                  <SlowMotionVideo />
                  <Typography>{data.totalAiVideos}</Typography>
                </Stack>
                <Typography
                  sx={{
                    background:
                      "linear-gradient(90deg, #f25470 0%, #5f88fe 100%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontWeight: 300,
                  }}
                  variant="h1"
                >
                  {dash.video}
                </Typography>
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={6} sm={4} lg={4}>
            <Box bgcolor={"action.hover"} p={3} borderRadius={3}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"flex-end"}
              >
                <Stack spacing={2} direction={"column"}>
                  <ContactMail />
                  <Typography>{data.totalLeads}</Typography>
                </Stack>
                <Typography
                  sx={{
                    background:
                      "linear-gradient(90deg, #f25470 0%, #5f88fe 100%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontWeight: 300,
                  }}
                  variant="h1"
                >
                  {dash.lead}
                </Typography>
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={6} sm={4} lg={4}>
            <Box bgcolor={"action.hover"} p={3} borderRadius={3}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"flex-end"}
              >
                <Stack spacing={2} direction={"column"}>
                  <VoiceChat />
                  <Typography>{data.totalGenVoice}</Typography>
                </Stack>
                <Typography
                  sx={{
                    background:
                      "linear-gradient(90deg, #f25470 0%, #5f88fe 100%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontWeight: 300,
                  }}
                  variant="h1"
                >
                  {dash.voice}
                </Typography>
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={6} sm={4} lg={4}>
            <Box bgcolor={"action.hover"} p={3} borderRadius={3}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"flex-end"}
              >
                <Stack spacing={2} direction={"column"}>
                  <AutoFixHigh />
                  <Typography>{data.totalAiModel}</Typography>
                </Stack>
                <Typography
                  sx={{
                    background:
                      "linear-gradient(90deg, #f25470 0%, #5f88fe 100%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontWeight: 300,
                  }}
                  variant="h1"
                >
                  {dash.model}
                </Typography>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Dash;

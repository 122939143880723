import { Box, Stack } from "@mui/material";
import React from "react";
import OneMsg from "./OneMsg";

const MessageMain = ({ messages }) => {
  return (
    <div>
      <Stack direction={"column"} spacing={2}>
        {messages?.map((i, key) => {
          return <OneMsg i={i} key={key} />;
        })}
      </Stack>
    </div>
  );
};

export default MessageMain;

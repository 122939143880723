import React from "react";
import TitlePanel from "../../../common/TitlePanel";
import { Avatar, Box, Grid, Stack, Typography } from "@mui/material";
import {
  AccountTreeOutlined,
  Assistant,
  AutoAwesome,
  FormatTextdirectionLToR,
  PhotoFilter,
  RecordVoiceOver,
  SlowMotionVideo,
} from "@mui/icons-material";
import { GlobalContext } from "../../../context/GlobalContext";

const DashComp = ({ data }) => {
  const { hitAxios } = React.useContext(GlobalContext);
  const [dash, setDash] = React.useState({});

  async function getDash(params) {
    const res = await hitAxios({
      path: "/api/user/get_dash",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      setDash({ ...dash, ...res.data });
    }
  }

  React.useEffect(() => {
    getDash();
  }, []);

  return (
    <div>
      <TitlePanel
        icon={
          <Avatar
            sx={{
              width: 50,
              height: 50,
              bgcolor: "action.hover",
            }}
          >
            <AccountTreeOutlined
              sx={{
                color: (t) => t.palette.primary.main,
              }}
            />
          </Avatar>
        }
        data={data}
        place={data.dashboard}
        title={data.dashboard}
      />

      <Box mt={3}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={4} lg={4}>
            <Box bgcolor={"action.hover"} p={3} borderRadius={3}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"flex-end"}
              >
                <Stack spacing={2} direction={"column"}>
                  <AutoAwesome />
                  <Typography>{data.aiModels}</Typography>
                </Stack>
                <Typography
                  sx={{
                    background:
                      "linear-gradient(90deg, #f25470 0%, #5f88fe 100%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontWeight: 300,
                  }}
                  variant="h1"
                >
                  {dash.model}
                </Typography>
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={6} sm={4} lg={4}>
            <Box bgcolor={"action.hover"} p={3} borderRadius={3}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"flex-end"}
              >
                <Stack spacing={2} direction={"column"}>
                  <Assistant />
                  <Typography>{data.aiChats}</Typography>
                </Stack>
                <Typography
                  sx={{
                    background:
                      "linear-gradient(90deg, #f25470 0%, #5f88fe 100%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontWeight: 300,
                  }}
                  variant="h1"
                >
                  {dash.chat}
                </Typography>
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={6} sm={4} lg={4}>
            <Box bgcolor={"action.hover"} p={3} borderRadius={3}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"flex-end"}
              >
                <Stack spacing={2} direction={"column"}>
                  <PhotoFilter />
                  <Typography>{data.imagesGenerated}</Typography>
                </Stack>
                <Typography
                  sx={{
                    background:
                      "linear-gradient(90deg, #f25470 0%, #5f88fe 100%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontWeight: 300,
                  }}
                  variant="h1"
                >
                  {dash.image}
                </Typography>
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={6} sm={4} lg={4}>
            <Box bgcolor={"action.hover"} p={3} borderRadius={3}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"flex-end"}
              >
                <Stack spacing={2} direction={"column"}>
                  <FormatTextdirectionLToR />
                  <Typography>{data.speechToText}</Typography>
                </Stack>
                <Typography
                  sx={{
                    background:
                      "linear-gradient(90deg, #f25470 0%, #5f88fe 100%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontWeight: 300,
                  }}
                  variant="h1"
                >
                  {dash.speech}
                </Typography>
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={6} sm={4} lg={4}>
            <Box bgcolor={"action.hover"} p={3} borderRadius={3}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"flex-end"}
              >
                <Stack spacing={2} direction={"column"}>
                  <RecordVoiceOver />
                  <Typography>{data.voiceMaker}</Typography>
                </Stack>
                <Typography
                  sx={{
                    background:
                      "linear-gradient(90deg, #f25470 0%, #5f88fe 100%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontWeight: 300,
                  }}
                  variant="h1"
                >
                  {dash.voice}
                </Typography>
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={6} sm={4} lg={4}>
            <Box bgcolor={"action.hover"} p={3} borderRadius={3}>
              <Stack
                direction={"row"}
                alignItems={"flex-end"}
                justifyContent={"space-between"}
              >
                <Stack spacing={2} direction={"column"}>
                  <SlowMotionVideo />
                  <Typography>{data.aiVideos}</Typography>
                </Stack>
                <Typography
                  sx={{
                    background:
                      "linear-gradient(90deg, #f25470 0%, #5f88fe 100%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontWeight: 300,
                  }}
                  variant="h1"
                >
                  {dash.video}
                </Typography>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default DashComp;

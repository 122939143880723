import { Box } from "@mui/material";
import React from "react";
import "./button.css";

const ButtonAnime = ({ children, ...props }) => {
  return (
    <Box {...props} className="gradient-border-animation">
      {children}
    </Box>
  );
};

export default ButtonAnime;

import React from "react";
import {
  Stack,
  Avatar,
  Box,
  Chip,
  Typography,
  IconButton,
  Tooltip,
  Grow,
} from "@mui/material";
import {
  AccountTreeOutlined,
  DeleteOutline,
  Downloading,
  RecordVoiceOver,
  RecordVoiceOverOutlined,
} from "@mui/icons-material";
import TitlePanel from "../../common/TitlePanel";
import TextFieldNew from "../../common/TextFieldNew";
import SliderComp from "../../common/SliderComp";
import ButtonGrid from "../../common/ButtonGrid";
import { GlobalContext } from "../../context/GlobalContext";
import ShadowBox from "../../common/ShadowBox";
import moment from "moment";
import UserToken from "../../common/UserToken";

const VoiceMaker = ({ data }) => {
  const { hitAxios } = React.useContext(GlobalContext);
  const [voice, setVoice] = React.useState([]);
  const [state, setState] = React.useState({
    voice: "alloy",
    prompt: "",
  });
  const voiceNames = ["alloy", "echo", "fable", "nova", "onyx", "shimmer"];

  async function getVoice() {
    const res = await hitAxios({
      path: "/api/user/get_voice",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      setVoice(res.data.data);
      setState({ ...state, prompt: "" });
    }
  }

  async function genVoie() {
    const res = await hitAxios({
      path: "/api/user/gen_ai_voice",
      post: true,
      admin: false,
      obj: state,
    });
    if (res.data.success) {
      getVoice();
    }
  }

  async function delVoice(id, filename) {
    if (window.confirm(data.aus)) {
      const res = await hitAxios({
        path: "/api/user/del_voice",
        post: true,
        admin: false,
        obj: { id, filename },
      });
      if (res.data.success) {
        getVoice();
      }
    }
  }

  React.useEffect(() => {
    getVoice();
  }, []);

  return (
    <div>
      <Stack direction={"column"} spacing={3}>
        <TitlePanel
          icon={
            <Avatar
              sx={{
                width: 50,
                height: 50,
                bgcolor: "action.hover",
              }}
            >
              <AccountTreeOutlined
                sx={{
                  color: (t) => t.palette.primary.main,
                }}
              />
            </Avatar>
          }
          data={data}
          place={data.voiceMaker}
          title={data.voiceMaker}
        />

        <UserToken />

        <ShadowBox p={3} borderRadius={2}>
          <Stack direction={"column"} spacing={3}>
            <TextFieldNew
              value={state.prompt}
              onChange={(e) => setState({ ...state, prompt: e.target.value })}
              multiline
              rows={3}
              label={data.enterYourText}
            />
            <SliderComp spacing={2}>
              {voiceNames?.map((i, key) => {
                return (
                  <Chip
                    onClick={() => {
                      setState({ ...state, voice: i });
                    }}
                    variant={i == state.voice ? "filled" : "outlined"}
                    label={i}
                  />
                );
              })}
            </SliderComp>

            <Stack alignItems={"flex-end"}>
              <ButtonGrid
                onClick={genVoie}
                sx={{
                  width: {
                    lg: "20%",
                    sm: "50%",
                    md: "40%",
                    sm: "100%",
                  },
                }}
                startIcon={<RecordVoiceOverOutlined />}
                title={data.genVoice}
              />
            </Stack>
          </Stack>
        </ShadowBox>

        <Stack direction={"column"} spacing={2}>
          {voice.map((i, key) => {
            return (
              <Grow in timeout={1000}>
                <Box key={key} borderRadius={2} bgcolor={"action.hover"} p={2}>
                  <Stack
                    alignItems={"center"}
                    direction={"row"}
                    spacing={2}
                    justifyContent={"space-between"}
                  >
                    <Stack direction={"row"} spacing={2}>
                      <Tooltip title={i.prompt}>
                        <RecordVoiceOver sx={{ height: 40, width: 40 }} />
                      </Tooltip>
                      <Stack direction={"column"}>
                        <Typography>
                          {moment(i?.createdAt).fromNow()}
                        </Typography>
                        <Typography color={"gray"} variant="caption">
                          {i?.voice}
                        </Typography>
                      </Stack>
                    </Stack>

                    <Stack direction={"row"}>
                      <IconButton
                        color="error"
                        onClick={() => delVoice(i?.id, i?.filename)}
                      >
                        <DeleteOutline />
                      </IconButton>

                      <IconButton
                        onClick={() => window.open(`/speech/${i?.filename}`)}
                      >
                        <Downloading />
                      </IconButton>
                    </Stack>
                  </Stack>
                </Box>
              </Grow>
            );
          })}
        </Stack>
      </Stack>
    </div>
  );
};

export default VoiceMaker;

import React from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
  Avatar,
} from "@mui/material";
import { TranslateContext } from "../../context/TranslateContext";
import { GlobalContext } from "../../context/GlobalContext";
import TextFieldNew from "../../common/TextFieldNew";
import {
  AccountTreeOutlined,
  DeleteOutline,
  Fitbit,
  HelpOutline,
} from "@mui/icons-material";
import TitlePanel from "../../common/TitlePanel";

const Faq = () => {
  const { data } = React.useContext(TranslateContext);
  const [state, setState] = React.useState({
    faq: [],
  });
  const { hitAxios } = React.useContext(GlobalContext);

  async function getFaq() {
    const res = await hitAxios({
      path: "/api/admin/get_faq",
      post: false,
      admin: false,
      token_user: "",
    });
    if (res.data.success) {
      setState({ ...state, faq: res.data.data, question: "", answer: "" });
    }
  }

  React.useEffect(() => {
    getFaq();
  }, []);

  async function addFaq() {
    const res = await hitAxios({
      path: "/api/admin/add_faq",
      post: true,
      admin: true,
      obj: state,
    });
    if (res.data.success) {
      getFaq();
    }
  }

  async function delFaq(id) {
    if (window.confirm(data.aus)) {
      const res = await hitAxios({
        path: "/api/admin/del_faq",
        post: true,
        admin: true,
        obj: { id },
      });
      if (res.data.success) {
        getFaq();
      }
    }
  }

  return (
    <div>
      <Box mt={2}>
        <TitlePanel
          icon={
            <Avatar
              sx={{
                width: 50,
                height: 50,
                bgcolor: "action.hover",
              }}
            >
              <AccountTreeOutlined
                sx={{
                  color: (t) => t.palette.primary.main,
                }}
              />
            </Avatar>
          }
          data={data}
          place={data.faq}
          title={data.faq}
        />

        <Box mt={4}>
          <Stack direction={"column"} spacing={2}>
            <TextFieldNew
              onChange={(e) => setState({ ...state, question: e.target.value })}
              value={state.question}
              label={data.question}
            />
            <TextFieldNew
              onChange={(e) => setState({ ...state, answer: e.target.value })}
              value={state.answer}
              multiline
              rows={2}
              label={data.answer}
            />

            <Button
              onClick={addFaq}
              variant="contained"
              sx={{ textTransform: "none", borderRadius: 2, boxShadow: 0 }}
            >
              {data.add}
            </Button>

            <Divider />

            <Box p={2} borderRadius={4} bgcolor={"action.hover"}>
              <Stack direction={"column"} spacing={3}>
                {state.faq?.map((i, key) => {
                  return (
                    <>
                      <Stack key={key} direction={"column"} spacing={1}>
                        <Stack
                          direction={"row"}
                          spacing={2}
                          justifyContent={"space-between"}
                        >
                          <Stack
                            alignItems={"center"}
                            direction={"row"}
                            spacing={1}
                          >
                            <HelpOutline />
                            <Typography variant="h6" fontWeight={600}>
                              {i?.question}
                            </Typography>
                          </Stack>
                          <IconButton
                            onClick={() => delFaq(i?.id)}
                            color="error"
                          >
                            <DeleteOutline />
                          </IconButton>
                        </Stack>

                        <Typography fontWeight={370}>{i?.answer}</Typography>
                      </Stack>
                      <Divider />
                    </>
                  );
                })}
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Box>
    </div>
  );
};

export default Faq;

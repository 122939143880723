import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const LogOutAdmin = ({ data }) => {
  const history = useHistory();
  return (
    <div>
      <Stack direction={"column"} spacing={2}>
        <Typography>{data.areYouSureYouwanttoLogout}</Typography>

        <Stack direction={"row"}>
          <Button
            onClick={() => {
              if (window.confirm(data.aus)) {
                localStorage.removeItem(process.env.REACT_APP_TOKEN + "_admin");
                history.push("/");
              }
            }}
            variant="contained"
            color="warning"
          >
            {data.logout}
          </Button>
        </Stack>
      </Stack>
    </div>
  );
};

export default LogOutAdmin;

import {
  AddLink,
  DeleteOutline,
  OpenInNew,
  SpeakerNotes,
  Visibility,
} from "@mui/icons-material";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import ViewChatList from "./ViewChatList";

const BotList = ({ data, state, setState, bot, getList }) => {
  const { hitAxios } = React.useContext(GlobalContext);

  async function delChat(id) {
    if (window.confirm(data.aus)) {
      const res = await hitAxios({
        path: "/api/embed/del_chatbot",
        post: true,
        admin: false,
        obj: { id },
      });
      if (res.data.success) {
        getList();
      }
    }
  }

  return (
    <div>
      <Stack direction={"column"} spacing={2}>
        {bot?.map((i, key) => {
          return (
            <Box bgcolor={"action.hover"} key={key} p={2} borderRadius={2}>
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                  <SpeakerNotes />
                  <Typography>
                    {data?.id}: <a style={{ color: "gray" }}>{i?.bot_id}</a>
                  </Typography>

                  <Tooltip title={data.copyBotLInk}>
                    <IconButton
                      onClick={() => window.open(`/chat/embed?id=${i?.bot_id}`)}
                    >
                      <OpenInNew />
                    </IconButton>
                  </Tooltip>
                </Stack>

                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                  <ViewChatList
                    state={state}
                    setState={setState}
                    data={data}
                    i={i}
                  />

                  <IconButton onClick={() => delChat(i?.id)} color="error">
                    <DeleteOutline />
                  </IconButton>
                </Stack>
              </Stack>
            </Box>
          );
        })}
      </Stack>
    </div>
  );
};

export default BotList;

import { Badge, Box, CardMedia, Stack, Typography } from "@mui/material";
import React from "react";

const TitlePanel = ({ data, title, place, firstTitle, icon }) => {
  return (
    <div>
      <Box
        sx={{
          bgcolor: (t) => t.palette.primary.light,
          borderTopRightRadius: 15,
          borderBottomLeftRadius: 15,
          borderTopLeftRadius: 15,
        }}
      >
        <Stack
          justifyContent={"space-between"}
          alignItems={"center"}
          direction={"row"}
          spacing={2}
        >
          <Stack p={3} spacing={1} direction={"column"}>
            <Typography fontWeight={520} fontSize={20}>
              {title}
            </Typography>
            <Stack direction={"row"} spacing={1.8} alignItems={"center"}>
              <Typography fontWeight={200} fontSize={14}>
                {firstTitle || data.home}
              </Typography>
              <Box borderRadius={"50%"} bgcolor={"gray"} p={0.3} />
              <Typography fontWeight={200} fontSize={14}>
                {place}
              </Typography>
            </Stack>
          </Stack>

          {/* {icon} */}
          <Box>
            <CardMedia
              sx={{
                height: 100,
                width: "100%",
                borderBottomRightRadius: 15,
                borderTopRightRadius: 15,
              }}
              component={"img"}
              src="/assets/3d.png"
            />
          </Box>
        </Stack>
      </Box>
    </div>
  );
};

export default TitlePanel;

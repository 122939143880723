import React from "react";
import TitlePanel from "../../common/TitlePanel";
import { AccountTreeOutlined } from "@mui/icons-material";
import { Avatar, Box, Grid } from "@mui/material";
import Pricing from "../../common/Pricing";
import { GlobalContext } from "../../context/GlobalContext";

const Subscription = ({ data }) => {
  const { hitAxios } = React.useContext(GlobalContext);
  const [planData, setPlanData] = React.useState([]);

  async function getPlan() {
    const res = await hitAxios({
      path: "/api/web/get_plans",
      post: false,
      admin: false,
    });
    if (res.data.success) {
      setPlanData(res.data.data);
    }
  }

  React.useEffect(() => {
    getPlan();
  }, []);

  return (
    <div>
      <TitlePanel
        icon={
          <Avatar
            sx={{
              width: 50,
              height: 50,
              bgcolor: "action.hover",
            }}
          >
            <AccountTreeOutlined
              sx={{
                color: (t) => t.palette.primary.main,
              }}
            />
          </Avatar>
        }
        data={data}
        place={data.apiKeys}
        title={data.apiKeys}
      />

      <Box mt={3}>
        <Grid direction={"row"} spacing={2} container>
          <Pricing
            user
            getPlan={getPlan}
            planData={planData}
            data={data}
            hitAxios={hitAxios}
          />
        </Grid>
      </Box>
    </div>
  );
};

export default Subscription;

import {
  Avatar,
  Box,
  CardActionArea,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { TranslateContext } from "../../context/TranslateContext";
import { GlobalContext } from "../../context/GlobalContext";
import SlideShow from "../../components/SlideShow";
import TitlePanel from "../../common/TitlePanel";
import { AccountTreeOutlined } from "@mui/icons-material";

const PartnersLogo = () => {
  const { data } = React.useContext(TranslateContext);
  const { hitAxios } = React.useContext(GlobalContext);
  const [state, setState] = React.useState({
    brands: [],
  });

  async function getBrands() {
    const res = await hitAxios({
      path: "/api/admin/get_brands",
      post: false,
      admin: false,
      token_user: "",
    });
    if (res.data.success) {
      setState({ ...state, brands: res.data.data });
    }
  }

  React.useEffect(() => {
    getBrands();
  }, []);

  async function addLogo(file) {
    const fd = new FormData();
    fd.append("file", file);
    const res = await hitAxios({
      path: "/api/admin/add_brand_image",
      post: true,
      admin: true,
      obj: fd,
    });
    if (res.data.success) {
      window.location.reload();
    }
  }

  return (
    <div>
      <Box mt={2}>
        <TitlePanel
          icon={
            <Avatar
              sx={{
                width: 50,
                height: 50,
                bgcolor: "action.hover",
              }}
            >
              <AccountTreeOutlined
                sx={{
                  color: (t) => t.palette.primary.main,
                }}
              />
            </Avatar>
          }
          data={data}
          place={data.fronPartners}
          title={data.fronPartners}
        />

        <Box mt={4}>
          <Stack direction={"column"} spacing={3}>
            <CardActionArea
              component="label"
              sx={{
                padding: 6,
                borderRadius: 3,
                bgcolor: "action.hover",
              }}
            >
              <input
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    const fileSizeInKB = file.size / 1024; // Convert bytes to KB
                    if (fileSizeInKB > 200) {
                      alert(data.selFilExct200kg);
                    } else {
                      // Proceed with file upload if within size limit
                      addLogo(file);
                    }
                  }
                }}
                hidden
                type="file"
                accept=".png, .jpeg, .jpg"
              />
              <Stack alignItems={"center"}>
                <Typography
                  fontWeight={600}
                  variant="body"
                  color={"gray"}
                  align="center"
                >
                  {data.add}
                </Typography>
              </Stack>
            </CardActionArea>

            <Divider />

            <SlideShow
              admin={true}
              data={data}
              getBrands={getBrands}
              images={state.brands}
            />
          </Stack>
        </Box>
      </Box>
    </div>
  );
};

export default PartnersLogo;

import React from "react";
import TitlePanel from "../../common/TitlePanel";
import { GlobalContext } from "../../context/GlobalContext";
import { AccountTreeOutlined } from "@mui/icons-material";
import { Avatar, Box, Stack } from "@mui/material";
import UserList from "./UserList";

const ManageUser = ({ data }) => {
  const { hitAxios } = React.useContext(GlobalContext);
  const [userData, setUserData] = React.useState([]);

  async function getUsers() {
    const res = await hitAxios({
      path: "/api/admin/get_users",
      post: false,
      admin: true,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_admin"),
    });
    if (res.data.success) {
      setUserData(res.data.data);
    }
  }

  React.useEffect(() => {
    getUsers();
  }, []);

  return (
    <div>
      <Stack direction={"column"} spacing={3}>
        <TitlePanel
          icon={
            <Avatar
              sx={{
                width: 50,
                height: 50,
                bgcolor: "action.hover",
              }}
            >
              <AccountTreeOutlined
                sx={{
                  color: (t) => t.palette.primary.main,
                }}
              />
            </Avatar>
          }
          data={data}
          place={data.users}
          title={data.users}
        />

        <UserList
          getUsers={getUsers}
          userData={userData}
          setUserData={setUserData}
          hitAxios={hitAxios}
          data={data}
        />
      </Stack>
    </div>
  );
};

export default ManageUser;

import { Box, Typography } from "@mui/material";
import React from "react";

const DividerTitile = ({ title, variant, fontWeight, color, ...props }) => {
  return (
    <Box width={"100%"} display="flex" alignItems="center">
      <Box
        flex="1"
        borderBottom={1}
        borderColor={color || "#E7E7E7"}
        marginRight={2}
      />
      <Typography {...props} variant={variant} fontWeight={fontWeight || 300}>
        {title}
      </Typography>
      <Box
        flex="1"
        borderBottom={1}
        borderColor={color || "#E7E7E7"}
        marginLeft={2}
      />
    </Box>
  );
};

export default DividerTitile;

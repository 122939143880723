import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { ButtonBase, CardMedia, Stack } from "@mui/material";
import { Login } from "@mui/icons-material";
import { TranslateContext } from "../../context/TranslateContext";
import { GlobalContext } from "../../context/GlobalContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import HeaderMenu from "./HeaderMenu";

function Header() {
  const history = useHistory();
  const { data } = React.useContext(TranslateContext);
  const { hitAxios } = React.useContext(GlobalContext);
  const [web, setWeb] = React.useState({});

  async function getWeb() {
    const res = await hitAxios({
      path: "/api/web/get_web_public",
      post: false,
      admin: false,
      token_user: "",
    });
    if (res.data.success) {
      setWeb(res.data.data);
    }
  }

  React.useEffect(() => {
    getWeb();
  }, []);

  return (
    <AppBar
      position="sticky"
      sx={{
        bgcolor: "action.hover",
        color: "black",
        boxShadow: "0px 0.2px 2px rgba(0, 0, 0, 0.1)", // Custom shadow
        // p: 0.5,
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Stack
            width={"100%"}
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <HeaderMenu data={data} />
              {web?.logo && (
                <ButtonBase onClick={() => history.push("/")}>
                  <Stack alignItems={"center"} direction={"row"} spacing={0}>
                    <CardMedia
                      height={50}
                      component={"img"}
                      sx={{ minWidth: 50 }}
                      src={`/media/${web?.logo}`}
                    />
                    <Typography variant="h3" fontWeight={100}>
                      {web?.app_name}
                    </Typography>
                  </Stack>
                </ButtonBase>
              )}
            </Stack>

            <Stack direction={"row"} spacing={2}>
              {/* <LinkComponent /> */}
              <Button
                onClick={() => history.push("/user")}
                startIcon={<Login />}
                color="inherit"
                sx={{
                  textTransform: "none",
                  padding: "0.4rem 1rem 0.4rem 1rem",
                  borderRadius: 4,
                  fontWeight: 200,
                }}
              >
                {data.dashboard}
              </Button>
            </Stack>
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;

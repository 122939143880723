import { Box } from "@mui/system";
import React from "react";
import ShadowBox from "../common/ShadowBox";
import {
  Button,
  CardMedia,
  Chip,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import {
  AutoFixHigh,
  ShoppingCartCheckout,
  Check,
  Close,
  Star,
  DeleteOutline,
} from "@mui/icons-material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Pricing = ({ data, planData, hitAxios, getPlan, user }) => {
  const history = useHistory();
  async function delPlan(id) {
    if (window.confirm(data.aus)) {
      const res = await hitAxios({
        path: "/api/admin/del_plan",
        post: true,
        admin: true,
        obj: { id },
      });

      if (res.data.success) {
        getPlan();
      }
    }
  }

  return planData?.map((i, key) => {
    return (
      <Grid xs={12} sm={4} lg={4} item>
        <ShadowBox border={2} radius={3} key={key} p={3}>
          <Stack direction={"column"} spacing={2.5}>
            <Typography fontSize={18} fontWeight={400}>
              {i?.name}
            </Typography>

            <CardMedia
              sx={{
                height: 50,
                width: 50,
              }}
              component={"img"}
              src="/assets/login_hero_ai.png"
            />

            <Typography variant={"h1"}>
              {parseInt(i?.price) > 0 ? i?.price : data.free}{" "}
              <a
                style={{
                  fontSize: 12,
                  fontWeight: 450,
                  // color: "gray",
                }}
              >
                {data.for} {i?.validity_days} / {data.days}
              </a>
            </Typography>

            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              {i?.in_app_chat > 0 ? (
                <Check
                  sx={{
                    color: (t) => t.palette.primary.main,
                    height: 18,
                    width: 18,
                  }}
                />
              ) : (
                <Close sx={{ color: "gray", height: 18, width: 18 }} />
              )}
              <Typography
                variant="body1"
                sx={{
                  color: i?.in_app_chat > 0 ? null : "gray",
                }}
              >
                {data.inAppChat}
              </Typography>
            </Stack>

            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              {i?.image_maker > 0 ? (
                <Check
                  sx={{
                    color: (t) => t.palette.primary.main,
                    height: 18,
                    width: 18,
                  }}
                />
              ) : (
                <Close sx={{ color: "gray", height: 18, width: 18 }} />
              )}
              <Typography
                variant="body1"
                sx={{
                  color: i?.image_maker > 0 ? null : "gray",
                }}
              >
                {data.aiImageMaker}
              </Typography>
            </Stack>

            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              {i?.code_writer > 0 ? (
                <Check
                  sx={{
                    color: (t) => t.palette.primary.main,
                    height: 18,
                    width: 18,
                  }}
                />
              ) : (
                <Close sx={{ color: "gray", height: 18, width: 18 }} />
              )}
              <Typography
                variant="body1"
                sx={{
                  color: i?.code_writer > 0 ? null : "gray",
                }}
              >
                {data.codeWriter}
              </Typography>
            </Stack>

            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              {i?.speech_to_text > 0 ? (
                <Check
                  sx={{
                    color: (t) => t.palette.primary.main,
                    height: 18,
                    width: 18,
                  }}
                />
              ) : (
                <Close sx={{ color: "gray", height: 18, width: 18 }} />
              )}
              <Typography
                variant="body1"
                sx={{
                  color: i?.speech_to_text > 0 ? null : "gray",
                }}
              >
                {data.speechToText}
              </Typography>
            </Stack>

            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              {i?.voice_maker > 0 ? (
                <Check
                  sx={{
                    color: (t) => t.palette.primary.main,
                    height: 18,
                    width: 18,
                  }}
                />
              ) : (
                <Close sx={{ color: "gray", height: 18, width: 18 }} />
              )}
              <Typography
                variant="body1"
                sx={{
                  color: i?.voice_maker > 0 ? null : "gray",
                }}
              >
                {data.voiceMaker}
              </Typography>
            </Stack>

            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              {i?.ai_video > 0 ? (
                <Check
                  sx={{
                    color: (t) => t.palette.primary.main,
                    height: 18,
                    width: 18,
                  }}
                />
              ) : (
                <Close sx={{ color: "gray", height: 18, width: 18 }} />
              )}
              <Typography
                variant="body1"
                sx={{
                  color: i?.ai_video > 0 ? null : "gray",
                }}
              >
                {data.aiVideoMaker}
              </Typography>
            </Stack>

            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              {i?.gemini_token > 0 ? (
                <Check
                  sx={{
                    color: (t) => t.palette.primary.main,
                    height: 18,
                    width: 18,
                  }}
                />
              ) : (
                <Close sx={{ color: "gray", height: 18, width: 18 }} />
              )}

              <Typography
                variant="body1"
                sx={{
                  color: i?.gemini_token > 0 ? null : "gray",
                }}
              >
                {data.geminiToken}: {i?.gemini_token}
              </Typography>
            </Stack>

            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              {i?.openai_token > 0 ? (
                <Check
                  sx={{
                    color: (t) => t.palette.primary.main,
                    height: 18,
                    width: 18,
                  }}
                />
              ) : (
                <Close sx={{ color: "gray", height: 18, width: 18 }} />
              )}

              <Typography
                variant="body1"
                sx={{
                  color: i?.openai_token > 0 ? null : "gray",
                }}
              >
                {data.geminiToken}: {i?.openai_token}
              </Typography>
            </Stack>

            {user && (
              <Button
                onClick={() => history.push(`/user/checkout?product=${i?.id}`)}
                variant="contained"
                startIcon={<ShoppingCartCheckout />}
                color="info"
              >
                {data.getStarted}
              </Button>
            )}

            {!user && (
              <Button
                onClick={() => delPlan(i?.id)}
                variant="contained"
                startIcon={<DeleteOutline />}
                color="error"
              >
                {data.del}
              </Button>
            )}
          </Stack>
        </ShadowBox>
      </Grid>
    );
  });
};

export default Pricing;

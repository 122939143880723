import { Box, CardMedia, ImageList } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { GlobalContext } from "../context/GlobalContext";

const SlideShow = ({ images, admin, getBrands, data }) => {
  const { hitAxios } = React.useContext(GlobalContext);

  async function delImg(id) {
    if (window.confirm(data.AUS)) {
      const res = await hitAxios({
        path: "/api/admin/del_brand_logo",
        post: true,
        admin: true,
        obj: { id },
      });
      if (res.data.success) {
        getBrands();
      }
    }
  }

  return (
    <div>
      <ImageList
        sx={{
          gridAutoFlow: "column",
          gridTemplateColumns: "repeat(auto-fill,minmax(160px,1fr)) !important",
          gridAutoColumns: "minmax(160px, 1fr)",
          msOverflowStyle: "none",
          overflowX: "scroll",
          "::-webkit-scrollbar": {
            width: "10px",
          },
          // '::-webkit-scrollbar-track': {
          //     borderRadius: 10
          // },
          // '::-webkit-scrollbar-thumb': {
          //     borderRadius: 10
          // },
          // '::-webkit-scrollbar-thumb:hover': {
          //     background: 'green',
          // }
        }}
      >
        <Stack direction={"row"} spacing={6}>
          {images?.map((i, key) => {
            return admin ? (
              <Box p={1} borderRadius={2} bgcolor={admin && "action.hover"}>
                <CardMedia
                  onClick={() => {
                    delImg(i?.id);
                  }}
                  sx={{
                    height: "100%",
                    width: 220,
                    cursor: admin && "pointer",
                  }}
                  component={"img"}
                  key={key}
                  src={`/media/${i?.filename}`}
                />
              </Box>
            ) : (
              <Box p={1} borderRadius={2} bgcolor={admin && "action.hover"}>
                <CardMedia
                  sx={{
                    height: "100%",
                    width: 220,
                    cursor: admin && "pointer",
                  }}
                  component={"img"}
                  key={key}
                  src={`/media/${i?.filename}`}
                />
              </Box>
            );
          })}
        </Stack>
      </ImageList>
    </div>
  );
};

export default SlideShow;

import {
  Avatar,
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  Divider,
} from "@mui/material";
import React from "react";
import TitlePanel from "../../common/TitlePanel";
import {
  AccountTreeOutlined,
  Check,
  CheckCircle,
  Close,
  PaidOutlined,
  SaveOutlined,
  Star,
} from "@mui/icons-material";
import { GlobalContext } from "../../context/GlobalContext";
import { useHistory } from "react-router-dom";
import TextFieldNew from "../../common/TextFieldNew";
import SliderComp from "../../common/SliderComp";
import UserToken from "../../common/UserToken";

const Profile = ({ data }) => {
  const { hitAxios } = React.useContext(GlobalContext);
  const CON = React.useContext(GlobalContext);
  const [profile, setProfile] = React.useState({});
  const history = useHistory();
  const [state, setState] = React.useState({});

  async function updateProfile() {
    const res = await hitAxios({
      path: "/api/user/update_profile",
      post: true,
      admin: false,
      obj: profile,
    });
  }

  async function getUser() {
    const res = await hitAxios({
      path: "/api/user/get_me",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      setProfile(res.data.data);
    }
  }

  React.useEffect(() => {
    getUser();
  }, []);

  return (
    <div>
      <TitlePanel
        icon={
          <Avatar
            sx={{
              width: 50,
              height: 50,
              bgcolor: "action.hover",
            }}
          >
            <AccountTreeOutlined
              sx={{
                color: (t) => t.palette.primary.main,
              }}
            />
          </Avatar>
        }
        data={data}
        place={data.profile}
        title={data.profile}
      />

      <Box mt={3}>
        <Stack direction={"column"} spacing={2}>
          <TextFieldNew
            onChange={(e) => setProfile({ ...profile, name: e.target.value })}
            shrink
            label={data.name}
            fullWidth
            value={profile.name}
          />

          <TextFieldNew
            onChange={(e) => setProfile({ ...profile, email: e.target.value })}
            shrink
            label={data.email}
            fullWidth
            value={profile.email}
          />

          <TextFieldNew
            startIcon={<Typography>+</Typography>}
            onChange={(e) => setProfile({ ...profile, mobile: e.target.value })}
            shrink
            label={data.mobile}
            fullWidth
            value={profile.mobile}
          />

          <TextFieldNew
            onChange={(e) =>
              setProfile({ ...profile, newPassword: e.target.value })
            }
            label={data.password}
            helperText={data.ignorePass}
            fullWidth
            value={profile.newPassword}
          />

          <Button
            onClick={updateProfile}
            startIcon={<SaveOutlined />}
            sx={{ borderRadius: 2, boxShadow: 0 }}
            variant="contained"
          >
            {data.submit}
          </Button>

          <Divider />

          <Stack
            direction={"row"}
            spacing={2}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <PaidOutlined />
              <Typography variant="h3">{data.myPlan}</Typography>
            </Stack>

            <UserToken />
          </Stack>

          {profile?.plan ? (
            <Box pt={3}>
              <SliderComp direction="row" spacing={2}>
                <Stack
                  direction={"row"}
                  spacing={1}
                  sx={{
                    borderRadius: 2,
                    p: 1,
                    bgcolor: "action.hover",
                  }}
                  alignItems={"center"}
                >
                  {JSON.parse(profile?.plan)?.in_app_chat > 0 ? (
                    <CheckCircle
                      sx={{
                        color: (t) => t.palette.primary.main,
                        height: 18,
                        width: 18,
                      }}
                    />
                  ) : (
                    <Close sx={{ color: "gray", height: 18, width: 18 }} />
                  )}
                  <Typography
                    variant="caption"
                    sx={{
                      color:
                        JSON.parse(profile?.plan)?.in_app_chat > 0
                          ? null
                          : "gray",
                    }}
                  >
                    {data.inAppChat}
                  </Typography>
                </Stack>

                <Stack
                  direction={"row"}
                  spacing={1}
                  sx={{
                    borderRadius: 2,
                    p: 1,
                    bgcolor: "action.hover",
                  }}
                  alignItems={"center"}
                >
                  {JSON.parse(profile?.plan)?.image_maker > 0 ? (
                    <CheckCircle
                      sx={{
                        color: (t) => t.palette.primary.main,
                        height: 18,
                        width: 18,
                      }}
                    />
                  ) : (
                    <Close sx={{ color: "gray", height: 18, width: 18 }} />
                  )}
                  <Typography
                    variant="caption"
                    sx={{
                      color:
                        JSON.parse(profile?.plan)?.image_maker > 0
                          ? null
                          : "gray",
                    }}
                  >
                    {data.aiImageMaker}
                  </Typography>
                </Stack>

                <Stack
                  direction={"row"}
                  spacing={1}
                  sx={{
                    borderRadius: 2,
                    p: 1,
                    bgcolor: "action.hover",
                  }}
                  alignItems={"center"}
                >
                  {JSON.parse(profile?.plan)?.code_writer > 0 ? (
                    <CheckCircle
                      sx={{
                        color: (t) => t.palette.primary.main,
                        height: 18,
                        width: 18,
                      }}
                    />
                  ) : (
                    <Close sx={{ color: "gray", height: 18, width: 18 }} />
                  )}
                  <Typography
                    variant="caption"
                    sx={{
                      color:
                        JSON.parse(profile?.plan)?.code_writer > 0
                          ? null
                          : "gray",
                    }}
                  >
                    {data.codeWriter}
                  </Typography>
                </Stack>

                <Stack
                  direction={"row"}
                  spacing={1}
                  sx={{
                    borderRadius: 2,
                    p: 1,
                    bgcolor: "action.hover",
                  }}
                  alignItems={"center"}
                >
                  {JSON.parse(profile?.plan)?.speech_to_text > 0 ? (
                    <CheckCircle
                      sx={{
                        color: (t) => t.palette.primary.main,
                        height: 18,
                        width: 18,
                      }}
                    />
                  ) : (
                    <Close sx={{ color: "gray", height: 18, width: 18 }} />
                  )}
                  <Typography
                    variant="caption"
                    sx={{
                      color:
                        JSON.parse(profile?.plan)?.speech_to_text > 0
                          ? null
                          : "gray",
                    }}
                  >
                    {data.speechToText}
                  </Typography>
                </Stack>

                <Stack
                  direction={"row"}
                  spacing={1}
                  sx={{
                    borderRadius: 2,
                    p: 1,
                    bgcolor: "action.hover",
                  }}
                  alignItems={"center"}
                >
                  {JSON.parse(profile?.plan)?.voice_maker > 0 ? (
                    <CheckCircle
                      sx={{
                        color: (t) => t.palette.primary.main,
                        height: 18,
                        width: 18,
                      }}
                    />
                  ) : (
                    <Close sx={{ color: "gray", height: 18, width: 18 }} />
                  )}
                  <Typography
                    variant="caption"
                    sx={{
                      color:
                        JSON.parse(profile?.plan)?.voice_maker > 0
                          ? null
                          : "gray",
                    }}
                  >
                    {data.voiceMaker}
                  </Typography>
                </Stack>

                <Stack
                  direction={"row"}
                  spacing={1}
                  sx={{
                    borderRadius: 2,
                    p: 1,
                    bgcolor: "action.hover",
                  }}
                  alignItems={"center"}
                >
                  {JSON.parse(profile?.plan)?.ai_video > 0 ? (
                    <CheckCircle
                      sx={{
                        color: (t) => t.palette.primary.main,
                        height: 18,
                        width: 18,
                      }}
                    />
                  ) : (
                    <Close sx={{ color: "gray", height: 18, width: 18 }} />
                  )}
                  <Typography
                    variant="caption"
                    sx={{
                      color:
                        JSON.parse(profile?.plan)?.ai_video > 0 ? null : "gray",
                    }}
                  >
                    {data.aiVideoMaker}
                  </Typography>
                </Stack>

                <Stack
                  direction={"row"}
                  spacing={1}
                  sx={{
                    borderRadius: 2,
                    p: 1,
                    bgcolor: "action.hover",
                  }}
                  alignItems={"center"}
                >
                  {JSON.parse(profile?.plan)?.gemini_token > 0 ? (
                    <CheckCircle
                      sx={{
                        color: (t) => t.palette.primary.main,
                        height: 18,
                        width: 18,
                      }}
                    />
                  ) : (
                    <Close sx={{ color: "gray", height: 18, width: 18 }} />
                  )}

                  <Typography
                    variant="caption"
                    sx={{
                      color:
                        JSON.parse(profile?.plan)?.gemini_token > 0
                          ? null
                          : "gray",
                    }}
                  >
                    {data.geminiToken}:{" "}
                    {JSON.parse(profile?.plan)?.gemini_token}
                  </Typography>
                </Stack>

                <Stack
                  direction={"row"}
                  spacing={1}
                  sx={{
                    borderRadius: 2,
                    p: 1,
                    bgcolor: "action.hover",
                  }}
                  alignItems={"center"}
                >
                  {JSON.parse(profile?.plan)?.openai_token > 0 ? (
                    <CheckCircle
                      sx={{
                        color: (t) => t.palette.primary.main,
                        height: 18,
                        width: 18,
                      }}
                    />
                  ) : (
                    <Close sx={{ color: "gray", height: 18, width: 18 }} />
                  )}

                  <Typography
                    variant="caption"
                    sx={{
                      color:
                        JSON.parse(profile?.plan)?.openai_token > 0
                          ? null
                          : "gray",
                    }}
                  >
                    {data.geminiToken}:{" "}
                    {JSON.parse(profile?.plan)?.openai_token}
                  </Typography>
                </Stack>
              </SliderComp>
            </Box>
          ) : (
            <Typography color={"orange"} variant="caption">
              {data.noPlanFound}
            </Typography>
          )}
        </Stack>
      </Box>
    </div>
  );
};

export default Profile;

import {
  ArrowCircleLeft,
  ArrowCircleRight,
  ColorLens,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import DragUpload from "../../../common/DragUpload";
import ButtonAnime from "../../../common/ButtonAnime";
import TextFieldNew from "../../../common/TextFieldNew";
import SelectChooseColor from "./SelectChooseColor";

const ChoseColor = ({ data, state, setState }) => {
  return (
    <Stack
      alignItems={"center"}
      justifyContent={"center"}
      spacing={4}
      direction={"column"}
      minHeight={300}
    >
      <Stack spacing={1} direction={"row"}>
        <ColorLens />
        <Typography variant="h4">{data.chooseCaptionColor}</Typography>
      </Stack>

      <Stack direction={"row"} spacing={2}>
        <Box bgcolor={"white"} p={1.5} borderRadius={2}>
          <Stack alignItems={"center"} direction={"row"} spacing={2}>
            <Typography>{data.primary}</Typography>
            <SelectChooseColor
              data={data}
              stateKey={"primaryColor"}
              state={state}
              setState={setState}
            />
          </Stack>
        </Box>

        <Box bgcolor={"white"} p={1.5} borderRadius={2}>
          <Stack alignItems={"center"} direction={"row"} spacing={2}>
            <Typography>{data.secondary}</Typography>
            <SelectChooseColor
              data={data}
              stateKey={"secondaryColor"}
              state={state}
              setState={setState}
            />
          </Stack>
        </Box>
      </Stack>

      <Stack direction={"row"} spacing={2}>
        <Box bgcolor={"white"} p={1.5} borderRadius={2}>
          <Stack alignItems={"center"} direction={"row"} spacing={2}>
            <Typography>{data.outline}</Typography>
            <SelectChooseColor
              data={data}
              stateKey={"outlineColor"}
              state={state}
              setState={setState}
            />
          </Stack>
        </Box>

        <Box bgcolor={"white"} p={1.5} borderRadius={2}>
          <Stack alignItems={"center"} direction={"row"} spacing={2}>
            <Typography>{data.background}</Typography>
            <SelectChooseColor
              data={data}
              stateKey={"backgroundColor"}
              state={state}
              setState={setState}
            />
          </Stack>
        </Box>
      </Stack>

      <Stack direction={"row"} spacing={4}>
        <Tooltip title={data.back}>
          <IconButton
            onClick={() => setState({ ...state, step: "chooseVideo" })}
            color="success"
          >
            <ArrowCircleLeft sx={{ height: 50, width: 50 }} />
          </IconButton>
        </Tooltip>

        <Tooltip title={data.next}>
          <IconButton
            onClick={() => setState({ ...state, step: "chooseStyle" })}
            color="success"
          >
            <ArrowCircleRight sx={{ height: 50, width: 50 }} />
          </IconButton>
        </Tooltip>
      </Stack>
    </Stack>
  );
};

export default ChoseColor;

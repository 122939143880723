import { Box, Button, Icon, IconButton, Stack, Tooltip } from "@mui/material";
import React from "react";
import { AutoAwesomeOutlined, ManageSearch } from "@mui/icons-material";

const TopBar = ({ data, state, setState, chat }) => {
  const [pad, setPad] = React.useState(0.5);
  return (
    <div onMouseLeave={() => setPad(0.5)} onMouseEnter={() => setPad(1.5)}>
      <Box
        borderRadius={2}
        // p={0.5}
        sx={
          {
            // bgcolor: (t) => t.palette?.primary?.light,
          }
        }
      >
        <Stack direction={"row"} spacing={2} justifyContent={"space-between"}>
          <Button
            color={chat?.selectedModel ? "primary" : "inherit"}
            onClick={() => setState({ ...state, selectModelDialog: true })}
            startIcon={<AutoAwesomeOutlined />}
            size="small"
            // sx={{ fontWeight: "bold" }}
          >
            {chat?.selectedModel
              ? chat?.selectedModel?.name
              : data.startNewChat}
          </Button>

          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Tooltip title={data.chatHistory}>
              <IconButton
                onClick={() => setState({ ...state, historyDialog: true })}
                color="inherit"
              >
                <ManageSearch />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      </Box>
    </div>
  );
};

export default TopBar;

import {
  ArrowCircleLeft,
  ArrowCircleRight,
  FormatPaint,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import DragUpload from "../../../common/DragUpload";
import ButtonAnime from "../../../common/ButtonAnime";
import TextFieldNew from "../../../common/TextFieldNew";
import SelectChooseColor from "./SelectChooseColor";
import SliderComp from "../../../common/SliderComp";
import { GlobalContext } from "../../../context/GlobalContext";

const effectArr = [
  {
    name: "Blur",
    code: "{\\blur5\\3c&HFFC000}",
  },
  {
    name: "Blur T",
    code: "{\\blur5\\3c&HFFC000&\\t(\\3c&HFF00C5&)}",
  },
  {
    name: "One C",
    code: "{\\1c&HFF0000&\\t(\\1c&H0000FF&)}",
  },
  {
    name: "Fscy",
    code: "{\\fscx0\\fscy0\\t(0,100,\\fscx100\\fscy100)}",
  },
  {
    name: "Clip",
    code: "{\\clip()\\b1\\fs50.5\\move(899,300,899,339,28,9245)\\frz4.3\\fax0.175\\c&H6054FF&\\blur1.6}",
  },
  {
    name: "Move",
    code: "{\\move(960,1080,960,540)}",
  },
  {
    name: "Tf",
    code: "{\\t(0,500,1,\\frx360)}",
  },
  {
    name: "4Ch",
    code: "{\\4c&H000000&}",
  },
];

const captionPosition = ["top", "middle", "bottom"];

const fontFam = [
  "Arial",
  "Verdana",
  "Tahoma",
  "Trebuchet MS",
  "Times New Roman",
  "Georgia",
  "Garamond",
  "Courier New",
  "Brush Script MT",
];

const captionType = ["word", "segment"];

const effectType = ["zoom", "fade"];

const ChooseStyle = ({ data, state, setState }) => {
  return (
    <Stack
      alignItems={"center"}
      justifyContent={"center"}
      spacing={4}
      direction={"column"}
      minHeight={300}
    >
      <Stack spacing={1} direction={"row"}>
        <FormatPaint />
        <Typography variant="h4">{data.chooseCaptionStyle}</Typography>
      </Stack>

      {/* captionType  */}
      <Box bgcolor={"white"} p={2} borderRadius={3}>
        <Stack alignItems={"center"} direction={"column"} spacing={2}>
          <Typography color={"gray"} variant="caption">
            {data.captionType}
          </Typography>
          <SliderComp spacing={1}>
            {captionType.map((i, key) => {
              return (
                <Chip
                  size="small"
                  onClick={() => setState({ ...state, captionType: i })}
                  variant={state.captionType === i ? "filled" : "outlined"}
                  key={key}
                  sx={{ p: 1 }}
                  label={<Typography variant="caption">{i}</Typography>}
                />
              );
            })}
          </SliderComp>
        </Stack>
      </Box>

      {/* captionType  */}
      <Box bgcolor={"white"} p={2} borderRadius={3}>
        <Stack alignItems={"center"} direction={"column"} spacing={2}>
          <Typography color={"gray"} variant="caption">
            {data.effectType}
          </Typography>
          <SliderComp spacing={1}>
            {effectType.map((i, key) => {
              return (
                <Chip
                  size="small"
                  onClick={() => setState({ ...state, transitionEffect: i })}
                  variant={state.transitionEffect === i ? "filled" : "outlined"}
                  key={key}
                  sx={{ p: 1 }}
                  label={<Typography variant="caption">{i}</Typography>}
                />
              );
            })}
          </SliderComp>
        </Stack>
      </Box>

      {/* position  */}
      <Box bgcolor={"white"} p={2} borderRadius={3}>
        <Stack alignItems={"center"} direction={"column"} spacing={2}>
          <Typography variant="caption" color={"gray"}>
            {data.captionPOsition}
          </Typography>
          <SliderComp direction={"row"} spacing={2} alignItems={"center"}>
            {captionPosition.map((i, key) => {
              return (
                <Chip
                  size="small"
                  onClick={() => setState({ ...state, captionPosition: i })}
                  variant={state.captionPosition === i ? "filled" : "outlined"}
                  sx={{ p: 1 }}
                  key={key}
                  label={<Typography variant="caption">{i}</Typography>}
                />
              );
            })}
          </SliderComp>
        </Stack>
      </Box>

      {/* effects  */}
      <Box bgcolor={"white"} p={2} borderRadius={3}>
        <Stack alignItems={"center"} direction={"column"} spacing={2}>
          <Typography variant="caption" color={"gray"}>
            {data.chooseEffect}
          </Typography>
          <SliderComp direction={"row"} spacing={2} alignItems={"center"}>
            {effectArr.map((i, key) => {
              return (
                <Chip
                  size="small"
                  onClick={() => setState({ ...state, fontEffect: i.name })}
                  variant={state.fontEffect === i.name ? "filled" : "outlined"}
                  sx={{ p: 1 }}
                  key={key}
                  label={<Typography variant="caption">{i?.name}</Typography>}
                />
              );
            })}
          </SliderComp>
        </Stack>
      </Box>

      {/* cfont style  */}
      <Box bgcolor={"white"} p={2} borderRadius={3}>
        <Stack alignItems={"center"} direction={"column"} spacing={2}>
          <Typography color={"gray"} variant="caption">
            {data.fontStyle}
          </Typography>

          <SliderComp spacing={1}>
            {fontFam.map((i, key) => {
              return (
                <Chip
                  size="small"
                  onClick={() => setState({ ...state, fontFamily: i })}
                  variant={state.fontFamily === i ? "filled" : "outlined"}
                  key={key}
                  sx={{ p: 1 }}
                  label={
                    <Typography variant="caption" fontFamily={i}>
                      {i}
                    </Typography>
                  }
                />
              );
            })}
          </SliderComp>
        </Stack>
      </Box>

      <Stack direction={"row"} spacing={4}>
        <Tooltip title={data.back}>
          <IconButton
            onClick={() => setState({ ...state, step: "chooseColor" })}
            color="success"
          >
            <ArrowCircleLeft sx={{ height: 50, width: 50 }} />
          </IconButton>
        </Tooltip>

        <Tooltip title={data.next}>
          <IconButton
            onClick={() => setState({ ...state, step: "chooseOther" })}
            color="success"
          >
            <ArrowCircleRight sx={{ height: 50, width: 50 }} />
          </IconButton>
        </Tooltip>
      </Stack>
    </Stack>
  );
};

export default ChooseStyle;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { ExpandMore } from "@mui/icons-material";

const Faq = ({ data }) => {
  const [faq, setFaq] = React.useState([]);
  const { hitAxios } = React.useContext(GlobalContext);

  async function getFaq() {
    const res = await hitAxios({
      path: "/api/admin/get_faq",
      post: false,
      admin: false,
    });
    if (res.data.success) {
      setFaq(res.data.data);
    }
  }

  React.useEffect(() => {
    getFaq();
  }, []);

  return (
    <div>
      <Container maxWidth="lg">
        <Box mb={6} mt={4}>
          <Stack direction={"column"} spacing={3}>
            <Typography align="center" variant="h3" fontWeight={600}>
              {data.faqq}
            </Typography>

            {faq?.map((i, key) => {
              return (
                <Accordion
                  key={key}
                  disableGutters
                  elevation={0}
                  sx={{
                    "&:before": {
                      display: "none",
                    },
                  }}
                >
                  <AccordionSummary
                    sx={{
                      bgcolor: "action.hover",
                      borderRadius: 3,
                      padding: "5px 20px 5px 20px",
                    }}
                    expandIcon={<ExpandMore />}
                    id={key}
                  >
                    <Typography variant="h6" fontWeight={600}>
                      {i?.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{i?.answer}</Typography>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Stack>
        </Box>
      </Container>
    </div>
  );
};

export default Faq;

import {
  Avatar,
  Box,
  CardMedia,
  Container,
  IconButton,
  Stack,
  Typography,
  Zoom,
} from "@mui/material";
import React from "react";
import { GlobalContext } from "../../context/GlobalContext";

const Testimonial = ({ data }) => {
  const { hitAxios } = React.useContext(GlobalContext);
  const [testi, setTesti] = React.useState([]);
  const [show, setShow] = React.useState({});

  async function getTesti() {
    const res = await hitAxios({
      path: "/api/admin/get_testi",
      post: false,
      admin: false,
    });
    if (res.data.success) {
      setTesti(res.data.data);
      if (res.data?.data?.length > 0 && Array?.isArray(res.data?.data)) {
        setShow(res.data?.data[0]);
      }
    }
  }

  React.useEffect(() => {
    getTesti();
  }, []);

  return (
    <Box mt={8} bgcolor={"action.hover"} pt={4} mb={6}>
      <Container maxWidth="lg">
        <Container maxWidth="sm">
          <Typography align="center" variant="h3" fontWeight={600}>
            {data.testi}
          </Typography>
          <Stack direction={"row"} justifyContent={"flex-end"}>
            <CardMedia
              sx={{ height: "100%", width: 100 }}
              component={"img"}
              src="/assets/arrow.png"
            />
          </Stack>
        </Container>

        <Box pb={4} mt={2}>
          <Stack direction={"column"} spacing={2}>
            <Zoom in={show?.id}>
              <Stack direction={"column"} spacing={2}>
                <Typography variant="h6">{show?.title}</Typography>

                <Box bgcolor={"white"} p={3}>
                  <Typography>{show?.description}</Typography>
                </Box>
              </Stack>
            </Zoom>

            <Stack direction={"row"} spacing={1}>
              {testi?.map((i, key) => {
                return (
                  <IconButton
                    onClick={() => {
                      setShow({});
                      setTimeout(() => {
                        setShow(i);
                      }, 200);
                    }}
                    key={key}
                  >
                    <Avatar
                      sx={{
                        height: 30,
                        width: 30,
                        bgcolor: show?.id === i?.id && data.color_theme,
                      }}
                    >
                      <Typography variant="body2">{key + 1}</Typography>
                    </Avatar>
                  </IconButton>
                );
              })}
            </Stack>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default Testimonial;

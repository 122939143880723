import React, { useState, useEffect } from "react";
import { Typography, Grow, Box, Stack } from "@mui/material";

const DesComp = ({ data }) => {
  const [show, setShow] = React.useState(true);
  const arr = [
    data.aiChat,
    data.aiImage,
    data.speechToText,
    data.aiVoiceMaker,
    data.aiVideoMaker,
    data.allinOneAi,
  ];
  const [activeIndex, setActiveIndex] = useState(-1);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 7500);
    let isMounted = true;

    const showNext = (index) => {
      if (index < arr.length && isMounted) {
        setActiveIndex(index);
        setTimeout(() => {
          if (isMounted && index < arr.length - 1) {
            setActiveIndex(-1); // Temporarily hide the current word
          }
          setTimeout(() => showNext(index + 1), 500); // Short delay before showing the next word
        }, 1000); // Duration each word stays visible
      }
    };

    showNext(0);

    return () => {
      isMounted = false; // Cleanup to prevent state updates after unmounting
    };
  }, []);

  return (
    <Box position="relative" height={25}>
      {arr.map((text, index) => (
        <Grow
          in={index === activeIndex}
          key={index}
          timeout={{ enter: 500, exit: 500 }} // Transition timings
        >
          <Typography
            // color={"gray"}
            variant="h5"
            fontWeight={200}
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              textAlign: "center",
              background:
                show && "linear-gradient(90deg, #f25470 0%, #5f88fe 100%)",
              WebkitBackgroundClip: show && "text",
              WebkitTextFillColor: show && "transparent",
              fontWeight: show && "bold",
            }}
          >
            {text}
          </Typography>
        </Grow>
      ))}
    </Box>
  );
};

export default DesComp;

import { CreditCard } from "@mui/icons-material";
import { CardActionArea, Stack, Typography } from "@mui/material";
import React from "react";
import { GlobalContext } from "../../context/GlobalContext";
import PaystackPop from "@paystack/inline-js";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const PayStack = ({ state, setState, data, plan, keys, web, user }) => {
  const { hitAxios } = React.useContext(GlobalContext);
  const history = useHistory();

  function convertCurrency(amount, exchangeRate) {
    if (typeof amount !== "number" || typeof exchangeRate !== "number") {
      throw new Error("Amount and exchange rate must be numbers");
    }
    return (amount * exchangeRate).toFixed(2);
  }

  function payWithPaystack() {
    const paystack = new PaystackPop();
    paystack.newTransaction({
      key: keys?.pay_paystack_id,
      amount: convertCurrency(
        parseInt(plan.price),
        parseInt(web.exchange_rate || 1)
      ),
      email: user?.email,
      firstname: user?.name || "",
      lastname: "",
      async onSuccess(a) {
        var res = await hitAxios({
          path: "/api/user/pay_with_paystack",
          obj: {
            planData: plan,
            trans_id: a.trans,
            reference: a?.reference,
          },
          admin: false,
          post: true,
        });

        if (res.data.success) {
          setTimeout(() => {
            history.push("/user");
          }, 2000);
        }
      },
      onCancel() {
        alert("Payment was canceled ");
      },
    });
  }

  return (
    <div>
      <CardActionArea
        onClick={payWithPaystack}
        disabled={state.tc && state.pp ? false : true}
        sx={{
          padding: 2,
          borderRadius: 3,
          bgcolor: "#113072",
          color: "white",
          pr: 2.5,
          pl: 2.5,
        }}
      >
        <Stack direction={"row"} spacing={2}>
          <CreditCard />
          <Typography fontWeight={600}>{data.payStack}</Typography>
        </Stack>
      </CardActionArea>
    </div>
  );
};

export default PayStack;

import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { GlobalContext } from "../context/GlobalContext";
import { TranslateContext } from "../context/TranslateContext";

const UserToken = ({ removeG, removeO }) => {
  const [user, setUser] = React.useState({});
  const { data } = React.useContext(TranslateContext);
  const { hitAxios } = React.useContext(GlobalContext);

  async function handleGetUser(params) {
    const res = await hitAxios({
      path: "/api/user/get_me",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      setUser(res.data?.data);
    }
  }

  React.useEffect(() => {
    handleGetUser();
  }, []);
  return (
    <Stack alignItems={"center"} direction={"row"} spacing={2}>
      {!removeG && (
        <Box
          bgcolor={"#eaecff"}
          sx={{
            padding: "5px 10px 5px 10px",
          }}
          borderRadius={3}
        >
          <Typography variant="caption" fontWeight={450} color={"#2933a6"}>
            {data.gemAi} {user?.gemini_token || "🤔"}
          </Typography>
        </Box>
      )}
      {!removeO && (
        <Box
          sx={{
            padding: "5px 10px 5px 10px",
          }}
          bgcolor={"#ecfff7"}
          p={0.8}
          borderRadius={3}
        >
          <Typography variant="caption" fontWeight={450} color={"#1b7c31"}>
            {data.openai} {user?.openai_token || "🤔"}
          </Typography>
        </Box>
      )}
    </Stack>
  );
};

export default UserToken;

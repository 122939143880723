import {
  Avatar,
  Box,
  Button,
  Chip,
  Grow,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import TitlePanel from "../../common/TitlePanel";
import {
  AccountTreeOutlined,
  Comment,
  DeleteOutline,
  Downloading,
  GraphicEq,
  Help,
  HelpOutline,
  UploadFile,
} from "@mui/icons-material";
import ButtonGrid from "../../common/ButtonGrid";
import ShadowBox from "../../common/ShadowBox";
import { GlobalContext } from "../../context/GlobalContext";
import moment from "moment";
import UserToken from "../../common/UserToken";

const SpeechToText = ({ data }) => {
  const [speech, setSpeech] = React.useState([]);
  const { hitAxios } = React.useContext(GlobalContext);
  const [file, setFile] = React.useState(null);
  const [state, setState] = React.useState({
    genType: "text",
    filename: "",
  });

  async function uploadMedia(uploadedFile) {
    setFile(uploadedFile);
    const fd = new FormData();
    fd.append("file", uploadedFile);
    const res = await hitAxios({
      path: "/api/web/return_url",
      post: true,
      admin: false,
      obj: fd,
    });
    if (res.data.success) {
      setState({ ...state, filename: res.data?.filename });
    }
  }

  async function genSpeech() {
    const res = await hitAxios({
      path: "/api/user/generate_speech",
      post: true,
      admin: false,
      obj: state,
    });
    if (res.data.success) {
      getSpeech();
    }
  }

  async function getSpeech() {
    const res = await hitAxios({
      path: "/api/user/get_speech",
      posr: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      setSpeech(res.data.data);
    }
  }

  async function delSpeech(id) {
    if (window.confirm(data.aus)) {
      const res = await hitAxios({
        path: "/api/user/del_speech",
        post: true,
        admin: false,
        obj: { id },
      });
      if (res.data.success) {
        getSpeech();
      }
    }
  }

  function downloadTextAsFile(content, filename = `speech_${Date.now()}.txt`) {
    // Check if content is JSON and pretty print it if it is
    let text = content;
    try {
      const json = JSON.parse(content);
      text = JSON.stringify(json, null, 2); // Pretty print JSON with 2 spaces indentation
    } catch (e) {
      // content is not a valid JSON, use it as is
    }

    // Create a Blob from the text
    const blob = new Blob([text], { type: "text/plain" });

    // Create an anchor element
    const a = document.createElement("a");

    // Create a URL for the Blob and set it as the href attribute of the anchor
    const url = URL.createObjectURL(blob);
    a.href = url;

    // Set the download attribute of the anchor to specify the filename
    a.download = filename;

    // Append the anchor to the body (this is required for some browsers)
    document.body.appendChild(a);

    // Programmatically click the anchor to trigger the download
    a.click();

    // Remove the anchor from the document
    document.body.removeChild(a);

    // Revoke the object URL to free up memory
    URL.revokeObjectURL(url);
  }

  React.useEffect(() => {
    getSpeech();
  }, []);

  return (
    <div>
      <Stack direction={"column"} spacing={3}>
        <TitlePanel
          icon={
            <Avatar
              sx={{
                width: 50,
                height: 50,
                bgcolor: "action.hover",
              }}
            >
              <AccountTreeOutlined
                sx={{
                  color: (t) => t.palette.primary.main,
                }}
              />
            </Avatar>
          }
          data={data}
          place={data.speechToText}
          title={data.speechToText}
        />

        <UserToken />

        <ShadowBox p={3} borderRadius={2}>
          <Stack direction={"column"} spacing={2}>
            <Stack
              direction={"row"}
              spacing={2}
              justifyContent={"space-between"}
            >
              <Stack alignItems={"center"} direction={"row"} spacing={2}>
                <Chip
                  onClick={() => setState({ ...state, genType: "text" })}
                  variant={state.genType === "text" ? "filled" : "outlined"}
                  icon={
                    <Tooltip title={data.textInfo}>
                      <Help sx={{ height: 18, width: 18 }} />
                    </Tooltip>
                  }
                  label={data.text}
                />
                <Chip
                  onClick={() => setState({ ...state, genType: "word" })}
                  variant={state.genType === "word" ? "filled" : "outlined"}
                  icon={
                    <Tooltip title={data.wordsInfo}>
                      <Help sx={{ height: 18, width: 18 }} />
                    </Tooltip>
                  }
                  label={data.words}
                />

                <Tooltip title={data.uploadAudio}>
                  <IconButton
                    component="label"
                    color={state.filename ? "success" : "info"}
                  >
                    <input
                      accept={".m4a, .mp3, .webm, .mp4, .mpga, .wav, .mpeg"}
                      type="file"
                      hidden
                      onChange={(e) => uploadMedia(e.target.files[0])}
                    />
                    <UploadFile />
                  </IconButton>
                </Tooltip>
              </Stack>

              <ButtonGrid
                onClick={genSpeech}
                disabled={state.filename ? false : true}
                startIcon={<GraphicEq />}
                title={data.genSpeech}
              />
            </Stack>
          </Stack>
        </ShadowBox>

        <Stack direction={"column"} spacing={2}>
          {speech?.map((i, key) => {
            return (
              <Grow in timeout={1000}>
                <Box key={key} borderRadius={2} p={2} bgcolor={"action.hover"}>
                  <Stack
                    direction={"row"}
                    spacing={2}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Stack direction={"row"} spacing={2}>
                      <Comment sx={{ height: 40, width: 40 }} />
                      <Stack direction={"column"}>
                        <Typography>
                          {moment(i?.createdAt).fromNow()}
                        </Typography>
                        <Typography color={"gray"} variant="caption">
                          {i?.type}
                        </Typography>
                      </Stack>
                    </Stack>

                    <Stack direction={"row"}>
                      <IconButton
                        color="error"
                        onClick={() => delSpeech(i?.id)}
                      >
                        <DeleteOutline />
                      </IconButton>

                      <IconButton onClick={() => downloadTextAsFile(i?.output)}>
                        <Downloading />
                      </IconButton>
                    </Stack>
                  </Stack>
                </Box>
              </Grow>
            );
          })}
        </Stack>
      </Stack>
    </div>
  );
};

export default SpeechToText;

import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import React from "react";
import TextFieldNew from "../../../common/TextFieldNew";
import { SendOutlined, SettingsSuggest } from "@mui/icons-material";
import { GlobalContext } from "../../../context/GlobalContext";
import ButtonAnime from "../../../common/ButtonAnime";

const FooterAction = ({
  data,
  chat,
  setTypeMsg,
  typeMsg,
  hitAxios,
  setMessages,
  messages,
}) => {
  const [que, setQue] = React.useState("");
  const CON = React.useContext(GlobalContext);

  async function sendMsg(chatId, modelId, question) {
    setQue("");
    const newMsg = {
      msgType: "text",
      sender: "user",
      modelId: modelId,
      text: question,
      msgObj: {
        text: question,
      },
      uid: "",
      timestamp: "",
    };

    // Append the new message to the existing messages
    setMessages((prevMessages) => [...prevMessages, newMsg]);

    try {
      const res = await hitAxios({
        path: "/api/chat/send_msg",
        post: true,
        admin: false,
        obj: {
          chatId,
          modelId,
          question,
        },
        globalLoad: false,
      });

      if (res.data.success) {
        const msg = res.data.data;

        // Update the messages state with the new response message
        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          updatedMessages.pop(); // Remove the last message (user's question)
          return [...updatedMessages, ...msg]; // Add the response message
        });
      }
    } catch (error) {
      console.error("Error sending message:", error);
      // Handle error accordingly
    }
  }

  return CON?.data?.loading ? (
    <Box sx={{ width: "100%" }}>
      <ButtonAnime minHeight={22}></ButtonAnime>
    </Box>
  ) : (
    <TextField
      multiline
      value={que}
      maxRows={3}
      onChange={(e) => setQue(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === "Enter" && !e.shiftKey) {
          // // Call your function here
          sendMsg(chat?.chatData?.chat_id, chat?.chatData?.model_id, que);
          // // Prevent the default behavior of the Enter key (new line)
          e.preventDefault();
        }
      }}
      disabled={chat?.selectedModel ? false : true}
      // size="small"
      fullWidth
      placeholder={data.enterMessage}
      InputProps={{
        style: {
          borderRadius: 10,
          // padding: 5,
        },
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title={data.sendMessage}>
              <IconButton
                onClick={() =>
                  sendMsg(
                    chat?.chatData?.chat_id,
                    chat?.chatData?.model_id,
                    que
                  )
                }
                disabled={chat?.selectedModel ? false : true}
                color="info"
              >
                <SendOutlined />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
        startAdornment: (
          <InputAdornment position="start">
            <Tooltip title={data.aiTools}>
              <IconButton
                disabled={chat?.selectedModel ? false : true}
                color="warning"
              >
                <SettingsSuggest />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
        disableUnderline: true,
      }}
    />
  );
};

export default FooterAction;

import {
  Avatar,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { TranslateContext } from "../../context/TranslateContext";
import TextFieldNew from "../../common/TextFieldNew";
import { AccountTreeOutlined, ForwardToInbox, Save } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import TitlePanel from "../../common/TitlePanel";

const SMTP = () => {
  const { data } = React.useContext(TranslateContext);
  const { hitAxios } = React.useContext(GlobalContext);
  const CON = React.useContext(GlobalContext);
  const [state, setState] = React.useState({});

  async function getSmtp() {
    const res = await hitAxios({
      path: "/api/admin/get_smtp",
      post: false,
      admin: true,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_admin"),
    });
    if (res.data.success) {
      setState({ ...state, ...res.data.data });
    }
  }

  async function updateSmtp() {
    const res = await hitAxios({
      path: "/api/admin/update_smtp",
      post: true,
      admin: true,
      obj: state,
    });
  }

  React.useEffect(() => {
    getSmtp();
  }, []);

  async function testEmail() {
    const email = prompt(data.enterEmail);
    if (email) {
      const res = await hitAxios({
        path: "/api/admin/send_test_email",
        post: true,
        admin: true,
        obj: { ...state, to: email },
      });
    }
  }

  return (
    state?.id && (
      <div>
        <Box mt={2}>
          <TitlePanel
            icon={
              <Avatar
                sx={{
                  width: 50,
                  height: 50,
                  bgcolor: "action.hover",
                }}
              >
                <AccountTreeOutlined
                  sx={{
                    color: (t) => t.palette.primary.main,
                  }}
                />
              </Avatar>
            }
            data={data}
            place={data.SMTP}
            title={data.SMTP}
          />

          <Box mt={4}>
            <Stack direction={"column"} spacing={2}>
              <TextFieldNew
                value={state.email}
                onChange={(e) => setState({ ...state, email: e.target.value })}
                label={data.smtpEmail}
              />

              <TextFieldNew
                value={state.host}
                onChange={(e) => setState({ ...state, host: e.target.value })}
                label={data.smtpHost}
              />

              <TextFieldNew
                value={state.port}
                type="number"
                onChange={(e) => setState({ ...state, port: e.target.value })}
                label={data.smtpPort}
              />

              <TextFieldNew
                value={state.password}
                onChange={(e) =>
                  setState({ ...state, password: e.target.value })
                }
                label={data.smtpPass}
              />

              <Stack direction={"row"} spacing={2}>
                <Button
                  onClick={updateSmtp}
                  startIcon={<Save />}
                  sx={{ boxShadow: 0, borderRadius: 2 }}
                  variant="contained"
                >
                  {data.submit}
                </Button>

                <LoadingButton
                  loading={CON.data?.loading}
                  onClick={testEmail}
                  color="info"
                  startIcon={<ForwardToInbox />}
                  sx={{ boxShadow: 0, borderRadius: 2 }}
                  variant="outlined"
                >
                  {data.cehckSMTP}
                </LoadingButton>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </div>
    )
  );
};

export default SMTP;

import { Avatar, Stack, Box } from "@mui/material";
import React from "react";
import TitlePanel from "../../common/TitlePanel";
import { AccountTreeOutlined } from "@mui/icons-material";
import CreateModel from "./CreateModel";
import ModelList from "./ModelList";
import { GlobalContext } from "../../context/GlobalContext";
import UserToken from "../../common/UserToken";

const AiModels = ({ data }) => {
  const { hitAxios } = React.useContext(GlobalContext);

  const [model, setModel] = React.useState([]);

  async function getModels() {
    const res = await hitAxios({
      path: "/api/user/get_ai_models",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      setModel(res.data.data);
    }
  }

  React.useEffect(() => {
    getModels();
  }, []);

  return (
    <div>
      <TitlePanel
        icon={
          <Avatar
            sx={{
              width: 50,
              height: 50,
              bgcolor: "action.hover",
            }}
          >
            <AccountTreeOutlined
              sx={{
                color: (t) => t.palette.primary.main,
              }}
            />
          </Avatar>
        }
        data={data}
        place={data.aiModels}
        title={data.aiModels}
      />

      <Box mt={3}>
        <CreateModel getModels={getModels} data={data} />
      </Box>

      <Box mt={3}>
        <ModelList
          getModels={getModels}
          model={model}
          setModel={setModel}
          data={data}
        />
      </Box>
    </div>
  );
};

export default AiModels;

import { Stack, Avatar, Box, Chip } from "@mui/material";
import React from "react";
import TitlePanel from "../../common/TitlePanel";
import {
  AccountTreeOutlined,
  LibraryAddCheckOutlined,
  MovieFilter,
  MovieFilterOutlined,
} from "@mui/icons-material";
import CreateVideo from "./create/CreateVideo";
import VideoHistory from "./history/VideoHistory";
import UserToken from "../../common/UserToken";

const AiVideoMaker = ({ data }) => {
  const [state, setState] = React.useState({
    doing: "create",
    step: "chooseAudio",
    chooseAudio: "manual",
    choosedAudio: "",
    choosedAudioText: "",
    chooseVideo: "manual",
    choosedVideo: "",
    choosedVideoImage: [],
    colorDialog: false,
    primaryColor: "#dcdfdd",
    secondaryColor: "#21130d",
    outlineColor: "#1786b4",
    backgroundColor: "#f1973f",
    videoResolution: "720x1280",
    fontFamily: "Verdana",
    fontEffect: "Blur",
    captionPosition: "middle",
    language: "Auto",
    fontSize: 18,
    boldCaption: false,
    outlineCaption: false,
    captionType: "word",
    transitionEffect: "zoom",
    voice: "alloy",
  });

  return (
    <div>
      <TitlePanel
        icon={
          <Avatar
            sx={{
              width: 50,
              height: 50,
              bgcolor: "action.hover",
            }}
          >
            <AccountTreeOutlined
              sx={{
                color: (t) => t.palette.primary.main,
              }}
            />
          </Avatar>
        }
        data={data}
        place={data.aiVideoMaker}
        title={data.aiVideoMaker}
      />

      <Box mt={3}>
        <Stack
          direction={"row"}
          spacing={2}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <UserToken />

          <Stack direction={"row"} spacing={2}>
            <Chip
              onClick={() => setState({ ...state, doing: "create" })}
              variant={state.doing == "create" ? "filled" : "outlined"}
              icon={<MovieFilterOutlined sx={{ height: 18, width: 18 }} />}
              label={data.ceateVideo}
              sx={{ p: 1, borderRadius: 2 }}
            />

            <Chip
              onClick={() => setState({ ...state, doing: "history" })}
              variant={state.doing == "history" ? "filled" : "outlined"}
              icon={<LibraryAddCheckOutlined sx={{ height: 18, width: 18 }} />}
              label={data.videoHistory}
              sx={{ p: 1, borderRadius: 2 }}
            />
          </Stack>
        </Stack>
      </Box>

      {state?.doing == "create" && (
        <CreateVideo data={data} state={state} setState={setState} />
      )}

      {state?.doing == "history" && (
        <VideoHistory data={data} state={state} setState={setState} />
      )}
    </div>
  );
};

export default AiVideoMaker;

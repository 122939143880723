import { Button, Typography } from "@mui/material";

const ButtonGrid = ({ ...props }) => {
  const gradientStyle = {
    background:
      "linear-gradient(122deg, rgb(250, 85, 96) 0.01%, rgb(177, 75, 244) 49.9%, rgb(77, 145, 255) 100%)",
    borderRadius: 8,
    border: 0,
    color: "white",
    // height: 48,
    // boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    padding: "11px 20px 11px 20px",
  };
  return (
    <Button {...props} variant="contained" style={gradientStyle}>
      <Typography sx={props.text}>{props.title}</Typography>
    </Button>
  );
};

export default ButtonGrid;

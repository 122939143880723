import { Box, IconButton, Stack, Typography } from "@mui/material";
import React from "react";

const Embed = ({ data }) => {
  return (
    <div>
      <Box
        mt={4}
        display={"flex"}
        justifyContent={"space-between"}
        flexDirection={"column"}
        height={400}
        bgcolor={"action.hover"}
        borderRadius={2}
        p={2}
      >
        <Box bgcolor={"white"} p={1}>
          <Typography variant="caption" color={"gray"}>
            {data.wwwdomainn}
          </Typography>
        </Box>
        <Stack direction={"row"} justifyContent={"flex-end"}>
          <Box
            p={2}
            sx={{
              borderTopRightRadius: 20,
              borderTopLeftRadius: 20,
              borderBottomLeftRadius: 20,
            }}
            bgcolor={"white"}
          >
            <Typography>{data.howCanIHelp}</Typography>
          </Box>
        </Stack>
      </Box>
    </div>
  );
};

export default Embed;

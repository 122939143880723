import { Box, Chip, Stack, Typography } from "@mui/material";
import React from "react";
import ShadowBox from "../../../common/ShadowBox";
import ChooseAudio from "./ChooseAudio";
import ButtonAnime from "../../../common/ButtonAnime";
import ChooseVideo from "./ChooseVideo";
import ChooseColor from "./ChooseColor";
import ChooseStyle from "./ChooseStyle";
import ChooseOther from "./ChooseOther";

const CreateVideo = ({ data, state, setState }) => {
  return (
    <ButtonAnime>
      <Box borderRadius={3} mt={3} p={3} bgcolor={"action.hover"}>
        {state.step === "chooseAudio" && (
          <ChooseAudio data={data} state={state} setState={setState} />
        )}
        {state.step === "chooseVideo" && (
          <ChooseVideo data={data} state={state} setState={setState} />
        )}
        {state.step === "chooseColor" && (
          <ChooseColor data={data} state={state} setState={setState} />
        )}
        {state.step === "chooseStyle" && (
          <ChooseStyle data={data} state={state} setState={setState} />
        )}
        {state.step === "chooseOther" && (
          <ChooseOther data={data} state={state} setState={setState} />
        )}
      </Box>
    </ButtonAnime>
  );
};

export default CreateVideo;

import React from "react";
import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  ImageList,
  Stack,
  Typography,
} from "@mui/material";
import { TranslateContext } from "../../context/TranslateContext";
import { GlobalContext } from "../../context/GlobalContext";
import TextFieldNew from "../../common/TextFieldNew";
import { AccountTreeOutlined, DeleteOutline } from "@mui/icons-material";
import TitlePanel from "../../common/TitlePanel";

const Testimonial = () => {
  const { data } = React.useContext(TranslateContext);
  const [testi, setTesti] = React.useState([]);
  const { hitAxios } = React.useContext(GlobalContext);
  const [state, setState] = React.useState({});
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateScreenWidth);
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  async function getTesti() {
    const res = await hitAxios({
      path: "/api/admin/get_testi",
      post: false,
      admin: false,
      token_user: "",
    });
    if (res.data.success) {
      setTesti(res.data.data);
      setState({
        title: "",
        description: "",
        reviewer_name: "",
        reviewer_position: "",
      });
    }
  }

  React.useEffect(() => {
    getTesti();
  }, []);

  async function addTestimonial() {
    const res = await hitAxios({
      path: "/api/admin/add_testimonial",
      post: true,
      admin: true,
      obj: state,
    });
    if (res.data.success) {
      getTesti();
    }
  }

  async function delTesti(id) {
    if (window.confirm(data.AUS)) {
      const res = await hitAxios({
        path: "/api/admin/del_testi",
        post: true,
        admin: true,
        obj: { id },
      });
      if (res.data.success) {
        getTesti();
      }
    }
  }

  return (
    <div>
      <Box mt={2}>
        <TitlePanel
          icon={
            <Avatar
              sx={{
                width: 50,
                height: 50,
                bgcolor: "action.hover",
              }}
            >
              <AccountTreeOutlined
                sx={{
                  color: (t) => t.palette.primary.main,
                }}
              />
            </Avatar>
          }
          data={data}
          place={data.testimonial}
          title={data.testimonial}
        />

        <Box mt={4}>
          <Stack direction={"column"} spacing={2}>
            <TextFieldNew
              onChange={(e) => setState({ ...state, title: e.target.value })}
              value={state.title}
              label={data.title}
            />
            <TextFieldNew
              onChange={(e) =>
                setState({ ...state, description: e.target.value })
              }
              value={state.description}
              multiline
              rows={2}
              label={data.description}
            />
            <Stack direction={"row"} spacing={2}>
              <TextFieldNew
                onChange={(e) =>
                  setState({ ...state, reviewer_name: e.target.value })
                }
                value={state.reviewer_name}
                fullWidth
                label={data.reviewerName}
              />
              <TextFieldNew
                onChange={(e) =>
                  setState({ ...state, reviewer_position: e.target.value })
                }
                value={state.reviewer_position}
                fullWidth
                label={data.reviewerPosition}
              />
            </Stack>

            <Button
              onClick={addTestimonial}
              variant="contained"
              sx={{ boxShadow: 0, borderRadius: 2 }}
            >
              {data.add}
            </Button>

            <Divider />

            <Box pb={screenWidth > 499 ? 8 : 4} pt={screenWidth > 499 ? 8 : 4}>
              <Container maxWidth="lg">
                <Grid container spacing={4}>
                  {testi?.map((i, key) => (
                    <Grid item xs={12} sm={6} md={4} key={key}>
                      <Box
                        sx={{
                          background: "rgba(255, 255, 255, 0.1)",
                          backdropFilter: "blur(10px)",
                          borderRadius: 4,
                          p: 4,
                          boxShadow: 5,
                          transition: "transform 0.3s ease-in-out",
                          "&:hover": {
                            transform: "scale(1.05)",
                          },
                        }}
                      >
                        <Stack direction={"column"} spacing={2}>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                          >
                            <Typography variant="h6" sx={{ color: "black" }}>
                              {i?.title}
                            </Typography>
                            <IconButton
                              onClick={() => delTesti(i?.id)}
                              sx={{
                                color: "black",
                                background: "rgba(255, 255, 255, 0.2)",
                                "&:hover": {
                                  background: "rgba(255, 255, 255, 0.4)",
                                },
                              }}
                              size="small"
                            >
                              <DeleteOutline fontSize="small" />
                            </IconButton>
                          </Stack>

                          <Typography
                            variant="body1"
                            fontWeight={500}
                            sx={{ color: "black" }}
                          >
                            {i?.description}
                          </Typography>

                          <Stack direction={"column"}>
                            <Typography
                              fontWeight={600}
                              variant="body2"
                              sx={{ color: "black" }}
                            >
                              {i?.reviewer_name}
                            </Typography>
                            <Typography variant="body2" sx={{ color: "gray" }}>
                              {i?.reviewer_position}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Container>
            </Box>
          </Stack>
        </Box>
      </Box>
    </div>
  );
};

export default Testimonial;

import { Box } from "@mui/material";
import React from "react";

const ShadowBox = ({ children, sx = {}, radius, shadow, border, ...props }) => {
  return (
    <Box
      sx={{
        boxShadow: `0px 4px 8px rgba(0, 0, 0, ${shadow || "0.05"})`,
        ...sx,
        borderRadius: radius || 2,
        border: border || 1,
        borderColor: "#F7F7F7",
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default ShadowBox;

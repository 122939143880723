import React from "react";
import TitlePanel from "../../common/TitlePanel";
import { Avatar, Box, Grid, Stack, Typography } from "@mui/material";
import {
  AccountTreeOutlined,
  SaveAlt,
  SaveOutlined,
} from "@mui/icons-material";
import ShadowBox from "../../common/ShadowBox";
import ThemeColor from "./ThemeColor";
import { GlobalContext } from "../../context/GlobalContext";
import ButtonGrid from "../../common/ButtonGrid";

const ThemeSetting = ({ data }) => {
  const [state, setState] = React.useState({});
  const [theme, setTheme] = React.useState({});
  const { hitAxios } = React.useContext(GlobalContext);

  async function updateTheme(params) {
    const res = await hitAxios({
      path: "/api/web/save_theme",
      post: true,
      admin: true,
      obj: { updatedJson: theme },
    });
    if (res.data.success) {
      getTheme();
    }
  }

  async function getTheme() {
    const res = await hitAxios({
      path: "/api/web/get_theme",
      post: false,
      admin: false,
    });
    if (res.data.success) {
      setTheme(res.data.data);
    }
  }

  React.useEffect(() => {
    getTheme();
  }, []);

  return (
    <div>
      <TitlePanel
        icon={
          <Avatar
            sx={{
              width: 50,
              height: 50,
              bgcolor: "action.hover",
            }}
          >
            <AccountTreeOutlined
              sx={{
                color: (t) => t.palette.primary.main,
              }}
            />
          </Avatar>
        }
        data={data}
        place={data.themeSetting}
        title={data.themeSetting}
      />

      <Box mt={3}>
        <Stack alignItems={"flex-end"}>
          <ButtonGrid
            onClick={updateTheme}
            startIcon={<SaveOutlined />}
            title={data.save}
          />
        </Stack>
      </Box>

      <Box borderRadius={2} bgcolor={"action.hover"} p={3} mt={3}>
        <Box mb={2}>
          <Typography variant="h5">{data?.text?.replace(":", "")}</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid xs={6} sm={6} lg={6} md={6} item>
            <Stack direction={"column"} spacing={2}>
              <Box bgcolor={"white"} borderRadius={2} p={2}>
                <Stack alignItems={"center"} direction={"column"} spacing={1}>
                  <Typography>{data.primary}</Typography>
                  <ThemeColor
                    onChange={(e) =>
                      setTheme({
                        ...theme,
                        text: {
                          ...theme.text,
                          primary: e,
                        },
                      })
                    }
                    color={theme?.text?.primary}
                    data={data}
                  />
                </Stack>
              </Box>
            </Stack>
          </Grid>

          <Grid xs={6} sm={6} lg={6} md={6} item>
            <Stack direction={"column"} spacing={2}>
              <Box bgcolor={"white"} borderRadius={2} p={2}>
                <Stack alignItems={"center"} direction={"column"} spacing={1}>
                  <Typography>{data.secondary}</Typography>

                  <ThemeColor
                    onChange={(e) =>
                      setTheme({
                        ...theme,
                        text: {
                          ...theme.text,
                          secondary: e,
                        },
                      })
                    }
                    color={theme?.text?.secondary}
                    data={data}
                  />
                </Stack>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Box borderRadius={2} bgcolor={"action.hover"} p={3} mt={3}>
        <Box mb={2}>
          <Typography variant="h5">
            {data?.primary?.replace(":", "")}
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid xs={6} sm={4} lg={4} md={4} item>
            <Stack direction={"column"} spacing={2}>
              <Box bgcolor={"white"} borderRadius={2} p={2}>
                <Stack alignItems={"center"} direction={"column"} spacing={1}>
                  <Typography>{data.main}</Typography>
                  <ThemeColor
                    onChange={(e) =>
                      setTheme({
                        ...theme,
                        primary: {
                          ...theme.primary,
                          main: e,
                        },
                      })
                    }
                    color={theme?.primary?.main}
                    data={data}
                  />
                </Stack>
              </Box>
            </Stack>
          </Grid>

          <Grid xs={6} sm={4} lg={4} md={4} item>
            <Stack direction={"column"} spacing={2}>
              <Box bgcolor={"white"} borderRadius={2} p={2}>
                <Stack alignItems={"center"} direction={"column"} spacing={1}>
                  <Typography>{data.light}</Typography>

                  <ThemeColor
                    onChange={(e) =>
                      setTheme({
                        ...theme,
                        primary: {
                          ...theme.primary,
                          light: e,
                        },
                      })
                    }
                    color={theme?.primary?.light}
                    data={data}
                  />
                </Stack>
              </Box>
            </Stack>
          </Grid>

          <Grid xs={6} sm={4} lg={4} md={4} item>
            <Stack direction={"column"} spacing={2}>
              <Box bgcolor={"white"} borderRadius={2} p={2}>
                <Stack alignItems={"center"} direction={"column"} spacing={1}>
                  <Typography>{data.dark}</Typography>

                  <ThemeColor
                    onChange={(e) =>
                      setTheme({
                        ...theme,
                        primary: {
                          ...theme.primary,
                          dark: e,
                        },
                      })
                    }
                    color={theme?.primary?.dark}
                    data={data}
                  />
                </Stack>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Box borderRadius={2} bgcolor={"action.hover"} p={3} mt={3}>
        <Box mb={2}>
          <Typography variant="h5">
            {data?.secondary?.replace(":", "")}
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid xs={6} sm={4} lg={4} md={4} item>
            <Stack direction={"column"} spacing={2}>
              <Box bgcolor={"white"} borderRadius={2} p={2}>
                <Stack alignItems={"center"} direction={"column"} spacing={1}>
                  <Typography>{data.main}</Typography>
                  <ThemeColor
                    onChange={(e) =>
                      setTheme({
                        ...theme,
                        secondary: {
                          ...theme.secondary,
                          main: e,
                        },
                      })
                    }
                    color={theme?.secondary?.main}
                    data={data}
                  />
                </Stack>
              </Box>
            </Stack>
          </Grid>

          <Grid xs={6} sm={4} lg={4} md={4} item>
            <Stack direction={"column"} spacing={2}>
              <Box bgcolor={"white"} borderRadius={2} p={2}>
                <Stack alignItems={"center"} direction={"column"} spacing={1}>
                  <Typography>{data.light}</Typography>

                  <ThemeColor
                    onChange={(e) =>
                      setTheme({
                        ...theme,
                        secondary: {
                          ...theme.secondary,
                          light: e,
                        },
                      })
                    }
                    color={theme?.secondary?.light}
                    data={data}
                  />
                </Stack>
              </Box>
            </Stack>
          </Grid>

          <Grid xs={6} sm={4} lg={4} md={4} item>
            <Stack direction={"column"} spacing={2}>
              <Box bgcolor={"white"} borderRadius={2} p={2}>
                <Stack alignItems={"center"} direction={"column"} spacing={1}>
                  <Typography>{data.dark}</Typography>

                  <ThemeColor
                    onChange={(e) =>
                      setTheme({
                        ...theme,
                        secondary: {
                          ...theme.secondary,
                          dark: e,
                        },
                      })
                    }
                    color={theme?.secondary?.dark}
                    data={data}
                  />
                </Stack>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Box borderRadius={2} bgcolor={"action.hover"} p={3} mt={3}>
        <Box mb={2}>
          <Typography variant="h5">
            {data?.success?.replace(":", "")}
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid xs={6} sm={4} lg={4} md={4} item>
            <Stack direction={"column"} spacing={2}>
              <Box bgcolor={"white"} borderRadius={2} p={2}>
                <Stack alignItems={"center"} direction={"column"} spacing={1}>
                  <Typography>{data.main}</Typography>
                  <ThemeColor
                    onChange={(e) =>
                      setTheme({
                        ...theme,
                        success: {
                          ...theme.success,
                          main: e,
                        },
                      })
                    }
                    color={theme?.success?.main}
                    data={data}
                  />
                </Stack>
              </Box>
            </Stack>
          </Grid>

          <Grid xs={6} sm={4} lg={4} md={4} item>
            <Stack direction={"column"} spacing={2}>
              <Box bgcolor={"white"} borderRadius={2} p={2}>
                <Stack alignItems={"center"} direction={"column"} spacing={1}>
                  <Typography>{data.light}</Typography>

                  <ThemeColor
                    onChange={(e) =>
                      setTheme({
                        ...theme,
                        success: {
                          ...theme.success,
                          light: e,
                        },
                      })
                    }
                    color={theme?.success?.light}
                    data={data}
                  />
                </Stack>
              </Box>
            </Stack>
          </Grid>

          <Grid xs={6} sm={4} lg={4} md={4} item>
            <Stack direction={"column"} spacing={2}>
              <Box bgcolor={"white"} borderRadius={2} p={2}>
                <Stack alignItems={"center"} direction={"column"} spacing={1}>
                  <Typography>{data.dark}</Typography>

                  <ThemeColor
                    onChange={(e) =>
                      setTheme({
                        ...theme,
                        success: {
                          ...theme.success,
                          dark: e,
                        },
                      })
                    }
                    color={theme?.success?.dark}
                    data={data}
                  />
                </Stack>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Box borderRadius={2} bgcolor={"action.hover"} p={3} mt={3}>
        <Box mb={2}>
          <Typography variant="h5">{data?.info?.replace(":", "")}</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid xs={6} sm={4} lg={4} md={4} item>
            <Stack direction={"column"} spacing={2}>
              <Box bgcolor={"white"} borderRadius={2} p={2}>
                <Stack alignItems={"center"} direction={"column"} spacing={1}>
                  <Typography>{data.main}</Typography>
                  <ThemeColor
                    onChange={(e) =>
                      setTheme({
                        ...theme,
                        info: {
                          ...theme.info,
                          main: e,
                        },
                      })
                    }
                    color={theme?.info?.main}
                    data={data}
                  />
                </Stack>
              </Box>
            </Stack>
          </Grid>

          <Grid xs={6} sm={4} lg={4} md={4} item>
            <Stack direction={"column"} spacing={2}>
              <Box bgcolor={"white"} borderRadius={2} p={2}>
                <Stack alignItems={"center"} direction={"column"} spacing={1}>
                  <Typography>{data.light}</Typography>

                  <ThemeColor
                    onChange={(e) =>
                      setTheme({
                        ...theme,
                        info: {
                          ...theme.info,
                          light: e,
                        },
                      })
                    }
                    color={theme?.info?.light}
                    data={data}
                  />
                </Stack>
              </Box>
            </Stack>
          </Grid>

          <Grid xs={6} sm={4} lg={4} md={4} item>
            <Stack direction={"column"} spacing={2}>
              <Box bgcolor={"white"} borderRadius={2} p={2}>
                <Stack alignItems={"center"} direction={"column"} spacing={1}>
                  <Typography>{data.dark}</Typography>

                  <ThemeColor
                    onChange={(e) =>
                      setTheme({
                        ...theme,
                        info: {
                          ...theme.info,
                          dark: e,
                        },
                      })
                    }
                    color={theme?.info?.dark}
                    data={data}
                  />
                </Stack>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Box borderRadius={2} bgcolor={"action.hover"} p={3} mt={3}>
        <Box mb={2}>
          <Typography variant="h5">{data?.error?.replace(":", "")}</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid xs={6} sm={4} lg={4} md={4} item>
            <Stack direction={"column"} spacing={2}>
              <Box bgcolor={"white"} borderRadius={2} p={2}>
                <Stack alignItems={"center"} direction={"column"} spacing={1}>
                  <Typography>{data.main}</Typography>
                  <ThemeColor
                    onChange={(e) =>
                      setTheme({
                        ...theme,
                        error: {
                          ...theme.error,
                          main: e,
                        },
                      })
                    }
                    color={theme?.error?.main}
                    data={data}
                  />
                </Stack>
              </Box>
            </Stack>
          </Grid>

          <Grid xs={6} sm={4} lg={4} md={4} item>
            <Stack direction={"column"} spacing={2}>
              <Box bgcolor={"white"} borderRadius={2} p={2}>
                <Stack alignItems={"center"} direction={"column"} spacing={1}>
                  <Typography>{data.light}</Typography>

                  <ThemeColor
                    onChange={(e) =>
                      setTheme({
                        ...theme,
                        error: {
                          ...theme.error,
                          light: e,
                        },
                      })
                    }
                    color={theme?.error?.light}
                    data={data}
                  />
                </Stack>
              </Box>
            </Stack>
          </Grid>

          <Grid xs={6} sm={4} lg={4} md={4} item>
            <Stack direction={"column"} spacing={2}>
              <Box bgcolor={"white"} borderRadius={2} p={2}>
                <Stack alignItems={"center"} direction={"column"} spacing={1}>
                  <Typography>{data.dark}</Typography>

                  <ThemeColor
                    onChange={(e) =>
                      setTheme({
                        ...theme,
                        error: {
                          ...theme.error,
                          dark: e,
                        },
                      })
                    }
                    color={theme?.error?.dark}
                    data={data}
                  />
                </Stack>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Box borderRadius={2} bgcolor={"action.hover"} p={3} mt={3}>
        <Box mb={2}>
          <Typography variant="h5">
            {data?.warning?.replace(":", "")}
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid xs={6} sm={4} lg={4} md={4} item>
            <Stack direction={"column"} spacing={2}>
              <Box bgcolor={"white"} borderRadius={2} p={2}>
                <Stack alignItems={"center"} direction={"column"} spacing={1}>
                  <Typography>{data.main}</Typography>
                  <ThemeColor
                    onChange={(e) =>
                      setTheme({
                        ...theme,
                        warning: {
                          ...theme.warning,
                          main: e,
                        },
                      })
                    }
                    color={theme?.warning?.main}
                    data={data}
                  />
                </Stack>
              </Box>
            </Stack>
          </Grid>

          <Grid xs={6} sm={4} lg={4} md={4} item>
            <Stack direction={"column"} spacing={2}>
              <Box bgcolor={"white"} borderRadius={2} p={2}>
                <Stack alignItems={"center"} direction={"column"} spacing={1}>
                  <Typography>{data.light}</Typography>

                  <ThemeColor
                    onChange={(e) =>
                      setTheme({
                        ...theme,
                        warning: {
                          ...theme.warning,
                          light: e,
                        },
                      })
                    }
                    color={theme?.warning?.light}
                    data={data}
                  />
                </Stack>
              </Box>
            </Stack>
          </Grid>

          <Grid xs={6} sm={4} lg={4} md={4} item>
            <Stack direction={"column"} spacing={2}>
              <Box bgcolor={"white"} borderRadius={2} p={2}>
                <Stack alignItems={"center"} direction={"column"} spacing={1}>
                  <Typography>{data.dark}</Typography>

                  <ThemeColor
                    onChange={(e) =>
                      setTheme({
                        ...theme,
                        warning: {
                          ...theme.warning,
                          dark: e,
                        },
                      })
                    }
                    color={theme?.warning?.dark}
                    data={data}
                  />
                </Stack>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default ThemeSetting;

import { SmartToy } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";

const OneMsg = ({ i, key }) => {
  return (
    <div>
      {i?.sender === "user" && (
        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          alignItems={"flex-end"}
        >
          <Box
            sx={{
              borderTopRightRadius: 15,
              borderBottomLeftRadius: 15,
              borderTopLeftRadius: 15,
              bgcolor: "action.hover",
              width: {
                sm: "90%",
                lg: "50%",
                md: "50%",
              },
            }}
            // width={"50%"}
            p={1.5}
          >
            <Typography fontWeight={300}>{i?.text}</Typography>
          </Box>
        </Stack>
      )}

      {i?.sender === "assistant" && (
        <Stack
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
        >
          <Box
            sx={{
              borderTopRightRadius: 15,
              borderBottomRightRadius: 15,
              borderTopLeftRadius: 15,
              width: {
                sm: "90%",
                lg: "50%",
                md: "50%",
              },
            }}
          >
            <Typography fontWeight={300}>{i?.text}</Typography>
          </Box>
        </Stack>
      )}
    </div>
  );
};

export default OneMsg;

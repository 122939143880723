import {
  SlowMotionVideo,
  CheckCircle,
  ArrowCircleRight,
  Psychology,
  Close,
  ArrowCircleLeft,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CardMedia,
  Chip,
  Divider,
  Icon,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import DragUpload from "../../../common/DragUpload";
import ButtonAnime from "../../../common/ButtonAnime";
import SliderComp from "../../../common/SliderComp";
import ChooseVideoScrap from "./ChooseVideoScrap";

const parseResolution = (resolution) => {
  const [width, height] = resolution.split("x").map(Number);
  return { width, height };
};

const ChooseVideo = ({ data, state, setState }) => {
  return (
    <Stack
      alignItems={"center"}
      justifyContent={"center"}
      spacing={4}
      direction={"column"}
      minHeight={300}
    >
      <Stack spacing={1} direction={"row"}>
        <SlowMotionVideo />
        <Typography variant="h4">{data.chooseVideo}</Typography>
      </Stack>

      <Stack direction={"row"} spacing={2}>
        <Button
          onClick={() => setState({ ...state, chooseVideo: "manual" })}
          sx={{ fontWeight: state.chooseVideo == "manual" ? "bold" : 200 }}
        >
          {data.uploadVideo}
        </Button>

        <Button
          onClick={() => setState({ ...state, chooseVideo: "ai" })}
          sx={{ fontWeight: state.chooseVideo == "ai" ? "bold" : 200 }}
        >
          {data.letTheAiCreate}
        </Button>
      </Stack>

      {state.chooseVideo == "manual" && (
        <Box minWidth={"40%"}>
          <DragUpload
            onUrl={(url) => setState({ ...state, choosedVideo: url })}
            title={data.selVideo}
            accept={".mp4"}
            acceptType={["video/mp4"]}
          />
        </Box>
      )}

      {state.chooseVideo == "ai" && (
        <Stack sx={{ minWidth: "40%" }} direction={"column"} spacing={2}>
          <Box minWidth={"40%"}>
            <ButtonAnime fullWidth>
              <DragUpload
                remove={true}
                onUrl={(url) =>
                  setState({
                    ...state,
                    choosedVideoImage: [...state.choosedVideoImage, url],
                  })
                }
                title={data.addImage}
                accept={".jpeg, .jpg, .png"}
                acceptType={["image/jpg", "image/jpeg", "image/png"]}
              />
            </ButtonAnime>
          </Box>

          <Typography align="center">{data.or}</Typography>

          <ChooseVideoScrap data={data} state={state} setState={setState} />

          <Divider />
        </Stack>
      )}

      {state.chooseVideo == "ai" && state.choosedVideoImage?.length < 2 && (
        <Typography align="center" color={"orange"} variant="caption">
          {data.req2Img}
        </Typography>
      )}

      <Stack sx={{ minWidth: "40%" }}>
        <Divider />
      </Stack>

      {state.chooseVideo == "ai" && (
        <Stack direction={"column"} spacing={3}>
          <Stack direction={"column"} spacing={0} p={2}>
            <Typography
              align="center"
              color={"gray"}
              variant="caption"
              sx={{ pb: 2 }}
            >
              {data.videoREsolution}
            </Typography>
            <Stack alignItems={"flex-start"} direction={"row"} spacing={2}>
              <Stack
                alignContent={"center"}
                justifyContent={"center"}
                direction={"column"}
                spacing={2}
              >
                <Chip
                  onClick={() =>
                    setState({ ...state, videoResolution: "720x1280" })
                  }
                  variant={
                    state.videoResolution == "720x1280" ? "filled" : "outlined"
                  }
                  label="720x1280"
                />
                <Stack alignItems={"center"}>
                  <Box
                    width={parseResolution("720x1280").width / 20}
                    height={parseResolution("720x1280").height / 20}
                    borderRadius={1}
                    sx={{
                      border: 2,
                      borderColor: "#d3d3d3",
                    }}
                  />
                </Stack>
              </Stack>

              <Stack
                alignContent={"center"}
                justifyContent={"space-between"}
                direction={"column"}
                spacing={2}
              >
                <Chip
                  onClick={() =>
                    setState({ ...state, videoResolution: "720x720" })
                  }
                  variant={
                    state.videoResolution == "720x720" ? "filled" : "outlined"
                  }
                  label="720x720"
                />
                <Stack alignItems={"center"}>
                  <Box
                    width={parseResolution("720x720").width / 20}
                    height={parseResolution("720x720").height / 20}
                    borderRadius={1}
                    sx={{
                      border: 2,
                      borderColor: "#d3d3d3",
                    }}
                  />
                </Stack>
              </Stack>

              <Stack
                alignContent={"center"}
                justifyContent={"space-between"}
                direction={"column"}
                spacing={2}
              >
                <Chip
                  onClick={() =>
                    setState({ ...state, videoResolution: "1280x720" })
                  }
                  variant={
                    state.videoResolution == "1280x720" ? "filled" : "outlined"
                  }
                  label="1280x720"
                />
                <Stack alignItems={"center"}>
                  <Box
                    width={parseResolution("1280x720").width / 20}
                    height={parseResolution("1280x720").height / 20}
                    borderRadius={1}
                    sx={{
                      border: 2,
                      borderColor: "#d3d3d3",
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      )}

      {state.chooseVideo == "ai" && state.choosedVideoImage?.length > 0 && (
        <SliderComp spacing={2}>
          {state.choosedVideoImage?.map((i, key) => {
            return (
              <Box position={"relative"}>
                <CardMedia
                  key={key}
                  src={`/media/${i}`}
                  component={"img"}
                  sx={{ height: 100, width: 100, borderRadius: 2 }}
                />
                <IconButton
                  onClick={() => {
                    const newOne = state.choosedVideoImage?.filter(
                      (ii) => ii !== i
                    );
                    setState({ ...state, choosedVideoImage: newOne });
                  }}
                  sx={{ position: "absolute", top: 0, right: 0 }}
                  size="small"
                >
                  <Close sx={{ height: 12, width: 12 }} />
                </IconButton>
              </Box>
            );
          })}
        </SliderComp>
      )}

      <Stack direction={"row"} spacing={4}>
        <Tooltip title={data.back}>
          <IconButton
            onClick={() => setState({ ...state, step: "chooseAudio" })}
            color="success"
          >
            <ArrowCircleLeft sx={{ height: 50, width: 50 }} />
          </IconButton>
        </Tooltip>

        {/* next button  */}
        {state.chooseVideo == "manual" && state.choosedVideo && (
          <Tooltip title={data.next}>
            <IconButton
              onClick={() => setState({ ...state, step: "chooseColor" })}
              color="success"
            >
              <ArrowCircleRight sx={{ height: 50, width: 50 }} />
            </IconButton>
          </Tooltip>
        )}

        {state.chooseVideo == "ai" && state.choosedVideoImage?.length > 1 && (
          <Tooltip title={data.next}>
            <IconButton
              onClick={() => setState({ ...state, step: "chooseColor" })}
              color="success"
            >
              <ArrowCircleRight sx={{ height: 50, width: 50 }} />
            </IconButton>
          </Tooltip>
        )}
        {/* next button  */}
      </Stack>
    </Stack>
  );
};

export default ChooseVideo;

import {
  Box,
  CardActionArea,
  CardMedia,
  Container,
  Divider,
  Grid,
  IconButton,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { ExpandMore, Translate } from "@mui/icons-material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Footer = ({ data, screenWidth, main }) => {
  const { hitAxios } = React.useContext(GlobalContext);
  const [page, setPage] = React.useState([]);
  const [web, setWeb] = React.useState({});
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [lang, setLang] = React.useState([]);

  async function getLangs() {
    const res = await hitAxios({
      path: "/api/web/get-all-translation-name",
      admin: false,
      post: false,
    });
    if (res.data.success) {
      setLang(res.data.data);
    }
  }

  async function getPages() {
    const res = await hitAxios({
      path: "/api/admin/get_pages",
      post: false,
      admin: false,
      token_user: "",
    });
    if (res.data.success) {
      setPage(res.data.data);
    }
  }

  async function getWeb() {
    const res = await hitAxios({
      path: "/api/web/get_web_public",
      post: false,
      admin: false,
      token_user: "",
    });
    if (res.data.success) {
      setWeb(res.data.data);
    }
  }

  React.useEffect(() => {
    getWeb();
    getPages();
    getLangs();
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    web?.id && (
      <div>
        <Box
          sx={{ bgcolor: main ? null : "action.hover" }}
          pb={screenWidth > 499 ? 6 : 3}
          pt={screenWidth > 499 ? 6 : 3}
        >
          <Container maxWidth="lg">
            <Box mb={4}>
              <Stack
                spacing={4}
                alignItems={"flex-end"}
                justifyContent={"space-between"}
                direction={"row"}
              >
                <CardMedia
                  onClick={() => history.push("/")}
                  src={`/media/${web?.logo}`}
                  component={"img"}
                  sx={{
                    height: "100%",
                    width: 100,
                    borderRadius: 3,
                    cursor: "pointer",
                  }}
                />

                <Stack
                  onClick={handleClick}
                  id="translate"
                  sx={{ cursor: "pointer" }}
                  alignItems={"center"}
                  direction={"row"}
                >
                  <Translate sx={{ height: 35, width: 35 }} />
                  <ExpandMore sx={{ height: 18, width: 18 }} />
                </Stack>

                <Popover
                  id="translate"
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Stack direction={"column"}>
                    {lang?.map((i, key) => {
                      return (
                        <CardActionArea
                          onClick={() => {
                            localStorage.setItem(
                              "language",
                              i.replace(".json", "")
                            );
                            window.location.reload();
                          }}
                          key={key}
                          sx={{ p: 1.5 }}
                        >
                          <Typography>{i?.replace(".json", "")}</Typography>
                        </CardActionArea>
                      );
                    })}
                  </Stack>
                </Popover>
              </Stack>
            </Box>

            <Grid container spacing={4}>
              <Grid xs={12} sm={6} lg={6} item>
                <Stack direction={"column"} spacing={2}>
                  <Typography variant="h5" fontWeight={600}>
                    {data.useFUlLinks}
                  </Typography>

                  <Typography
                    sx={{ cursor: "pointer" }}
                    onClick={() => history.push("/view/privacy-policy")}
                    fontWeight={600}
                  >
                    {data.privacyPlicy}
                  </Typography>

                  <Typography
                    sx={{ cursor: "pointer" }}
                    onClick={() => history.push("/view/terms-and-conditions")}
                    fontWeight={600}
                  >
                    {data.termsCondition}
                  </Typography>

                  <Typography
                    sx={{ cursor: "pointer" }}
                    onClick={() => history.push("/page/contact-form")}
                    fontWeight={600}
                  >
                    {data.contactUs}
                  </Typography>
                </Stack>
              </Grid>

              <Grid xs={12} sm={6} lg={6} item>
                <Stack direction={"column"} spacing={2}>
                  <Typography variant="h5" fontWeight={600}>
                    {data.pages}
                  </Typography>

                  {page?.map((i, key) => {
                    return (
                      <Typography
                        onClick={() => history.push(`/view/${i?.slug}`)}
                        sx={{ cursor: "pointer" }}
                        key={key}
                        fontWeight={600}
                      >
                        {i?.title}
                      </Typography>
                    );
                  })}
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </div>
    )
  );
};

export default Footer;

import {
  Box,
  CardMedia,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import Pricing from "../../common/Pricing";
import { GlobalContext } from "../../context/GlobalContext";

const PricingComp = ({ data }) => {
  const { hitAxios } = React.useContext(GlobalContext);
  const [planData, setPlanData] = React.useState([]);

  const [state, setState] = React.useState({
    addPlanDialog: false,
    in_app_chat: false,
    image_maker: false,
    code_writer: false,
    speech_to_text: false,
    voice_maker: false,
    ai_video: false,
    isTrial: false,
  });

  async function getPlan() {
    const res = await hitAxios({
      path: "/api/web/get_plans",
      post: false,
      admin: false,
    });
    if (res.data.success) {
      setPlanData(res.data.data);
    }
  }

  React.useEffect(() => {
    getPlan();
  }, []);

  return (
    <div>
      <Container maxWidth="lg">
        <Box mb={4}>
          <Stack alignItems={"center"} direction={"column"} spacing={4}>
            <Typography align="center" variant="h3">
              {data.affordablePlans}
            </Typography>

            <CardMedia
              sx={{
                height: 100,
                width: 100,
              }}
              component={"img"}
              src="/assets/pay.svg"
            />
          </Stack>
        </Box>
        <Grid direction={"row"} spacing={2} container>
          <Pricing
            user
            getPlan={getPlan}
            planData={planData}
            data={data}
            hitAxios={hitAxios}
          />
        </Grid>
      </Container>
    </div>
  );
};

export default PricingComp;

import React from "react";
import {
  Box,
  Dialog,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { TranslateContext } from "../../context/TranslateContext";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { GlobalContext } from "../../context/GlobalContext";
import { DeleteOutline, Visibility } from "@mui/icons-material";
import CloseBtn from "../../context/CloseBtn";

const ContactForm = () => {
  const { data } = React.useContext(TranslateContext);
  const [forms, setForms] = React.useState([]);
  const { hitAxios } = React.useContext(GlobalContext);
  const [state, setState] = React.useState({
    dialog: false,
    msg: "",
  });

  async function getForms() {
    const res = await hitAxios({
      path: "/api/admin/get_contact_leads",
      post: false,
      admin: true,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_admin"),
    });
    if (res.data.success) {
      setForms(res.data.data);
    }
  }

  React.useEffect(() => {
    getForms();
  }, []);

  async function delEntry(id) {
    if (window.confirm(data.AUS)) {
      const res = await hitAxios({
        path: "/api/admin/del_cotact_entry",
        post: true,
        admin: true,
        obj: { id },
      });
      if (res.data.success) {
        getForms();
      }
    }
  }

  return (
    <div>
      <Dialog
        fullWidth
        PaperProps={{
          style: { borderRadius: 10 },
        }}
        open={state.dialog}
        onClose={() => setState({ ...state, dialog: false })}
      >
        <Box p={2}>
          <Stack direction={"row"} justifyContent={"flex-end"}>
            <CloseBtn onClick={() => setState({ ...state, dialog: false })} />
          </Stack>
          <Divider />
          <Box mt={2}>
            <Typography>{state?.msg}</Typography>
          </Box>
        </Box>
      </Dialog>

      <Box mt={2}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          spacing={2}
          justifyContent={"space-between"}
        >
          <Stack direction={"column"}>
            <Typography fontWeight={"bold"} variant="h6">
              {data.leads}
            </Typography>
            <Typography color={"gray"} variant="caption">
              {data.leadsDes}
            </Typography>
          </Stack>
        </Stack>

        <Box mt={4} borderRadius={4} bgcolor={"action.hover"}>
          {forms ? (
            <Box height="80vh">
              <DataGrid
                getRowId={(row) => row.id}
                rows={[...forms].reverse()}
                columns={[
                  {
                    headerName: data.email,
                    field: "email",
                    flex: 1,
                  },
                  {
                    headerName: data.name,
                    field: "name",
                    flex: 1,
                  },
                  {
                    headerName: data.mob,
                    field: "mobile",
                    flex: 1,
                  },
                  {
                    headerName: data.message,
                    field: "content",
                    flex: 1,
                    renderCell: (row) => {
                      return (
                        <IconButton
                          onClick={() => {
                            setState({
                              ...state,
                              dialog: true,
                              msg: row.row?.content,
                            });
                          }}
                        >
                          <Visibility />
                        </IconButton>
                      );
                    },
                  },
                  {
                    headerName: data.delete,
                    field: "del",
                    flex: 1,
                    renderCell: (row) => {
                      return (
                        <IconButton
                          color="error"
                          onClick={() => {
                            delEntry(row.row?.id);
                          }}
                        >
                          <DeleteOutline />
                        </IconButton>
                      );
                    },
                  },
                ]}
                pageSize={50}
                rowsPerPageOptions={[50]}
                checkboxSelection={false}
                // components={{
                //     Toolbar: CustomToolbar
                // }}
                sx={{
                  boxShadow: 0,
                  borderRadius: 4,
                  border: 0,
                  fontSize: 13,
                  padding: 2,
                }}
              />
            </Box>
          ) : (
            ""
          )}
        </Box>
      </Box>
    </div>
  );
};

export default ContactForm;

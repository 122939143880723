import { ChatOutlined, Close, DeleteOutline } from "@mui/icons-material";
import {
  Box,
  ButtonBase,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import moment from "moment";

const ChatHistory = ({
  data,
  state,
  setState,
  hitAxios,
  messages,
  setMessages,
  setChat,
  chat,
  history,
  setHistory,
  getChatHistory,
}) => {
  async function getMsgsHistory(chatId, num, i) {
    const res = await hitAxios({
      path: `/api/chat/get_one_chat?msgNum=${num || 100}`,
      post: true,
      admin: false,
      obj: { chatId: chatId, modelId: i?.model_id },
    });
    if (res.data.success) {
      setMessages(res.data.data);
      setChat({ ...chat, selectedModel: res.data?.model, chatData: i });
      setState({ ...state, selectModelDialog: false });
    }
  }

  async function deltChat(chatId, modelId) {
    if (window.confirm(data.aus)) {
      const res = await hitAxios({
        path: "/api/chat/del_one_chat",
        post: true,
        admin: false,
        obj: { chatId },
      });
      if (res.data.success) {
        getChatHistory();
        const openedChat = chat?.selectedModel?.model_id;

        if (openedChat == modelId) {
          setChat({ ...chat, selectedModel: "" });
        }
      }
    }
  }

  return (
    <Drawer
      anchor="right"
      open={state.historyDialog}
      onClose={() => setState({ ...state, historyDialog: false })}
    >
      <Box overflow={"auto"} width={300} minHeight={"100vh"}>
        <Box p={2}>
          <Stack direction={"column"} spacing={3}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography variant="h6">{data.chatHistory}</Typography>
              <IconButton
                onClick={() => {
                  setState({ ...state, historyDialog: false });
                }}
              >
                <Close />
              </IconButton>
            </Stack>
          </Stack>
        </Box>
        <Divider />

        <Stack p={1} spacing={2} direction={"column"}>
          {history.map((i, key) => {
            return (
              <Box
                sx={{ width: "100%" }}
                borderRadius={2}
                key={key}
                bgcolor={"action.hover"}
                p={1}
              >
                <Stack
                  direction={"row"}
                  spacing={1}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Stack
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      getMsgsHistory(i?.chat_id, 100, i);
                    }}
                    alignItems={"flex-start"}
                    direction={"column"}
                  >
                    <Typography>{i?.title}</Typography>
                    <Typography
                      variant="caption"
                      color={"gray"}
                      fontWeight={300}
                    >
                      {moment(i?.createdAt).fromNow()}
                    </Typography>
                  </Stack>

                  <IconButton onClick={() => deltChat(i?.chat_id, i?.model_id)}>
                    <DeleteOutline
                      color="error"
                      sx={{ height: 18, width: 18 }}
                    />
                  </IconButton>
                </Stack>
              </Box>
            );
          })}
        </Stack>
      </Box>
    </Drawer>
  );
};

export default ChatHistory;

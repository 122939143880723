import React from "react";
import { TranslateContext } from "../../context/TranslateContext";
import { GlobalContext } from "../../context/GlobalContext";
import { Box, Button, Stack, Typography } from "@mui/material";
import {
  Autorenew,
  CloudSyncRounded,
  PublishedWithChanges,
  SecurityUpdate,
} from "@mui/icons-material";
import axios from "axios";

const UpdateApp = () => {
  const { data } = React.useContext(TranslateContext);
  const { hitAxios } = React.useContext(GlobalContext);
  const [show, setShow] = React.useState(false);
  const [latestVer, setLatestVer] = React.useState("");

  async function checkUpdate() {
    const res = await axios.get(
      "https://envato-buyer.oneoftheprojects.com/api/web/check_update_dallham"
    );
    if (res.data.success) {
      setLatestVer(res.data.version);
    }
  }

  async function check() {
    const res = await hitAxios({
      path: "/api/web/update_to_be_shown",
      post: false,
      admin: false,
    });
    if (res.data.success) {
      setShow(res.data.show);
    }
  }

  React.useEffect(() => {
    check();
    checkUpdate();
  }, []);
  return (
    <div>
      <Box mt={2}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          spacing={2}
          justifyContent={"space-between"}
        >
          <Stack direction={"column"}>
            <Typography fontWeight={"bold"} variant="h6">
              {data.updateApp}
            </Typography>
            <Typography color={"gray"} variant="caption">
              {data.updateYourAppInOneClick}
            </Typography>
          </Stack>
        </Stack>

        <Box mt={4}>
          <Stack alignItems={"flex-start"} direction={"column"} spacing={2}>
            <Box bgcolor={"action.hover"}>
              <Stack p={2} direction={"row"} spacing={2}>
                <SecurityUpdate />
                <Typography>
                  {data.yourcurver} {process.env.REACT_APP_VERSION}.
                </Typography>
              </Stack>
            </Box>

            {latestVer && (
              <Box bgcolor={"action.hover"}>
                <Stack p={2} direction={"row"} spacing={2}>
                  <SecurityUpdate />
                  <Typography>
                    {data.appLatestVersionIs + ": " + latestVer}.
                  </Typography>
                </Stack>
              </Box>
            )}

            {show && (
              <Button
                onClick={() =>
                  window.open(`https://envato-buyer.oneoftheprojects.com/`)
                }
                startIcon={<CloudSyncRounded />}
                variant="contained"
                sx={{
                  boxShadow: 0,
                  borderRadius: 2,
                }}
              >
                {data.checkForUpdate}
              </Button>
            )}
          </Stack>
        </Box>
      </Box>
    </div>
  );
};

export default UpdateApp;

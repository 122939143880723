import axios from "axios";
import React from "react";
import { useHistory } from "react-router-dom";

export const GlobalContext = React.createContext(null);

export const GlobalProvider = (props) => {
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const url = process.env.REACT_APP_BASE_URL;
  const [data, setData] = React.useState({
    loading: false,
    snack: false,
    snack_msg: "",
    campaign_done: false,
    tutorial: {},
    userData: {},
    globalLoad: true,
  });

  const history = useHistory();

  React.useEffect(() => {
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateScreenWidth);
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  function invertColor(hex) {
    if (hex.indexOf("#") === 0) {
      hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error("Invalid HEX color.");
    }
    // invert color components
    var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
      g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
      b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
    // pad each with zeros and return
    return "#" + padZero(r) + padZero(g) + padZero(b);
  }

  function addOpacityToHexColor(hexColor, opacity) {
    // Remove any existing opacity from the hex color
    hexColor = hexColor.replace(/[^0-9A-Fa-f]/g, "");

    // Validate the hex color code
    if (!/^[0-9A-Fa-f]{6}$/.test(hexColor)) {
      console.error("Invalid hex color code");
      return null;
    }

    // Validate the opacity value
    if (opacity < -1 || opacity > 5) {
      console.error("Opacity should be between -1 and 5");
      return null;
    }

    // Convert hex to RGB
    const r = parseInt(hexColor.substring(0, 2), 16);
    const g = parseInt(hexColor.substring(2, 4), 16);
    const b = parseInt(hexColor.substring(4, 6), 16);

    // Calculate the adjusted darkness
    const darkness = opacity > 1 ? opacity : 1 / opacity;

    // Adjust darkness within a reasonable range
    const adjustedDarkness = Math.min(5, Math.max(0, darkness));

    // Calculate new RGB values
    const newR = Math.round(r * adjustedDarkness);
    const newG = Math.round(g * adjustedDarkness);
    const newB = Math.round(b * adjustedDarkness);

    // Convert new RGB values to hexadecimal
    const newHexColor = `${(newR < 16 ? "0" : "") + newR.toString(16)}${
      (newG < 16 ? "0" : "") + newG.toString(16)
    }${(newB < 16 ? "0" : "") + newB.toString(16)}`;

    return `#${newHexColor}`;
  }

  function padZero(str, len) {
    len = len || 2;
    var zeros = new Array(len).join("0");
    return (zeros + str).slice(-len);
  }

  async function hitAxios({
    path,
    obj,
    admin,
    post,
    token_user,
    globalLoad = Boolean,
  }) {
    const token = admin
      ? localStorage.getItem(process.env.REACT_APP_TOKEN + "_admin")
      : localStorage.getItem(process.env.REACT_APP_TOKEN + "_user");

    setData({ ...data, loading: true, globalLoad: globalLoad ? true : false });
    return new Promise(async (resolve, reject) => {
      try {
        const resp = post
          ? await axios.post(url + path, obj, {
              headers: {
                Authorization: "Bearer " + token,
              },
            })
          : await axios.get(url + path, {
              headers: {
                Authorization: "Bearer " + token_user,
              },
            });

        if (resp?.data?.logout) {
          admin
            ? localStorage.removeItem(process.env.REACT_APP_TOKEN + "_admin")
            : localStorage.removeItem(process.env.REACT_APP_TOKEN + "_user");
          history.push(admin ? "/admin" : "/user");
        }

        if (!resp.data.success) {
          console.log(resp.data);
        }

        if (!resp.data.success) {
          setData({
            ...data,
            loading: false,
            snack: resp?.data?.msg ? true : false,
            snack_msg: resp?.data?.msg || "Great",
            snack_success: false,
            globalLoad: true,
          });
          setTimeout(() => {
            setData({
              ...data,
              loading: false,
              snack: false,
              snack_msg: resp?.data?.msg || "Great",
              snack_success: false,
              globalLoad: true,
            });
          }, 4000);
        } else {
          setData({
            ...data,
            loading: false,
            snack: resp?.data?.msg ? true : false,
            snack_msg: resp?.data?.msg || "Great",
            snack_success: true,
            globalLoad: true,
          });
          setTimeout(() => {
            setData({
              ...data,
              loading: false,
              snack: false,
              snack_msg: resp?.data?.msg || "Great",
              snack_success: true,
              globalLoad: true,
            });
          }, 4000);
        }

        resolve(resp);
      } catch (err) {
        console.log(err);
        setData({
          ...data,
          snack_msg: JSON.stringify(err),
          snack: true,
          loading: false,
          globalLoad: true,
        });
        setTimeout(() => {
          setData({
            ...data,
            snack_msg: "",
            snack: false,
            loading: false,
            globalLoad: true,
          });
        }, 4000);
      }
    });
  }

  return (
    <GlobalContext.Provider
      value={{
        data,
        setData,
        hitAxios,
        addOpacityToHexColor,
        invertColor,
        screenWidth,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

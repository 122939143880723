import { Campaign, SlowMotionVideo } from "@mui/icons-material";
import {
  Box,
  CardMedia,
  Fade,
  Slide,
  Stack,
  Typography,
  Zoom,
} from "@mui/material";
import React, { useState, useEffect } from "react";

const VideoMaker = ({ data }) => {
  const endNumber = 100;
  const duration = 1.5;
  const [count, setCount] = useState(0);
  const [show, setShow] = React.useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 2000);
    const countToNumber = (endNumber, totalDurationInSeconds) => {
      return new Promise((resolve) => {
        const totalSteps = endNumber + 1;
        const delayBetweenSteps = (totalDurationInSeconds * 1000) / totalSteps;

        let currentNumber = 0;
        const interval = setInterval(() => {
          setCount(currentNumber);
          currentNumber += 1;

          if (currentNumber > endNumber) {
            clearInterval(interval);
            resolve();
          }
        }, delayBetweenSteps);
      });
    };

    countToNumber(data.loremLopez, duration);
  }, [data.loremLopez, duration]);

  return (
    <div>
      <Box mt={5}>
        <Box>
          <Fade in timeout={2000}>
            <Typography variant="h5" fontWeight={100}>
              {data.loremLopez?.slice(0, count)}
            </Typography>
          </Fade>
        </Box>

        {show && (
          <Zoom in timeout={1000}>
            <Box
              mt={4}
              height={300}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              bgcolor="action.hover"
              borderRadius={2}
            >
              <SlowMotionVideo />
            </Box>
          </Zoom>
        )}
      </Box>
    </div>
  );
};

export default VideoMaker;

import React from "react";
import { TranslateContext } from "../../context/TranslateContext";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { ConnectWithoutContact, Send } from "@mui/icons-material";
import TextFieldNew from "../../common/TextFieldNew";
import ButtonComp from "../../context/ButtonComp";
import { GlobalContext } from "../../context/GlobalContext";
import Header from "../component/Header";
import Footer from "../component/Footer";
import ButtonAnime from "../../common/ButtonAnime";
import ButtonGrid from "../../common/ButtonGrid";

const ContactForm = () => {
  const { data } = React.useContext(TranslateContext);
  const { hitAxios } = React.useContext(GlobalContext);
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const [state, setState] = React.useState({});

  async function addContactForm() {
    const res = await hitAxios({
      path: "/api/web/submit_contact_form",
      post: true,
      admin: false,
      obj: state,
    });
    if (res.data.success) {
      setState({ ...state, name: "", email: "", content: "", mobile: "" });
    }
  }

  React.useEffect(() => {
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateScreenWidth);
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  return (
    <div>
      <Header />

      <Container maxWidth="lg">
        <Box mt={4}>
          <Stack direction={"column"} spacing={4}>
            <Stack alignItems={"flex-start"} direction={"row"} spacing={2}>
              <Box pt={1}>
                <ConnectWithoutContact
                  sx={{ color: "gray", height: 40, width: 40 }}
                />
              </Box>

              <Stack direction={"column"}>
                <Typography variant="h3" fontWeight={600}>
                  {data.contactUs}
                </Typography>
                <Typography fontWeight={500}>{data.filltheForm}</Typography>
              </Stack>
            </Stack>

            <Box
              sx={{
                borderRight: 5,
                borderColor: (t) => t.palette.primary.main,
                borderTop: 5,
                borderTopColor: (t) => t.palette.primary.main,
              }}
              bgcolor={"action.hover"}
              p={screenWidth > 899 ? 5 : 3}
              borderRadius={3}
            >
              <Stack direction={"column"} spacing={2}>
                <TextFieldNew
                  value={state.name}
                  onChange={(e) => setState({ ...state, name: e.target.value })}
                  label={data.name}
                />
                <TextFieldNew
                  type="number"
                  value={state.mobile}
                  onChange={(e) =>
                    setState({ ...state, mobile: e.target.value })
                  }
                  label={data.mobile}
                />
                <TextFieldNew
                  value={state.email}
                  onChange={(e) =>
                    setState({ ...state, email: e.target.value })
                  }
                  label={data.yourEmail}
                />
                <TextFieldNew
                  value={state.content}
                  onChange={(e) =>
                    setState({ ...state, content: e.target.value })
                  }
                  multiline
                  rows={4}
                  label={data.yourMsg}
                />

                <Stack pt={2}>
                  <ButtonGrid
                    onClick={addContactForm}
                    startIcon={<Send />}
                    title={data.submit}
                  />
                </Stack>
              </Stack>
            </Box>
          </Stack>

          <Box pb={4} pt={4}>
            <Grid container spacing={2}>
              <Grid xs={12} sm={4} lg={4} item>
                <Box
                  minHeight={120}
                  bgcolor={"action.hover"}
                  borderRadius={3}
                  p={3}
                >
                  <Stack direction={"column"} spacing={2}>
                    <Typography variant="h6" fontWeight={600}>
                      {data.conP1}
                    </Typography>
                    <Typography fontWeight={350}>{data.conP2}</Typography>
                  </Stack>
                </Box>
              </Grid>

              <Grid xs={12} sm={4} lg={4} item>
                <Box
                  minHeight={120}
                  bgcolor={"action.hover"}
                  borderRadius={3}
                  p={3}
                >
                  <Stack direction={"column"} spacing={2}>
                    <Typography variant="h6" fontWeight={600}>
                      {data.conP3}
                    </Typography>
                    <Typography fontWeight={350}>{data.conP4}</Typography>
                  </Stack>
                </Box>
              </Grid>

              <Grid xs={12} sm={4} lg={4} item>
                <Box
                  minHeight={120}
                  bgcolor={"action.hover"}
                  borderRadius={3}
                  p={3}
                >
                  <Stack direction={"column"} spacing={2}>
                    <Typography variant="h6" fontWeight={600}>
                      {data.conP5}
                    </Typography>
                    <Typography fontWeight={350}>{data.conP5Des}</Typography>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>

      <Footer data={data} />
    </div>
  );
};

export default ContactForm;

import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  IconButton,
  Stack,
  LinearProgress,
} from "@mui/material";
import { GlobalContext } from "../context/GlobalContext";
import { TranslateContext } from "../context/TranslateContext";
import { Close } from "@mui/icons-material";

const DragUpload = ({ title, accept, acceptType, onUrl, remove = false }) => {
  const { hitAxios } = React.useContext(GlobalContext);
  const CON = React.useContext(GlobalContext);
  const { data } = React.useContext(TranslateContext);
  const [file, setFile] = useState(null);
  const [dragging, setDragging] = useState(false);

  async function returnUrl(uploadedFile) {
    setFile(uploadedFile);
    const fd = new FormData();
    fd.append("file", uploadedFile);
    const res = await hitAxios({
      path: "/api/web/return_url",
      post: true,
      admin: false,
      obj: fd,
    });
    if (res.data.success) {
      onUrl(res.data?.filename);
      if (remove) {
        setFile(null);
      }
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const uploadedFile = e.dataTransfer.files[0];
    if (
      `${acceptType || ["image/jpg", "image/png", "image/jpeg"]}`.includes(
        uploadedFile.type
      )
    ) {
      returnUrl(uploadedFile);
    } else {
      alert(data.fileTypeNa);
    }
  };

  return (
    <Box
      sx={{
        border: dragging ? "2px dashed #3f51b5" : "2px dashed #ccc",
        backgroundColor: dragging ? "#f0f0f0" : "#fff",
        borderRadius: 2,
      }}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {file && (
        <Stack p={0.5} alignItems={"flex-end"}>
          <IconButton onClick={() => setFile(null)} size="small">
            <Close
              sx={{
                height: 18,
                width: 18,
              }}
            />
          </IconButton>
        </Stack>
      )}

      {/* {CON?.data?.loading && <LinearProgress />} */}

      <Box
        component={"label"}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
        p={3}
      >
        <input
          accept={accept || ".jpg, .png, .jpeg"}
          type="file"
          hidden
          onChange={(e) => returnUrl(e.target.files[0])}
        />
        <Typography
          color={"gray"}
          sx={{
            width: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textAlign: "center",
          }}
        >
          {file
            ? file?.name?.length > 20
              ? file?.name?.slice(0, 20) + "..."
              : file?.name
            : title || data.dragOrSelectimg}
        </Typography>
      </Box>
    </Box>
  );
};

export default DragUpload;

import React from "react";
import ShadowBox from "../../common/ShadowBox";
import { DataGrid } from "@mui/x-data-grid";
import {
  Avatar,
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import {
  DeleteOutline,
  Edit,
  EditOutlined,
  Login,
  hitAxios,
} from "@mui/icons-material";
import UpdateUser from "./UpdateUser";
import { GlobalContext } from "../../context/GlobalContext";

const UserList = ({ data, userData, getUsers, hitAxios }) => {
  const { screenWidth } = React.useContext(GlobalContext);
  const [editUser, setEditUser] = React.useState({});
  const [state, setState] = React.useState({
    dialog: false,
  });

  async function direcLogin(e) {
    const res = await hitAxios({
      path: "/api/admin/auto_login",
      post: true,
      admin: true,
      obj: { uid: e },
    });

    if (res.data.success) {
      localStorage.setItem(
        process.env.REACT_APP_TOKEN + "_user",
        res.data.token
      );
      window.open("/user");
    }
  }

  async function delUser(id) {
    if (window.confirm(data.AUS)) {
      const res = await hitAxios({
        path: "/api/admin/del_user",
        post: true,
        admin: true,
        obj: { id },
      });
      if (res.data.success) {
        getUsers();
      }
    }
  }

  return (
    <div>
      <ShadowBox>
        {userData ? (
          <Box p={1.5} height="70vh">
            <DataGrid
              style={{
                padding: 0,
              }}
              sx={{
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "action.hover",
                  borderRadius: 2,
                },
              }}
              getRowId={(row) => row.id}
              rows={[...userData].reverse()}
              columns={[
                {
                  headerName: data.autoLogin,
                  field: "auto_login",
                  flex: 0.5,
                  renderCell: (row) => {
                    return (
                      <Tooltip title={data.autoLogin}>
                        <IconButton
                          color="info"
                          onClick={() => direcLogin(row.row.uid)}
                        >
                          <Login />
                        </IconButton>
                      </Tooltip>
                    );
                  },
                  renderHeader: (row) => (
                    <a
                      style={{
                        fontSize: 14,
                      }}
                    >
                      {row?.colDef?.headerName}
                    </a>
                  ),
                },
                ,
                {
                  headerName: data.name,
                  field: "name",
                  flex: 1,
                  renderHeader: (row) => (
                    <a
                      style={{
                        fontSize: 14,
                      }}
                    >
                      {row?.colDef?.headerName}
                    </a>
                  ),
                  renderCell: (row) => {
                    return (
                      <Stack
                        direction={"row"}
                        spacing={1.5}
                        alignItems={"center"}
                      >
                        {screenWidth > 890 && (
                          <Avatar
                            sx={{
                              height: 30,
                              width: 30,
                            }}
                          >
                            {row?.row?.name?.slice(0, 1)}
                          </Avatar>
                        )}

                        <Stack spacing={-0.5} direction={"column"}>
                          <Typography fontWeight={"bold"}>
                            {row?.row?.name}
                          </Typography>
                          <Typography variant="caption" color={"gray"}>
                            {row?.row?.email}
                          </Typography>
                        </Stack>
                      </Stack>
                    );
                  },
                },
                {
                  headerName: data.mobile,
                  field: "mobile",
                  flex: 1,
                  renderHeader: (row) => (
                    <a
                      style={{
                        fontSize: 14,
                      }}
                    >
                      {row?.colDef?.headerName}
                    </a>
                  ),
                },
                {
                  headerName: data.plan,
                  field: "plan",
                  flex: 0.8,
                  renderHeader: (row) => (
                    <a
                      style={{
                        fontSize: 14,
                      }}
                    >
                      {row?.colDef?.headerName}
                    </a>
                  ),
                  renderCell: (row) =>
                    row?.row?.plan ? JSON.parse(row?.row?.plan)?.name : data.na,
                },
                {
                  headerName: data.update,
                  field: "update",
                  flex: 0.5,
                  renderHeader: (row) => (
                    <a
                      style={{
                        fontSize: 14,
                      }}
                    >
                      {row?.colDef?.headerName}
                    </a>
                  ),
                  renderCell: (row) => (
                    <IconButton
                      onClick={() => {
                        setState({
                          ...state,
                          dialog: true,
                        });
                        setEditUser(row?.row);
                      }}
                      size="small"
                      color="warning"
                    >
                      <EditOutlined />
                    </IconButton>
                  ),
                },
                {
                  headerName: data.del,
                  field: "del",
                  flex: 0.5,
                  renderHeader: (row) => (
                    <a
                      style={{
                        fontSize: 14,
                      }}
                    >
                      {row?.colDef?.headerName}
                    </a>
                  ),
                  renderCell: (row) => (
                    <IconButton
                      onClick={() => {
                        delUser(row.row.id);
                      }}
                      size="small"
                      color="error"
                    >
                      <DeleteOutline />
                    </IconButton>
                  ),
                },
                {
                  headerName: data.creatdAt,
                  field: "creatdAt",
                  flex: 1,
                  renderHeader: (row) => (
                    <a
                      style={{
                        fontSize: 14,
                      }}
                    >
                      {row?.colDef?.headerName}
                    </a>
                  ),
                  renderCell: (row) =>
                    screenWidth > 890
                      ? moment(row?.row.creatdAt)?.format("DD-MM-YY | hh:mmA")
                      : moment(row?.row.creatdAt)?.format("DD-MM-YY"),
                },
              ]}
              pageSize={50}
              rowsPerPageOptions={[50]}
              checkboxSelection={false}
              // components={{
              //     Toolbar: CustomToolbar
              // }}
            />
          </Box>
        ) : (
          ""
        )}
      </ShadowBox>

      <UpdateUser
        hitAxios={hitAxios}
        editUser={editUser}
        setEditUser={setEditUser}
        state={state}
        setState={setState}
        data={data}
        userData={userData}
        getUsers={getUsers}
      />
    </div>
  );
};

export default UserList;

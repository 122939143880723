import { DeleteOutline, Downloading, EditOutlined } from "@mui/icons-material";
import {
  Box,
  CardMedia,
  Grow,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

const ImageList = ({ data, imgData, i, hitAxios, getImgData }) => {
  async function delImage(id, filename) {
    if (window.confirm(data.aus)) {
      const res = await hitAxios({
        path: "/api/user/del_ai_image",
        post: true,
        admin: false,
        obj: { id, filename },
      });
      if (res.data.success) {
        getImgData();
      }
    }
  }

  return (
    <Grow in={true} timeout={1000}>
      <Box
        sx={{
          position: "relative",
          borderRadius: 2,
          overflow: "hidden",
          "&:hover::after": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background:
              "linear-gradient(to top, rgba(0,0,0,0.5), rgba(0,0,0,0))",
            zIndex: 1,
          },
          "&:hover img": {
            filter: "blur(7px)",
          },
          "&:hover .promptText": {
            opacity: 1,
          },
        }}
      >
        <CardMedia
          component="img"
          src={`/media/${i?.filename}`}
          sx={{
            position: "relative",
            zIndex: 0,
            width: "100%",
            height: "auto",
            transition: "filter 0.3s ease-in-out",
          }}
        />

        <Box
          p={2}
          position={"absolute"}
          bottom={0}
          width="100%"
          sx={{
            opacity: 0,
            transition: "opacity 0.3s ease-in-out",
            zIndex: 2,
          }}
          className="promptText"
        >
          <Stack direction={"column"}>
            <Stack direction={"row"} justifyContent={"flex-end"}>
              <IconButton onClick={() => window.open(`/media/${i?.filename}`)}>
                <Downloading color="success" />
              </IconButton>
              <IconButton onClick={() => delImage(i?.id, i?.filename)}>
                <DeleteOutline color="error" />
              </IconButton>
            </Stack>
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <Typography color={"white"}>{i?.prompt}</Typography>
            </Stack>
            <Typography fontSize={11} fontWeight={300} color={"white"}>
              {data?.size}: {i?.image_size}
            </Typography>
          </Stack>
        </Box>
      </Box>
    </Grow>
  );
};

export default ImageList;

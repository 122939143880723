import React from "react";
import UserDrawer from "./components/UserDrawer";
import { Box, Typography } from "@mui/material";
import { TranslateContext } from "../../context/TranslateContext";
import AiModels from "../ai-models/AiModels";
import AiChat from "../ai-chat/AiChat";
import ImageMaker from "../image-maker/ImageMaker";
import SpeechToText from "../apeech-to-text/SpeechToText";
import VoiceMaker from "../voice-maker/VoiceMaker";
import AiVideoMaker from "../ai-video-maker/AiVideoMaker";
import Subscription from "../subscription/Subscription";
import EmbedChatbot from "../embed-chatbot/EmbedChatbot";
import Profile from "../profile/Profile";
import DashComp from "./dash/DashComp";
import LogOutUser from "../logout/LogOutUser";

const UserDashboard = () => {
  const { data } = React.useContext(TranslateContext);

  const [page, setPage] = React.useState(null);
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let foo = params.get("page");

  React.useEffect(() => {
    setPage(foo || "dashboard");
  }, [foo]);

  return (
    <div>
      <UserDrawer page={page} setPage={setPage} data={data}>
        {page === "dashboard" && <DashComp data={data} />}
        {page === "ai-models" && <AiModels data={data} />}
        {page === "ai-chat" && <AiChat data={data} />}
        {page === "ai-image" && <ImageMaker data={data} />}
        {page === "ai-speech-to-text" && <SpeechToText data={data} />}
        {page === "ai-voice-maker" && <VoiceMaker data={data} />}
        {page === "ai-video-maker" && <AiVideoMaker data={data} />}
        {page === "subscription" && <Subscription data={data} />}
        {page === "embed-chatbot" && <EmbedChatbot data={data} />}
        {page === "profile" && <Profile data={data} />}
        {page === "logout" && <LogOutUser data={data} />}
      </UserDrawer>
    </div>
  );
};

export default UserDashboard;

import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import SideMenu from "./SideMenu";
import { ButtonBase, CardMedia, Divider, Stack } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { GlobalContext } from "../../../context/GlobalContext";

const drawerWidth = 270;

function UserDrawer({ children, data, page, setPage, ...props }) {
  const history = useHistory();
  const { hitAxios } = React.useContext(GlobalContext);
  const [web, setWeb] = React.useState({});
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  async function getWeb() {
    const res = await hitAxios({
      path: "/api/web/get_web_public",
      post: false,
      admin: false,
      token_user: "",
    });
    if (res.data.success) {
      setWeb(res.data.data);
    }
  }

  React.useEffect(() => {
    getWeb();
  }, []);

  const drawer = (
    <div>
      {web?.logo && (
        <ButtonBase onClick={() => history.push("/")}>
          <Stack p={0.9} alignItems={"center"} direction={"row"} spacing={0}>
            <CardMedia
              height={50}
              component={"img"}
              sx={{ minWidth: 50 }}
              src={`/media/${web?.logo}`}
            />
            <Typography variant="h3" fontWeight={100}>
              {web?.app_name}
            </Typography>
          </Stack>
        </ButtonBase>
      )}
      <Divider />
      <SideMenu data={data} page={page} setPage={setPage} web={{}} />
    </div>
  );

  // Remove this const when copying and pasting into your project.
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        color="inherit"
        sx={{
          width: { lg: `calc(100% - ${drawerWidth}px)` },
          ml: { lg: `${drawerWidth}px` },
          bgcolor: (t) => "action.hover",
          boxShadow: 0,
          borderBottom: 1,
          borderColor: (t) => t.palette.divider,
        }}
      >
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { lg: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6">{data.hello}</Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", lg: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { lg: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}

UserDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window: PropTypes.func,
};

export default UserDrawer;

import {
  Box,
  CardActionArea,
  Stack,
  CardMedia,
  Typography,
  List,
} from "@mui/material";
import React from "react";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import NavGroup from "./NavGroup";
import NavItem from "./NavItem";
import {
  AccountTreeOutlined,
  AddTaskOutlined,
  AppSettingsAlt,
  BrowserUpdated,
  ConnectWithoutContact,
  Dashboard,
  DashboardOutlined,
  Description,
  FacebookOutlined,
  FormatColorFill,
  ForwardToInbox,
  Handshake,
  ImportContactsOutlined,
  Logout,
  PaidOutlined,
  Password,
  Payments,
  PermContactCalendarOutlined,
  QuestionAnswerOutlined,
  Quiz,
  Reviews,
  ScheduleSendOutlined,
  SmartToyOutlined,
  SupportAgentOutlined,
  Translate,
  ViewListOutlined,
  WidgetsOutlined,
} from "@mui/icons-material";

const SideMenu = ({ page, setPage, data }) => {
  const history = useHistory();

  const Menuitems = [
    {
      navlabel: true,
      subheader: data?.information || "loading...",
    },
    {
      title: data?.dashboard || "loading...",
      icon: DashboardOutlined,
      path: "dashboard",
    },
    {
      title: data?.users || "loading...",
      icon: AccountTreeOutlined,
      path: "users",
    },
    {
      title: data?.plans || "loading...",
      icon: PaidOutlined,
      path: "plans",
    },
    {
      title: data?.apiKeys || "loading...",
      icon: Password,
      path: "api-keys",
    },
    {
      title: data?.paymentGateway || "loading...",
      icon: Payments,
      path: "payment-gateway",
    },
    {
      title: data?.fronPartners || "loading...",
      icon: Handshake,
      path: "partners",
    },
    {
      title: data?.faq || "loading...",
      icon: Quiz,
      path: "faq",
    },
    {
      title: data?.managePage || "loading...",
      icon: Description,
      path: "manage-page",
    },
    {
      title: data?.testimonial || "loading...",
      icon: Reviews,
      path: "testimonial",
    },
    {
      title: data?.appConfig || "loading...",
      icon: AppSettingsAlt,
      path: "app-config",
    },
    {
      title: data?.themeSetting || "loading...",
      icon: FormatColorFill,
      path: "theme-setting",
    },
    {
      title: data?.smtpEmail || "loading...",
      icon: ForwardToInbox,
      path: "smtp",
    },
    {
      title: data?.webTranslation || "loading...",
      icon: Translate,
      path: "translation",
    },
    {
      title: data?.leads || "loading...",
      icon: ConnectWithoutContact,
      path: "leads",
    },
    {
      title: data?.updateApp || "loading...",
      icon: BrowserUpdated,
      path: "update-app",
    },
    {
      title: data?.logout || "loading...",
      icon: Logout,
      path: "logout",
    },
  ];

  return (
    <div>
      <Box p={1.6}>
        <Stack direction={"column"} spacing={3}>
          <List sx={{ pt: 0 }} className="sidebarNav">
            {Menuitems.map((item, index) => {
              if (item.navlabel) {
                return <NavGroup key={index} item={item} />;
              } else {
                return (
                  <NavItem
                    key={index}
                    item={item}
                    page={page}
                    onClick={() => {
                      history.push(`/admin?page=${item.path}`);
                      setPage(item.path);
                    }}
                  />
                );
              }
            })}
          </List>
        </Stack>
      </Box>
    </div>
  );
};

export default SideMenu;

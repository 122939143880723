import { Button, Chip, Stack, Typography } from "@mui/material";
import React from "react";
import ButtonGrid from "../../../common/ButtonGrid";
import SmallDialog from "../../../common/SmallDialog";
import { LoadingButton } from "@mui/lab";
import {
  AddCircleOutline,
  AutoAwesome,
  PhotoFilter,
} from "@mui/icons-material";
import TextFieldNew from "../../../common/TextFieldNew";
import SliderComp from "../../../common/SliderComp";
import { GlobalContext } from "../../../context/GlobalContext";
import UserToken from "../../../common/UserToken";

const GenerateImg = ({
  data,
  state,
  setState,
  img,
  setImg,
  genImg,
  getImgData,
}) => {
  const CON = React.useContext(GlobalContext);
  const sdSize = [
    "1536x640",
    "640x1536",
    "832x1216",
    "1152x896",
    "1344x768",
    "768x1344",
    "896x1152",
  ];

  const imgStyle = [
    "3d-model",
    "analog-film",
    "anime",
    "cinematic",
    "comic-book",
    "digital-art",
    "enhance",
    "fantasy-art",
    "isometric",
    "line-art",
    "low-poly",
    "modeling-compound",
    "neon-punk origami",
    "photographic",
    "pixel-art",
    "tile-texture",
  ];

  return (
    <div>
      <Stack>
        <Stack direction={"row"} spacing={2} justifyContent={"space-between"}>
          <UserToken />
          <ButtonGrid
            onClick={() => setState({ ...state, addDialog: true })}
            startIcon={<AddCircleOutline />}
            title={data.generateImg}
          />
        </Stack>

        <SmallDialog
          onClose={() => setState({ ...state, addDialog: false })}
          open={state.addDialog}
          dialogType="fullWidth"
          title={data.generateImg}
        >
          <Stack direction={"column"} spacing={3}>
            <Stack direction={"row"} spacing={2}>
              <Chip
                onClick={() =>
                  setImg({ ...img, aiType: "OPENAI", imgSize: "256x256" })
                }
                variant={img?.aiType === "OPENAI" ? "filled" : "outlined"}
                icon={<AutoAwesome sx={{ height: 18, width: 18 }} />}
                label={data.openAi}
              />
              <Chip
                onClick={() =>
                  setImg({ ...img, aiType: "SD", imgSize: "1536x640" })
                }
                variant={img?.aiType === "SD" ? "filled" : "outlined"}
                icon={<AutoAwesome sx={{ height: 18, width: 18 }} />}
                label={data.stableDiffusion}
              />
            </Stack>

            <TextFieldNew
              value={img?.prompt}
              onChange={(e) => setImg({ ...img, prompt: e.target.value })}
              multiline
              rows={4}
              label={data.prompt}
            />

            {img?.aiType === "OPENAI" && (
              <Stack direction={"column"} spacing={1}>
                <Typography variant="caption" color={"gray"}>
                  {data.imageReso}
                </Typography>
                <SliderComp spacing={2}>
                  <Chip
                    onClick={() => setImg({ ...img, imgSize: "256x256" })}
                    variant={img.imgSize === "256x256" ? "filled" : "outlined"}
                    label="256x256"
                  />
                  <Chip
                    onClick={() => setImg({ ...img, imgSize: "512x512" })}
                    variant={img.imgSize === "512x512" ? "filled" : "outlined"}
                    label="512x512"
                  />
                  <Chip
                    onClick={() => setImg({ ...img, imgSize: "1024x1024" })}
                    variant={
                      img.imgSize === "1024x1024" ? "filled" : "outlined"
                    }
                    label="1024x1024"
                  />
                </SliderComp>
              </Stack>
            )}

            {img?.aiType === "SD" && (
              <Stack direction={"column"} spacing={1}>
                <Typography variant="caption" color={"gray"}>
                  {data.imageReso}
                </Typography>
                <SliderComp spacing={2}>
                  {sdSize.map((i, key) => {
                    return (
                      <Chip
                        key={key}
                        onClick={() => setImg({ ...img, imgSize: i })}
                        variant={img.imgSize === i ? "filled" : "outlined"}
                        label={i}
                      />
                    );
                  })}
                </SliderComp>
              </Stack>
            )}

            <Stack direction={"column"} spacing={1}>
              <Typography variant="caption" color={"gray"}>
                {data.imgStyle}
              </Typography>
              <SliderComp spacing={2}>
                {imgStyle.map((i, key) => {
                  return (
                    <Chip
                      key={key}
                      onClick={() => setImg({ ...img, imgStyle: i })}
                      variant={img.imgStyle === i ? "filled" : "outlined"}
                      label={i}
                    />
                  );
                })}
              </SliderComp>
            </Stack>

            <LoadingButton
              loading={CON?.data?.loading}
              onClick={genImg}
              variant="contained"
              startIcon={<PhotoFilter />}
            >
              {data.genImage}
            </LoadingButton>
          </Stack>
        </SmallDialog>
      </Stack>
    </div>
  );
};

export default GenerateImg;

import { Close, Cancel } from '@mui/icons-material'
import { Box, Stack } from '@mui/material'
import React from 'react'

const CloseBtn = ({ onClick, small }) => {
    return (
        <Stack direction={'row'}>
            <Box onClick={onClick} sx={{ cursor: 'pointer' }} position={'relative'}>
                {/* <Box bgcolor={"#FF6666"} p={1.5} borderRadius={'50%'}>
                </Box> */}
                <Box position={'absolute'} top={0} left={4} border={1} p={small ? 1 : 1.5} borderRadius={'50%'}>
                </Box>
                <Cancel sx={{ color: "#FF6666", height: small ? 20 : 30, width: small ? 20 : 30 }} />
            </Box>
        </Stack>
    )
}

export default CloseBtn
import { Stack, Typography } from "@mui/material";
import React from "react";

const TitleHeading = ({ text, icon }) => {
  return (
    <div>
      <Stack alignItems={"center"} direction={"row"} spacing={2}>
        {icon}
        <Typography variant="h5" fontWeight={400}>
          {text}
        </Typography>
      </Stack>
    </div>
  );
};

export default TitleHeading;

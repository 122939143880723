import {
  Audiotrack,
  CheckCircle,
  ArrowCircleRight,
  Psychology,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import DragUpload from "../../../common/DragUpload";
import ButtonAnime from "../../../common/ButtonAnime";
import TextFieldNew from "../../../common/TextFieldNew";
import ChooseAudioText from "./ChooseAudioText";

const aiVoice = ["alloy", "echo", "fable", "nova", "onyx", "shimmer"];

const ChooseAudio = ({ data, state, setState }) => {
  return (
    <Stack
      alignItems={"center"}
      justifyContent={"center"}
      spacing={4}
      direction={"column"}
      minHeight={300}
    >
      <Stack spacing={1} direction={"row"}>
        <Audiotrack />
        <Typography variant="h4">{data.chooseAudio}</Typography>
      </Stack>

      <Stack direction={"row"} spacing={2}>
        <Button
          onClick={() => setState({ ...state, chooseAudio: "manual" })}
          sx={{ fontWeight: state.chooseAudio == "manual" ? "bold" : 200 }}
        >
          {data.uploadAudio}
        </Button>

        <Button
          onClick={() => setState({ ...state, chooseAudio: "ai" })}
          sx={{ fontWeight: state.chooseAudio == "ai" ? "bold" : 200 }}
        >
          {data.letTheAiCreate}
        </Button>

        <Button
          onClick={() => setState({ ...state, chooseAudio: "none" })}
          sx={{ fontWeight: state.chooseAudio == "none" ? "bold" : 200 }}
        >
          {data.none}
        </Button>
      </Stack>

      <Box minWidth={"40%"}>
        {state.chooseAudio !== "none" && (
          <Autocomplete
            value={state.voice}
            onChange={(e, newValue) => {
              if (newValue?.label) {
                setState({ ...state, voice: newValue?.label });
              }
            }}
            size="small"
            sx={{ "& fieldset": { borderRadius: 3 } }}
            id="combo-box-demo"
            options={aiVoice.map((i) => {
              return {
                label: i,
              };
            })}
            renderInput={(params) => (
              <TextField
                InputProps={{
                  style: { borderRadius: 10 },
                }}
                size="small"
                {...params}
                label={data.chooseVoice}
              />
            )}
          />
        )}
      </Box>

      {state.chooseAudio == "manual" && (
        <Box minWidth={"40%"}>
          <DragUpload
            onUrl={(url) => setState({ ...state, choosedAudio: url })}
            title={data.selAudio}
            accept={".mp3"}
            acceptType={["audio/mpeg"]}
          />
        </Box>
      )}

      {state.chooseAudio == "ai" && (
        <ChooseAudioText data={data} state={state} setState={setState} />
      )}

      {state.chooseAudio == "ai" && (
        <ButtonAnime sx={{ minWidth: "40%" }}>
          <TextFieldNew
            value={state.choosedAudioText}
            onChange={(e) =>
              setState({ ...state, choosedAudioText: e.target.value })
            }
            placeholder={data.topicsummary}
            fullWidth
            place
            multiline
            rows={4}
          />
        </ButtonAnime>
      )}

      {state.chooseAudio == "ai" && (
        <Tooltip title={data.next}>
          <IconButton
            disabled={state.choosedAudioText?.length > 10 ? false : true}
            onClick={() => setState({ ...state, step: "chooseVideo" })}
            color="success"
          >
            <ArrowCircleRight sx={{ height: 50, width: 50 }} />
          </IconButton>
        </Tooltip>
      )}

      {state.chooseAudio == "manual" && state.choosedAudio && (
        <Tooltip title={data.next}>
          <IconButton
            onClick={() => setState({ ...state, step: "chooseVideo" })}
            color="success"
          >
            <ArrowCircleRight sx={{ height: 50, width: 50 }} />
          </IconButton>
        </Tooltip>
      )}

      {state.chooseAudio == "none" && (
        <Tooltip title={data.next}>
          <IconButton
            onClick={() => setState({ ...state, step: "chooseVideo" })}
            color="success"
          >
            <ArrowCircleRight sx={{ height: 50, width: 50 }} />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
};

export default ChooseAudio;

import React from "react";
import { Avatar, Button, Stack } from "@mui/material";
import TitlePanel from "../../common/TitlePanel";
import TextFieldNew from "../../common/TextFieldNew";
import { AccountTreeOutlined } from "@mui/icons-material";
import { GlobalContext } from "../../context/GlobalContext";

const ApiKeys = ({ data }) => {
  const { hitAxios } = React.useContext(GlobalContext);
  const [keys, setKeys] = React.useState({});

  async function getKeys() {
    const res = await hitAxios({
      path: "/api/web/get_keys",
      post: false,
      admin: true,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_admin"),
    });
    if (res.data.success) {
      setKeys(res.data.data);
    }
  }

  async function getApi() {
    const res = await hitAxios({
      path: "/api/web/update_api_keys",
      post: true,
      admin: true,
      obj: keys,
    });
  }

  React.useEffect(() => {
    getKeys();
  }, []);

  return (
    <div>
      <Stack direction={"column"} spacing={3}>
        <TitlePanel
          icon={
            <Avatar
              sx={{
                width: 50,
                height: 50,
                bgcolor: "action.hover",
              }}
            >
              <AccountTreeOutlined
                sx={{
                  color: (t) => t.palette.primary.main,
                }}
              />
            </Avatar>
          }
          data={data}
          place={data.apiKeys}
          title={data.apiKeys}
        />

        <TextFieldNew
          value={keys?.open_ai}
          onChange={(e) => setKeys({ ...keys, open_ai: e.target?.value })}
          label={data.openAiKeys}
        />
        <TextFieldNew
          value={keys?.gemini_ai}
          onChange={(e) => setKeys({ ...keys, gemini_ai: e.target?.value })}
          label={data.geminiAiKeys}
        />

        <Button onClick={getApi} variant="contained">
          {data.save}
        </Button>
      </Stack>
    </div>
  );
};

export default ApiKeys;

import React from "react";
import { GlobalProvider } from "./context/GlobalContext";
import { TranslateProvider } from "./context/TranslateContext";
import { FlowProvider } from "./context/FlowContext";
import AppMui from "./AppMui";

export default function ToggleColorMode() {
  return (
    <GlobalProvider>
      <TranslateProvider>
        <FlowProvider>
          <AppMui />
        </FlowProvider>
      </TranslateProvider>
    </GlobalProvider>
  );
}

import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import TitleHeading from "./TitleHeading";
import { AutoAwesome } from "@mui/icons-material";
import SliderComp from "../../common/SliderComp";
import ChatBot from "./features/ChatBot";
import ImgGen from "./features/ImgGen";
import SpeechTT from "./features/SpeechTT";
import VoiceMaker from "./features/VoiceMaker";
import VideoMaker from "./features/VideoMaker";
import Embed from "./features/Embed";

const Features = ({ data }) => {
  const [state, setState] = React.useState({
    show: 0,
    feature: {},
  });

  React.useEffect(() => {
    setState({
      ...state,
      feature: {
        title: data.aiChat,
        des: data.aiChatDes,
        key: 1,
      },
    });
  }, [data]);

  const featuresArrNew = [
    {
      title: data.aiChat,
      des: data.aiChatDes,
      key: 1,
    },
    {
      title: data.aiChat,
      des: "R",
      key: 1,
    },
    {
      title: data.imageMaker,
      des: data.imageMakerDes,
      key: 2,
    },
    {
      title: data.aiChat,
      des: "R",
      key: 1,
    },
    {
      title: data.speechToText,
      des: data.speechToTextDes,
      key: 3,
    },
    {
      title: data.aiChat,
      des: "R",
      key: 1,
    },
    {
      title: data.voiceMaker,
      des: data.voiceMakerDes,
      key: 4,
    },
    {
      title: data.aiChat,
      des: "R",
      key: 1,
    },
    {
      title: data.aiVideoMaker,
      des: data.aiVideoMakerDes,
      key: 5,
    },
    {
      title: data.aiChat,
      des: "R",
      key: 1,
    },
    {
      title: data.codeWriter,
      des: data.codeWriterDes,
      key: 5,
    },
  ];
  return (
    <Box mt={4} mb={4} minHeight={"80vh"}>
      <Container maxWidth="md">
        <Stack alignItems={"center"} direction={"column"} spacing={4}>
          <Stack justifyContent={"center"} direction={"row"}>
            <Box bgcolor={"action.hover"} p={2} borderRadius={2}>
              <TitleHeading
                text={data.features}
                icon={
                  <>
                    <svg width={0} height={0}>
                      <linearGradient
                        id="linearColors"
                        x1={0}
                        y1={0}
                        x2={1}
                        y2={1}
                      >
                        <stop offset="0%" stopColor="#f25470" />
                        <stop offset="100%" stopColor="#5f88fe" />
                      </linearGradient>
                    </svg>
                    <AutoAwesome sx={{ fill: "url(#linearColors)" }} />
                  </>
                }
              />
            </Box>
          </Stack>

          <Container maxWidth="sm">
            <Typography variant="h3" align="center">
              {state?.feature?.des}
            </Typography>
          </Container>

          <Box
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "block",
                lg: "block",
                xl: "block",
              },
            }}
            bgcolor={"action.hover"}
            p={2}
            borderRadius={2}
          >
            <SliderComp alignItems="center" spacing={2}>
              {featuresArrNew.map((i, key) => {
                return (
                  (i.des == "R" && (
                    <Box
                      key={key}
                      borderRadius={"50%"}
                      p={0.1}
                      sx={{ bgcolor: (t) => t.palette.primary.main }}
                    />
                  )) ||
                  (i.des !== "R" && (
                    <Typography
                      onClick={() =>
                        setState({ ...state, show: key, feature: i })
                      }
                      sx={{
                        fontWeight: state.show == key && "bold",
                        fontSize: state.show == key && 20,
                        background:
                          state.show == key &&
                          "linear-gradient(90deg, #f25470 0%, #5f88fe 100%)",
                        WebkitBackgroundClip: state.show == key && "text",
                        WebkitTextFillColor: state.show == key && "transparent",
                        cursor: "pointer",
                      }}
                      key={key}
                    >
                      {i.title}
                    </Typography>
                  ))
                );
              })}
            </SliderComp>
          </Box>

          <Box
            sx={{
              display: {
                xs: "block",
                sm: "block",
                md: "none",
                lg: "none",
                xl: "none",
              },
            }}
            bgcolor={"action.hover"}
            p={2}
            borderRadius={2}
          >
            <SliderComp alignItems="center" spacing={2}>
              {featuresArrNew.map((i, key) => {
                return (
                  (i.des == "R" && (
                    <Box
                      key={key}
                      borderRadius={"50%"}
                      p={0.1}
                      sx={{ bgcolor: (t) => t.palette.primary.main }}
                    />
                  )) ||
                  (i.des !== "R" && (
                    <Typography
                      onClick={() =>
                        setState({ ...state, show: key, feature: i })
                      }
                      sx={{
                        fontWeight: state.show == key && "bold",
                        fontSize: state.show == key && 20,
                        background:
                          state.show == key &&
                          "linear-gradient(90deg, #f25470 0%, #5f88fe 100%)",
                        WebkitBackgroundClip: state.show == key && "text",
                        WebkitTextFillColor: state.show == key && "transparent",
                        cursor: "pointer",
                      }}
                      key={key}
                    >
                      {i.key}
                    </Typography>
                  ))
                );
              })}
            </SliderComp>
          </Box>
        </Stack>

        <Box>
          {state.show == 0 && <ChatBot data={data} />}
          {state.show == 2 && <ImgGen data={data} />}
          {state.show == 4 && <SpeechTT data={data} />}
          {state.show == 6 && <VoiceMaker data={data} />}
          {state.show == 8 && <VideoMaker data={data} />}
          {state.show == 10 && <Embed data={data} />}
        </Box>
      </Container>
    </Box>
  );
};

export default Features;

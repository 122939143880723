import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import Hero from "./component/Hero";
import { TranslateContext } from "../context/TranslateContext";
import Header from "./component/Header";
import Features from "./component/Features";
import OurClient from "./component/OurClient";
import PricingComp from "./component/PricingComp";
import Testimonial from "./component/Testimonial";
import Faq from "./component/Faq";
import Page from "./component/Page";
import Footer from "./component/Footer";
import { GlobalContext } from "../context/GlobalContext";

function extractHostname(url) {
  // Remove the protocol (http, https, etc.)
  let hostname;

  if (url.indexOf("://") > -1) {
    hostname = url.split("://")[1];
  } else {
    hostname = url;
  }

  // Find the first part before any '/' or ':' to get just the domain
  hostname = hostname.split("/")[0];
  hostname = hostname.split(":")[0];

  return hostname;
}

const FrontEnd = () => {
  const { data } = React.useContext(TranslateContext);
  const { hitAxios } = React.useContext(GlobalContext);
  const [web, setWeb] = React.useState({});
  const [state, setState] = React.useState({
    load: false,
    isReditect: false,
  });

  async function getWeb() {
    const res = await hitAxios({
      path: "/api/web/get_web_public",
      post: false,
      admin: false,
      token_user: "",
    });
    if (res.data.success) {
      if (
        parseInt(res.data?.data?.is_custom_home) > 0 &&
        extractHostname(res.data?.data?.custom_home) !==
          extractHostname(
            `${window.location.protocol}//${window.location.host}/`
          )
      ) {
        window.location.href = res.data?.data?.custom_home;
      } else {
        setWeb(res.data.data);
        setState({
          ...state,
          load: true,
          isReditect:
            parseInt(res.data?.data?.is_custom_home) > 0 &&
            res.data?.data?.custom_home !==
              `${window.location.protocol}//${window.location.host}/`
              ? true
              : false,
          url: res.data?.data?.custom_home,
        });
      }
    }
  }

  React.useEffect(() => {
    getWeb();
  }, []);

  return (
    state.load && (
      <Box>
        <Header />
        <Hero data={data} />
        <OurClient data={data} />
        <Features data={data} />
        <PricingComp data={data} />
        <Testimonial data={data} />
        <Faq data={data} />
        <Page data={data} />
        <Footer main data={data} />
      </Box>
    )
  );
};

export default FrontEnd;

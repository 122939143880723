import { Box, Button, ButtonBase, Dialog, Stack } from "@mui/material";
import React from "react";
import { HexColorPicker } from "react-colorful";

const SelectChooseColor = ({ data, state, setState, stateKey }) => {
  const [open, setOpen] = React.useState(false);
  return (
    <div>
      <ButtonBase onClick={() => setOpen(true)}>
        <Box bgcolor={state[stateKey]} p={1}></Box>
      </ButtonBase>
      <Dialog onClose={() => setOpen(false)} open={open}>
        <Stack direction={"column"} spacing={1} p={1}>
          <HexColorPicker
            color={state[stateKey]}
            onChange={(color) => setState({ ...state, [stateKey]: color })}
          />

          <Button
            onClick={() =>
              setState({
                ...state,
                colorDialog: { ...state.colorDialog, open: false },
              })
            }
            fullWidth
          >
            {data.close}
          </Button>
        </Stack>
      </Dialog>
    </div>
  );
};

export default SelectChooseColor;

import { DeleteOutline, Visibility } from "@mui/icons-material";
import { Box, Container, IconButton, Tooltip } from "@mui/material";
import React from "react";
import SmallDialog from "../../../common/SmallDialog";
import { GlobalContext } from "../../../context/GlobalContext";
import { DataGrid } from "@mui/x-data-grid";
import ShadowBox from "../../../common/ShadowBox";
import MessagesArr from "../../../embed/MessagesArr";

const ViewChatList = ({ data, i, state, setState }) => {
  const { hitAxios } = React.useContext(GlobalContext);
  const [chatData, setChatData] = React.useState([]);
  const [chats, setChats] = React.useState([]);

  async function getChats(botId) {
    const res = await hitAxios({
      path: "/api/embed/get_initiated_chats",
      post: true,
      admin: false,
      obj: { botId },
    });
    if (res.data.success) {
      setChats(res.data.data);
      setState({ ...state, dialogChat: true });
    }
  }

  async function delChatOne(chatId) {
    if (window.confirm(data.aus)) {
      const res = await hitAxios({
        path: "/api/embed/del_embed_chat",
        post: true,
        admin: false,
        obj: { chatId },
      });
      if (res.data.success) {
        getChats(i?.bot_id);
      }
    }
  }

  async function getChatsView(chatId) {
    const res = await hitAxios({
      path: "/api/embed/get_one_chat",
      post: true,
      admin: false,
      obj: { chatId },
    });
    if (res.data.success) {
      setChatData(res.data.data);
      setState({ ...state, dialogChatView: true });
    }
  }

  return (
    <div>
      <IconButton onClick={() => getChats(i?.bot_id)} color="info">
        <Visibility />
      </IconButton>

      <SmallDialog
        title={data.initiatedCChats}
        dialogType={"fullScreen"}
        open={state.dialogChat}
        onClose={() => setState({ ...state, dialogChat: false })}
      >
        <Box minHeight={"80vh"}>
          <Container maxWidth="lg">
            <ShadowBox sx={{ mt: 3 }}>
              {chats ? (
                <Box p={1.5} height="85vh">
                  <DataGrid
                    style={{
                      padding: 0,
                    }}
                    sx={{
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: "action.hover",
                        borderRadius: 2,
                      },
                    }}
                    getRowId={(row) => row.id}
                    rows={[...chats].reverse()}
                    columns={[
                      {
                        headerName: data.email,
                        field: "user_email",
                        flex: 0.5,
                      },
                      {
                        headerName: data.mobile,
                        field: "user_mobile",
                        flex: 0.5,
                      },
                      {
                        headerName: data.name,
                        field: "user_name",
                        flex: 0.5,
                      },
                      {
                        headerName: data.chatId,
                        field: "chat_id",
                        flex: 0.5,
                      },
                      {
                        headerName: data.viewChat,
                        field: "view_chat",
                        flex: 0.5,
                        renderCell: (row) => (
                          <IconButton
                            onClick={() => getChatsView(row?.row?.chat_id)}
                            color="info"
                          >
                            <Visibility />
                          </IconButton>
                        ),
                      },
                      {
                        headerName: data.del,
                        field: "del",
                        flex: 0.5,
                        renderCell: (data) => (
                          <IconButton onClick={delChatOne} color="error">
                            <DeleteOutline />
                          </IconButton>
                        ),
                      },
                    ]}
                    pageSize={50}
                    rowsPerPageOptions={[50]}
                    checkboxSelection={false}
                  />
                </Box>
              ) : (
                ""
              )}
            </ShadowBox>
          </Container>
        </Box>

        <SmallDialog
          title={data.viewingChat}
          onClose={() => setState({ ...state, dialogChatView: false })}
          open={state.dialogChatView}
          dialogType={"fullWidth"}
        >
          <Box p={2} borderRadius={2} bgcolor={"action.hover"}>
            {chatData.map((msg, index) => (
              <MessagesArr index={index} msg={msg} />
            ))}
          </Box>
        </SmallDialog>
      </SmallDialog>
    </div>
  );
};

export default ViewChatList;

import {
  Box,
  CardActionArea,
  Stack,
  CardMedia,
  Typography,
  List,
} from "@mui/material";
import React from "react";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import NavGroup from "./NavGroup";
import NavItem from "./NavItem";
import {
  AccountCircle,
  AccountTreeOutlined,
  Assistant,
  AutoAwesome,
  DashboardOutlined,
  FormatTextdirectionLToR,
  Language,
  Logout,
  Paid,
  PaidOutlined,
  PhotoFilter,
  RecordVoiceOver,
  SlowMotionVideo,
} from "@mui/icons-material";

const SideMenu = ({ page, setPage, data }) => {
  const history = useHistory();

  const Menuitems = [
    {
      navlabel: true,
      subheader: data?.information || "loading...",
    },
    {
      title: data?.dashboard || "loading...",
      icon: DashboardOutlined,
      path: "dashboard",
    },
    {
      navlabel: true,
      subheader: data?.tools || "loading...",
    },
    {
      title: data?.aiModels || "loading...",
      icon: AutoAwesome,
      path: "ai-models",
    },
    {
      title: data?.aiChat || "loading...",
      icon: Assistant,
      path: "ai-chat",
    },
    {
      title: data?.imageMaker || "loading...",
      icon: PhotoFilter,
      path: "ai-image",
    },
    {
      title: data?.speechToText || "loading...",
      icon: FormatTextdirectionLToR,
      path: "ai-speech-to-text",
    },
    {
      title: data?.voiceMaker || "loading...",
      icon: RecordVoiceOver,
      path: "ai-voice-maker",
    },
    {
      title: data?.aiVideoMaker || "loading...",
      icon: SlowMotionVideo,
      path: "ai-video-maker",
    },
    {
      title: data?.codeWriter || "loading...",
      icon: Language,
      path: "embed-chatbot",
    },
    {
      navlabel: true,
      subheader: data?.useFull || "loading...",
    },
    {
      title: data?.subscription || "loading...",
      icon: Paid,
      path: "subscription",
    },
    {
      title: data?.profile || "loading...",
      icon: AccountCircle,
      path: "profile",
    },
    {
      title: data?.logout || "loading...",
      icon: Logout,
      path: "logout",
    },
  ];

  return (
    <div>
      <Box p={1.6}>
        <Stack direction={"column"}>
          <List sx={{ pt: 0 }} className="sidebarNav">
            {Menuitems.map((item, index) => {
              if (item.navlabel) {
                return <NavGroup key={index} item={item} />;
              } else {
                return (
                  <NavItem
                    key={index}
                    item={item}
                    page={page}
                    onClick={() => {
                      history.push(`/user?page=${item.path}`);
                      setPage(item.path);
                    }}
                  />
                );
              }
            })}
          </List>
        </Stack>
      </Box>
    </div>
  );
};

export default SideMenu;

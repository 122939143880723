import { Close as CloseIcon } from "@mui/icons-material";
import {
  Box,
  Container,
  Dialog,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { GlobalContext } from "../context/GlobalContext";

const SmallDialog = ({
  children,
  open,
  onClose,
  dialogType,
  blur,
  title,
  topRight,
}) => {
  const { invertColor } = React.useContext(GlobalContext);

  const handleClose = () => {
    onClose && onClose();
  };

  const getDialogContainerStyle = () => {
    let style = {};

    if (dialogType === "fullScreen") {
      style = {};
    }
    if (dialogType === "fullWidth") {
      style = {
        ...style,
        borderRadius: 10,
      };
    }
    if (blur) {
      style = {
        ...style,
        backgroundColor: "rgba(44, 64, 57, 0.42)",
        backdropFilter: "blur(3px)",
      };
    }
    return style;
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={dialogType === "fullScreen"}
      fullWidth={dialogType === "fullWidth"}
      PaperProps={{ style: getDialogContainerStyle() }}
    >
      <Box>
        <Box p={2}>
          <Stack
            direction={"row"}
            spacing={2}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>

              <Typography variant="h5" fontWeight={600}>
                {title}
              </Typography>
            </Stack>

            {topRight}
          </Stack>
        </Box>
        <Divider />
        <Box p={dialogType === "fullScreen" ? 0 : 2}>{children}</Box>
      </Box>
    </Dialog>
  );
};

SmallDialog.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  dialogType: PropTypes.oneOf(["fullScreen", "fullWidth"]),
  blur: PropTypes.bool,
  title: PropTypes.string,
};

export default SmallDialog;

import { Box, Stack, Typography } from "@mui/material";
import React from "react";

const MessagesArr = ({ index, msg }) => {
  return (
    <div key={index}>
      {msg?.sender == "user" ? (
        <>
          <Stack alignItems={"flex-end"}>
            <Box
              sx={{
                mt: 1,
                borderTopRightRadius: 20,
                borderTopLeftRadius: 20,
                borderBottomLeftRadius: 20,
              }}
              bgcolor={"white"}
              p={1.5}
              borderRadius={2}
            >
              <Typography fontWeight={200}>{msg.text}</Typography>
            </Box>
          </Stack>
        </>
      ) : (
        <Stack alignItems={"flex-start"}>
          <Box
            p={1.5}
            sx={{
              mt: 1,
            }}
            borderRadius={2}
          >
            <Typography fontWeight={200}>{msg.text}</Typography>
          </Box>
        </Stack>
      )}
    </div>
  );
};

export default MessagesArr;

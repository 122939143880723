import { Box, Button, ButtonBase, Dialog, Stack } from "@mui/material";
import React from "react";
import { HexColorPicker } from "react-colorful";

const ThemeColor = ({ color, onChange, boxStyle, data }) => {
  const [dialog, setDialog] = React.useState(false);
  return (
    <div>
      <ButtonBase
        onClick={() => setDialog(true)}
        sx={{
          bgcolor: color || "#f78383",
          p: 2,
          borderRadius: 2,
          cursor: "pointer",
          ...boxStyle,
        }}
      />
      <Dialog open={dialog} onClose={() => setDialog(false)}>
        <Box p={2}>
          <Stack direction={"column"} spacing={2}>
            <HexColorPicker color={color || "#f78383"} onChange={onChange} />
            <Button
              onClick={() => setDialog(false)}
              variant="outlined"
              size="small"
            >
              {data.close}
            </Button>
          </Stack>
        </Box>
      </Dialog>
    </div>
  );
};

export default ThemeColor;

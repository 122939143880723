import { Stack } from "@mui/material";
import React from "react";
import { GlobalContext } from "../context/GlobalContext";

const SliderComp = ({ children, spacing }) => {
  const { screenWidth } = React.useContext(GlobalContext);
  return (
    <Stack
      sx={{
        overflowX: "scroll",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        maxWidth: screenWidth / 1.3,
      }}
      spacing={spacing}
      direction={"row"}
    >
      {children}
    </Stack>
  );
};

export default SliderComp;

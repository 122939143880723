import React from "react";
import SmallDialog from "../../common/SmallDialog";
import { Alert, Button, Stack } from "@mui/material";
import TextFieldNew from "../../common/TextFieldNew";
import { LoadingButton } from "@mui/lab";
import { GlobalContext } from "../../context/GlobalContext";
import { Check } from "@mui/icons-material";

const ForgotPw = ({ data, state, setState, hitAxios }) => {
  const CON = React.useContext(GlobalContext);

  async function sendRecovery() {
    const res = await hitAxios({
      path: "/api/user/send_resovery",
      post: true,
      admin: false,
      obj: { email: state.forgotEmail },
    });
    if (res.data.success) {
      setState({ ...state, msg: res.data.msg });
    }
  }

  return (
    <SmallDialog
      onClose={() => setState({ ...state, forgotDialog: false })}
      title={data.forPass}
      open={state.forgotDialog}
      dialogType="fullWidth"
    >
      <Stack direction={"column"} spacing={2}>
        <TextFieldNew
          onChange={(e) => setState({ ...state, forgotEmail: e.target.value })}
          label={data.email}
        />

        <LoadingButton
          onClick={sendRecovery}
          variant="contained"
          sx={{ borderRadius: 2, boxShadow: 0 }}
          loading={CON?.data?.loading}
        >
          {data.submit}
        </LoadingButton>

        {state?.msg && (
          <Alert icon={<Check fontSize="inherit" />} severity="success">
            {state.msg}
          </Alert>
        )}
      </Stack>
    </SmallDialog>
  );
};

export default ForgotPw;

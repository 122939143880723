import {
  Box,
  Button,
  ButtonBase,
  CardMedia,
  Checkbox,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import DividerTitile from "../../common/DividerTitile";
import { TranslateContext } from "../../context/TranslateContext";
import TextFieldNew from "../../common/TextFieldNew";
import { GlobalContext } from "../../context/GlobalContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const LoginAdmin = () => {
  const { data } = React.useContext(TranslateContext);
  const history = useHistory();
  const [state, setState] = React.useState({});
  const { hitAxios } = React.useContext(GlobalContext);
  const [web, setWeb] = React.useState({});

  async function handleLogin() {
    const res = await hitAxios({
      path: "/api/admin/login",
      post: true,
      admin: true,
      obj: state,
    });

    if (res.data.success) {
      localStorage.setItem(
        process.env.REACT_APP_TOKEN + "_admin",
        res.data.token
      );
      history.push("/admin");
    }
  }

  async function getWebPublic() {
    const res = await hitAxios({
      path: "/api/web/get_web_public",
      post: false,
      admin: false,
      token_user: "",
    });
    if (res.data.success) {
      setWeb(res.data.data);
    }
  }

  React.useEffect(() => {
    getWebPublic();
  }, []);

  return (
    <Box bgcolor={"action.hover"}>
      <Grid
        minHeight={"100vh"}
        alignItems={"center"}
        justifyContent={"center"}
        container
      >
        <Grid xs={12} sm={12} lg={12} item>
          <Container maxWidth="xs">
            <Box
              borderRadius={2}
              sx={{
                bgcolor: "action.unhover",
              }}
              p={4}
            >
              <Stack direction={"column"} spacing={3}>
                <Stack direction={"column"} spacing={3} alignItems={"center"}>
                  {web?.logo && (
                    <ButtonBase onClick={() => history.push("/")}>
                      <Stack
                        p={0.9}
                        alignItems={"center"}
                        direction={"row"}
                        spacing={0}
                      >
                        <CardMedia
                          height={50}
                          component={"img"}
                          sx={{ minWidth: 50 }}
                          src={`/media/${web?.logo}`}
                        />
                        <Typography variant="h3" fontWeight={100}>
                          {web?.app_name}
                        </Typography>
                      </Stack>
                    </ButtonBase>
                  )}
                  <Typography>{data.helloAdmin}</Typography>
                </Stack>

                <DividerTitile title={data.login} />

                <TextFieldNew
                  onChange={(e) =>
                    setState({ ...state, email: e.target.value })
                  }
                  value={state.email}
                  fullWidth
                  label={data.email}
                />
                <TextFieldNew
                  onChange={(e) =>
                    setState({ ...state, password: e.target.value })
                  }
                  value={state.password}
                  fullWidth
                  label={data.password}
                />

                <Button onClick={handleLogin} fullWidth variant="contained">
                  {data.login}
                </Button>

                <Stack alignItems={"flex-end"}>
                  {/* <Typography
                    sx={{
                      color: (t) => t.palette.primary.main,
                      cursor: "pointer",
                    }}
                  >
                    {data.forgotPas}
                  </Typography> */}
                </Stack>
              </Stack>
            </Box>
          </Container>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoginAdmin;

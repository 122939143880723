import { Box } from "@mui/material";
import React from "react";
import "./button2.css";

const ButtonAnime2 = ({ children, ...props }) => {
  return (
    <Box {...props} className="gradient-border-animation2">
      {children}
    </Box>
  );
};

export default ButtonAnime2;

import {
  Avatar,
  Box,
  Container,
  Stack,
  TextField,
  Typography,
  Zoom,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  AttachFile,
  EmojiEmotions,
  Face,
  Menu,
  Send,
  SmartToy,
} from "@mui/icons-material";
import TextFieldNew from "../../../common/TextFieldNew";

const ChatBot = ({ data, screenWidth }) => {
  const [showFirstChat, setShowFirstChat] = useState(false);
  const [showSecondChat, setShowSecondChat] = useState(false);

  useEffect(() => {
    const timeout1 = setTimeout(() => {
      setShowFirstChat(true);
    }, 1000);

    const timeout2 = setTimeout(() => {
      setShowSecondChat(true);
    }, 2000);

    const timeout3 = setTimeout(() => {
      setShowFirstChat(false);
      setShowSecondChat(false);
    }, 3000);

    const timeout4 = setTimeout(() => {
      setShowFirstChat(true);
    }, 4000);

    const timeout5 = setTimeout(() => {
      setShowSecondChat(true);
    }, 5000);

    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
      clearTimeout(timeout3);
      clearTimeout(timeout4);
      clearTimeout(timeout5);
    };
  }, []);

  return (
    <Box mt={4}>
      <Box
        minHeight={230}
        style={{ backgroundImage: "url('/assets/chat_bg.png')" }}
        mt={2}
        borderRadius={3}
        bgcolor={"action.hover"}
        p={2}
      >
        {showFirstChat && (
          <Zoom in={showFirstChat}>
            <Stack
              spacing={1}
              direction={"row"}
              justifyContent={"flex-end"}
              alignItems={"flex-end"}
            >
              <Box
                sx={{
                  borderTopRightRadius: 20,
                  borderBottomRightRadius: 20,
                  borderTopLeftRadius: 20,
                }}
                bgcolor={"white"}
                width={"50%"}
                p={2}
              >
                <Stack direction={"column"} spacing={1}>
                  {[...Array(5)].map((_, index) => (
                    <Box bgcolor={"action.hover"} p={0.5} key={index} />
                  ))}
                </Stack>
              </Box>
              <Avatar>
                <Face />
              </Avatar>
            </Stack>
          </Zoom>
        )}

        {showSecondChat && (
          <Zoom in={showSecondChat}>
            <Stack
              spacing={0}
              mt={2}
              direction={"row"}
              alignItems={"flex-end"}
              justifyContent={"flex-start"}
            >
              <Avatar sx={{ bgcolor: "green" }}>
                <SmartToy />
              </Avatar>
              <Box
                sx={{
                  borderTopRightRadius: 20,
                  borderBottomLeftRadius: 20,
                  borderTopLeftRadius: 20,
                }}
                width={"50%"}
                p={2}
              >
                <Stack direction={"column"} spacing={1}>
                  {[...Array(5)].map((_, index) => (
                    <Box bgcolor={"white"} p={0.5} key={index} />
                  ))}
                </Stack>
              </Box>
            </Stack>
          </Zoom>
        )}
      </Box>

      <Box borderRadius={3} bgcolor={"action.hover"} p={2} mt={2}>
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <TextFieldNew fullWidth size="small" disabled />
          <EmojiEmotions sx={{ color: "gray" }} />
          <AttachFile sx={{ color: "gray" }} />
          <Send sx={{ color: "gray" }} />
        </Stack>
      </Box>
    </Box>
  );
};

export default ChatBot;

import {
  Box,
  Button,
  CardMedia,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import TextFieldNew from "../../common/TextFieldNew";
import { TranslateContext } from "../../context/TranslateContext";
import DividerTitile from "../../common/DividerTitile";
import {
  Email,
  EmailOutlined,
  FingerprintOutlined,
  Login,
  Visibility,
  VisibilityOff,
  VpnKey,
} from "@mui/icons-material";
import { GlobalContext } from "../../context/GlobalContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ForgotPw from "./ForgotPw";

const UserLogin = () => {
  const { hitAxios } = React.useContext(GlobalContext);
  const history = useHistory();
  const { data } = React.useContext(TranslateContext);
  const [state, setState] = React.useState({
    isLogin: true,
    showPass: false,
    forgotDialog: false,
  });

  async function handleLogin() {
    const res = await hitAxios({
      path: "/api/user/login",
      admin: false,
      post: true,
      obj: state,
    });
    if (res.data.success) {
      history.push("/user");
      localStorage.setItem(
        process.env.REACT_APP_TOKEN + "_user",
        res.data.token
      );
    }
  }

  async function handleSignup() {
    const res = await hitAxios({
      path: "/api/user/signup",
      admin: false,
      post: true,
      obj: state,
    });
    if (res.data.success) {
      setState({ ...state, isLogin: true });
    }
  }

  return (
    <div>
      <Grid
        container
        alignItems={"center"}
        justifyContent={"center"}
        minHeight={"100vh"}
      >
        <Grid
          sx={{ display: { xs: "none", lg: "block", md: "block" } }}
          xs={12}
          sm={12}
          md={6}
          lg={7}
          item
        >
          <Box
            sx={{
              bgcolor: (t) => t.palette.primary.light,
            }}
          >
            <Container maxWidth="xs">
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                minHeight={"100vh"}
              >
                <CardMedia component={"img"} src="/assets/login_hero_ai.png" />
              </Stack>
            </Container>
          </Box>
        </Grid>

        <Grid xs={12} sm={12} md={6} lg={5} item>
          <Box>
            <Container maxWidth="xs">
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                minHeight={"100vh"}
              >
                <CardMedia
                  sx={{
                    display: {
                      xs: "block",
                      sm: "block",
                      md: "none",
                      lg: "none",
                    },
                    maxHeight: 150,
                    maxWidth: 150,
                  }}
                  component={"img"}
                  src="/assets/login_hero_ai.png"
                />

                <Stack spacing={3} direction={"column"}>
                  <Stack direction={"column"}>
                    <Typography variant="h2">{data?.loginTitile}</Typography>
                    <Typography fontWeight={300} variant="subtitle1">
                      {data.loginOrsignup}
                    </Typography>
                  </Stack>

                  <DividerTitile
                    title={!state.isLogin ? data.signup : data.login}
                  />

                  {state.isLogin ? (
                    <>
                      <TextFieldNew
                        type="email"
                        value={state.email}
                        onChange={(e) =>
                          setState({ ...state, email: e.target.value })
                        }
                        label={data.email}
                      />

                      <TextFieldNew
                        value={state.password}
                        onChange={(e) =>
                          setState({ ...state, password: e.target.value })
                        }
                        type={state.showPass ? "text" : "password"}
                        endIcon={
                          <IconButton
                            onClick={() => {
                              setState({ ...state, showPass: !state.showPass });
                            }}
                          >
                            {state.showPass ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        }
                        label={data.password}
                      />

                      <Stack alignItems={"flex-end"}>
                        <Typography
                          onClick={() =>
                            setState({ ...state, forgotDialog: true })
                          }
                          sx={{
                            color: (t) => t.palette.primary.main,
                            cursor: "pointer",
                          }}
                        >
                          {data.forgotPas}
                        </Typography>
                      </Stack>

                      <Button
                        onClick={handleLogin}
                        startIcon={<Login />}
                        variant="contained"
                      >
                        {data.login}
                      </Button>
                    </>
                  ) : (
                    <>
                      <TextFieldNew
                        value={state.name}
                        onChange={(e) =>
                          setState({ ...state, name: e.target.value })
                        }
                        label={data.name}
                      />

                      <TextFieldNew
                        value={state.email}
                        onChange={(e) =>
                          setState({ ...state, email: e.target.value })
                        }
                        label={data.email}
                      />

                      <TextFieldNew
                        value={state.password}
                        onChange={(e) =>
                          setState({ ...state, password: e.target.value })
                        }
                        type={state.showPass ? "text" : "password"}
                        endIcon={
                          <IconButton
                            onClick={() => {
                              setState({ ...state, showPass: !state.showPass });
                            }}
                          >
                            {state.showPass ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        }
                        label={data.password}
                      />

                      <TextFieldNew
                        type="number"
                        value={state.mobile}
                        onChange={(e) =>
                          setState({ ...state, mobile: e.target.value })
                        }
                        startIcon={<Typography>+</Typography>}
                        label={data.mobile}
                      />

                      <Button
                        onClick={handleSignup}
                        startIcon={<VpnKey />}
                        color="secondary"
                        variant="contained"
                      >
                        {data.signup}
                      </Button>
                    </>
                  )}

                  <Stack alignItems={"center"} direction={"row"} spacing={1}>
                    <Typography>{data.newHere}</Typography>
                    <Typography
                      onClick={() =>
                        setState({ ...state, isLogin: !state.isLogin })
                      }
                      variant="body1"
                      sx={{
                        color: (t) => t.palette.primary.main,
                        cursor: "pointer",
                      }}
                    >
                      {state.isLogin ? data.signup : data.login}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Container>
          </Box>
        </Grid>
      </Grid>

      <ForgotPw
        hitAxios={hitAxios}
        data={data}
        state={state}
        setState={setState}
      />
    </div>
  );
};

export default UserLogin;

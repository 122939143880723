import React from "react";
import { AccountTreeOutlined, AddOutlined } from "@mui/icons-material";
import { Avatar, Box, Button, Chip, Grid, Stack } from "@mui/material";
import TitlePanel from "../../common/TitlePanel";
import SliderComp from "../../common/SliderComp";
import Pricing from "../../common/Pricing";
import AddPlan from "./AddPlan";
import { GlobalContext } from "../../context/GlobalContext";
import ButtonGrid from "../../common/ButtonGrid";

const Plans = ({ data }) => {
  const { hitAxios } = React.useContext(GlobalContext);
  const [planData, setPlanData] = React.useState([]);
  const [state, setState] = React.useState({
    addPlanDialog: false,
    in_app_chat: false,
    image_maker: false,
    code_writer: false,
    speech_to_text: false,
    voice_maker: false,
    ai_video: false,
    isTrial: false,
  });

  async function getPlan() {
    const res = await hitAxios({
      path: "/api/web/get_plans",
      post: false,
      admin: false,
    });
    if (res.data.success) {
      setPlanData(res.data.data);
    }
  }

  React.useEffect(() => {
    getPlan();
  }, []);
  return (
    <>
      <Stack direction={"column"} spacing={3}>
        <TitlePanel
          icon={
            <Avatar
              sx={{
                width: 50,
                height: 50,
                bgcolor: "action.hover",
              }}
            >
              <AccountTreeOutlined
                sx={{
                  color: (t) => t.palette.primary.main,
                }}
              />
            </Avatar>
          }
          data={data}
          place={data.plans}
          title={data.plans}
        />

        <AddPlan
          hitAxios={hitAxios}
          data={data}
          state={state}
          setState={setState}
        />

        <Stack direction={"row"} justifyContent={"flex-end"}>
          <ButtonGrid
            startIcon={<AddOutlined />}
            onClick={() => setState({ ...state, addPlanDialog: true })}
            title={data.addPlan}
          />
        </Stack>
      </Stack>
      <Box mt={3}>
        <Grid direction={"row"} spacing={2} container>
          <Pricing
            getPlan={getPlan}
            planData={planData}
            data={data}
            hitAxios={hitAxios}
          />
        </Grid>
      </Box>
    </>
  );
};

export default Plans;

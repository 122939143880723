import {
  InsertLink,
  Inbox,
  Menu,
  HomeOutlined,
  ContactMailOutlined,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const HeaderMenu = ({ data }) => {
  const history = useHistory();
  const [state, setState] = React.useState({
    drawer: false,
  });
  const { hitAxios } = React.useContext(GlobalContext);
  const [page, setPage] = React.useState([]);

  async function getPages(params) {
    const res = await hitAxios({
      path: "/api/admin/get_pages",
      post: false,
      admin: false,
    });
    if (res.data.success) {
      setPage(res.data.data);
    }
  }

  React.useState(() => {
    getPages();
  }, []);

  return (
    <div>
      <Drawer
        open={state.drawer}
        onClose={() => setState({ ...state, drawer: false })}
        anchor="left"
      >
        <Box minWidth={300}>
          <Stack direction={"column"}>
            <Box p={2}>
              <Typography color={"gray"}>{data.useFulLinks}</Typography>
            </Box>

            <List>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    history.push(`/`);
                    setState({ ...state, drawer: false });
                  }}
                >
                  <ListItemIcon>
                    <HomeOutlined />
                  </ListItemIcon>
                  <ListItemText primary={data.home} />
                </ListItemButton>
              </ListItem>
              {page.map((i, key) => {
                return (
                  <ListItem key={key} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        history.push(`/view/${i?.slug}`);
                        setState({ ...state, drawer: false });
                      }}
                    >
                      <ListItemIcon>
                        <InsertLink />
                      </ListItemIcon>
                      <ListItemText primary={i?.title} />
                    </ListItemButton>
                  </ListItem>
                );
              })}

              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    history.push(`/page/contact-form`);
                    setState({ ...state, drawer: false });
                  }}
                >
                  <ListItemIcon>
                    <ContactMailOutlined />
                  </ListItemIcon>
                  <ListItemText primary={data.contactUs} />
                </ListItemButton>
              </ListItem>
            </List>
          </Stack>
        </Box>
      </Drawer>
      <IconButton onClick={() => setState({ ...state, drawer: true })}>
        <Menu />
      </IconButton>
    </div>
  );
};

export default HeaderMenu;

import {
  Avatar,
  Box,
  ButtonBase,
  Dialog,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import SmallDialog from "../../../common/SmallDialog";

const SelectModel = ({
  data,
  state,
  setState,
  chat,
  setChat,
  hitAxios,
  getChatHistory,
  setMessages,
}) => {
  const [models, setModels] = React.useState([]);

  async function getModels() {
    const res = await hitAxios({
      path: "/api/user/get_ai_models",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      setModels(res.data.data);
    }
  }

  async function createChat(i) {
    const res = await hitAxios({
      path: "/api/chat/create_chat",
      post: true,
      admin: false,
      obj: {
        modelId: i?.model_id,
      },
    });
    if (res.data.success) {
      // setChat({ ...chat, selectedModel: i });
      // setState({ ...state, selectModelDialog: false });

      setMessages([]);
      setChat({ ...chat, selectedModel: i, chatData: res?.data?.chat });
      setState({ ...state, selectModelDialog: false });
      getChatHistory();
    }
  }

  React.useEffect(() => {
    getModels();
  }, []);

  return (
    <SmallDialog
      title={data.startNewChat}
      onClose={() => setState({ ...state, selectModelDialog: false })}
      dialogType={"fullWidth"}
      open={state.selectModelDialog}
    >
      <Stack direction={"column"} spacing={2}>
        {models?.map((i, key) => {
          return (
            <ButtonBase
              onClick={() => {
                createChat(i);
              }}
              key={key}
              sx={{ width: "100%", borderRadius: 2 }}
            >
              <Box
                sx={{ width: "100%" }}
                borderRadius={2}
                bgcolor={"action.hover"}
                p={1}
                // border={1}
                borderColor={"#e8e9f3"}
              >
                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                  <Avatar
                    src={`/media/${i?.icon}`}
                    sx={{ height: 30, width: 30 }}
                  />

                  <Typography>{i?.name}</Typography>
                </Stack>
              </Box>
            </ButtonBase>
          );
        })}
      </Stack>
    </SmallDialog>
  );
};

export default SelectModel;

import { AutoFixHigh } from "@mui/icons-material";
import {
  Box,
  CardMedia,
  IconButton,
  Stack,
  Typography,
  Zoom,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const ImgGen = ({ data }) => {
  const [state, setState] = React.useState({
    show: false,
  });
  const [typedText, setTypedText] = useState("");

  function typeText(text, totalDurationInSeconds) {
    const length = text.length;
    const delayBetweenChars = (totalDurationInSeconds * 1000) / length; // Calculate delay per character

    let currentIndex = 0;
    const interval = setInterval(() => {
      if (currentIndex < text.length) {
        setTypedText(text.slice(0, currentIndex + 1));
        currentIndex += 1;
      } else {
        clearInterval(interval);
      }
    }, delayBetweenChars);
  }

  useEffect(() => {
    typeText(data.srt, 1); // Pass total duration of 0.5 seconds
    setTimeout(() => {
      setState({ ...state, show: true });
    }, 1000);
  }, []);

  return (
    <div>
      <Box mt={4}>
        <Stack
          alignItems={"center"}
          direction={"row"}
          justifyContent={"space-between"}
          spacing={2}
        >
          <Typography variant="h5">{typedText}</Typography>

          {state.show && (
            <Zoom in={state.show} timeout={500}>
              <IconButton>
                <>
                  <svg width={0} height={0}>
                    <linearGradient
                      id="linearColors"
                      x1={0}
                      y1={0}
                      x2={1}
                      y2={1}
                    >
                      <stop offset="0%" stopColor="#f25470" />
                      <stop offset="100%" stopColor="#5f88fe" />
                    </linearGradient>
                  </svg>

                  <AutoFixHigh sx={{ fill: "url(#linearColors)" }} />
                </>
              </IconButton>
            </Zoom>
          )}
        </Stack>

        <Zoom in={state.show} timeout={1000}>
          <Box mt={3}>
            <CardMedia
              height={400}
              sx={{
                borderRadius: 2,
              }}
              component={"img"}
              src="/assets/chuchu.png"
            />
          </Box>
        </Zoom>
      </Box>
    </div>
  );
};

export default ImgGen;

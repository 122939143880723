import React from "react";
import SmallDialog from "../../common/SmallDialog";
import TextFieldNew from "../../common/TextFieldNew";
import { Box, Button, Stack, Switch, Typography } from "@mui/material";
import { SaveOutlined } from "@mui/icons-material";

const AddPlan = ({ data, state, setState, hitAxios }) => {
  async function addPlan() {
    const res = await hitAxios({
      path: "/api/plan/add_new",
      post: true,
      admin: true,
      obj: state,
    });
    if (res.data.success) {
      setState({ ...state, addPlanDialog: false });
    }
  }

  return (
    <SmallDialog
      dialogType={"fullWidth"}
      title={data.addPlan}
      onClose={(e) => setState({ ...state, addPlanDialog: false })}
      open={state.addPlanDialog}
    >
      <Stack direction={"column"} spacing={2}>
        <TextFieldNew
          onChange={(e) => setState({ ...state, name: e.target.value })}
          label={data.name}
        />

        <Box flex={1} p={1} borderRadius={2} bgcolor={"action.hover"}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography>{data.isTrial}</Typography>

            <Switch
              onChange={(e) =>
                setState({ ...state, isTrial: e.target.checked })
              }
              checked={state.isTrial}
              size="small"
            />
          </Stack>
        </Box>

        {!state.isTrial && (
          <TextFieldNew
            onChange={(e) => setState({ ...state, price: e.target.value })}
            type="number"
            label={data.price}
          />
        )}

        <TextFieldNew
          onChange={(e) =>
            setState({ ...state, validity_days: e.target.value })
          }
          helperText={data.numOfDays}
          type="number"
          label={data.planValidity}
        />

        <TextFieldNew
          onChange={(e) => setState({ ...state, gemini_token: e.target.value })}
          helperText={data.numOfToken}
          type="number"
          label={data.geminiToken}
        />

        <TextFieldNew
          onChange={(e) => setState({ ...state, openai_token: e.target.value })}
          helperText={data.numOfToken}
          type="number"
          label={data.openAiTokens}
        />

        <Stack spacing={1} direction={"row"}>
          <Box p={1} flex={1} borderRadius={2} bgcolor={"action.hover"}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography>{data.inAppChat}</Typography>

              <Switch
                onChange={(e) =>
                  setState({ ...state, in_app_chat: e.target.checked })
                }
                checked={state.in_app_chat}
                size="small"
              />
            </Stack>
          </Box>

          <Box flex={1} p={1} borderRadius={2} bgcolor={"action.hover"}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography>{data.aiImageMaker}</Typography>

              <Switch
                onChange={(e) =>
                  setState({ ...state, image_maker: e.target.checked })
                }
                checked={state.image_maker}
                size="small"
              />
            </Stack>
          </Box>
        </Stack>

        <Stack spacing={1} direction={"row"}>
          <Box p={1} flex={1} borderRadius={2} bgcolor={"action.hover"}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography>{data.codeWriter}</Typography>

              <Switch
                onChange={(e) =>
                  setState({ ...state, code_writer: e.target.checked })
                }
                checked={state.code_writer}
                size="small"
              />
            </Stack>
          </Box>

          <Box p={1} flex={1} borderRadius={2} bgcolor={"action.hover"}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography>{data.speechToText}</Typography>

              <Switch
                onChange={(e) =>
                  setState({ ...state, speech_to_text: e.target.checked })
                }
                checked={state.speech_to_text}
                size="small"
              />
            </Stack>
          </Box>
        </Stack>

        <Stack spacing={1} direction={"row"}>
          <Box p={1} flex={1} borderRadius={2} bgcolor={"action.hover"}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography>{data.voiceMaker}</Typography>

              <Switch
                onChange={(e) =>
                  setState({ ...state, voice_maker: e.target.checked })
                }
                checked={state.voice_maker}
                size="small"
              />
            </Stack>
          </Box>

          <Box p={1} flex={1} borderRadius={2} bgcolor={"action.hover"}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography>{data.aiVideoMaker}</Typography>

              <Switch
                onChange={(e) =>
                  setState({ ...state, ai_video: e.target.checked })
                }
                checked={state.ai_video}
                size="small"
              />
            </Stack>
          </Box>
        </Stack>

        <Button
          onClick={addPlan}
          startIcon={<SaveOutlined />}
          variant="contained"
        >
          {data.save}
        </Button>
      </Stack>
    </SmallDialog>
  );
};

export default AddPlan;

import { Stack, Avatar, Box, Grid } from "@mui/material";
import React from "react";
import TitlePanel from "../../common/TitlePanel";
import { AccountTreeOutlined } from "@mui/icons-material";
import GenerateImg from "./components/GenerateImg";
import { GlobalContext } from "../../context/GlobalContext";
import ImageList from "./components/ImageList";

const ImageMaker = ({ data }) => {
  const [state, setState] = React.useState({});
  const { hitAxios } = React.useContext(GlobalContext);
  const [imgData, setImgData] = React.useState([]);
  const [img, setImg] = React.useState({
    aiType: "OPENAI",
    imgSize: "256x256",
    imgStyle: "cinematic",
    prompt: "",
  });

  async function getImgData() {
    const res = await hitAxios({
      path: "/api/user/get_ai_images",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      setImgData(res.data.data);
      setState({ ...state, addDialog: false, prompt: "" });
    }
  }

  async function genImg() {
    const res = await hitAxios({
      path: "/api/user/generate_ai_image",
      post: true,
      admin: false,
      obj: img,
    });
    if (res.data.success) {
      getImgData();
    }
  }

  React.useEffect(() => {
    getImgData();
  }, []);

  return (
    <div>
      <Stack direction={"column"} spacing={3}>
        <TitlePanel
          icon={
            <Avatar
              sx={{
                width: 50,
                height: 50,
                bgcolor: "action.hover",
              }}
            >
              <AccountTreeOutlined
                sx={{
                  color: (t) => t.palette.primary.main,
                }}
              />
            </Avatar>
          }
          data={data}
          place={data.imageMaker}
          title={data.imageMaker}
        />

        <Box mt={3}>
          <GenerateImg
            getImgData={getImgData}
            img={img}
            setImg={setImg}
            state={state}
            setState={setState}
            data={data}
            genImg={genImg}
          />
        </Box>

        <Box mt={3}>
          <Grid container spacing={2}>
            {imgData?.map((i, key) => {
              return (
                <Grid xs={6} sm={4} lg={3} item>
                  <ImageList
                    getImgData={getImgData}
                    hitAxios={hitAxios}
                    data={data}
                    i={i}
                    key={key}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Stack>
    </div>
  );
};

export default ImageMaker;
